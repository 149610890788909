import {BarcodeCollection, Invoice} from '@emporos/api-enterprise';
import {CompleteBarcodeComponent, decode, ScanResult} from '@emporos/barcodes';
import {Redirect, RouteComponentProps} from '@reach/router';
import assert from 'assert';
import {memo, useMemo} from 'react';
import {
  AnalyticType,
  createInvoiceOtcReducer,
  NavigationLogTypes,
  Transaction,
  useAlertState,
  useAnalyticsProvider,
  useGlobalData,
  useInvoice,
  useLog,
  useMultiSelect,
  UserLogTypes,
  useSession,
  useTotals,
  useTransactionsState,
  withRootPage,
} from '../../../';

export interface ManualRXFormData {
  rx: string;
  fill: string;
  site: string;
  partial?: string;
  price?: string;
  rxId?: string;
  mrn?: string;
  plan1?: string;
  plan2?: string;
  plan3?: string;
  plan4?: string;
  plan5?: string;
}

const EMPTY_BARCODES: BarcodeCollection = {
  barcodeComponents: [],
  pmsBarcodeMappings: [],
};

function TransactionIntegrationComponent({
  navigate,
}: RouteComponentProps): JSX.Element | null {
  const {NODE_ENV} = process.env;

  assert(
    navigate,
    '<Transaction /> must have a `navigate` prop.' + String(NODE_ENV) ===
      'production'
      ? ''
      : ' This likely means that you need to have it as a direct child of a <Router />',
  );

  const {logUserSelection} = useLog();
  const {currentInvoiceId, setCurrentInvoiceId} = useTransactionsState();
  const {updateSession} = useSession();
  const {
    barcodesResult,
    barcodeName,
    pmsName,
    otcItemsResult,
    settingsResult,
  } = useGlobalData();

  if (!currentInvoiceId) {
    return <Redirect to="/sales" noThrow />;
  }

  const {notification} = useAlertState();
  const {track} = useAnalyticsProvider();

  const multiselect = useMultiSelect<string>();

  const {invoice, updateInvoice, updateInvoiceItem, canDelete} = useInvoice();
  const {totals} = useTotals();

  const barcodeComponents = useMemo(
    () =>
      barcodesResult && barcodesResult.data
        ? (barcodesResult.data.barcodeComponents.filter(
            barcodeComponent => barcodeComponent.barcodeName === barcodeName,
          ) as CompleteBarcodeComponent[])
        : [],
    [barcodesResult],
  );

  function onScan(scanResult: ScanResult) {
    if (!(pmsName && barcodesResult && navigate)) {
      return;
    }

    const item = decode(barcodeComponents, scanResult, pmsName);
    const isRx = Boolean(item.rx && item.fill && item.site);

    if (isRx) {
      logUserSelection(NavigationLogTypes.UserNavigating, {
        url: 'sales/transactions/customer',
        info: 'Scanned RX item.',
      });
      navigate(`customer`, {
        state: item,
      });
    } else if (otcItemsResult && otcItemsResult.data) {
      const otcItems = otcItemsResult.data.find(
        otcItem => otcItem.itemNumber === scanResult.raw,
      );

      if (otcItems && invoice) {
        logUserSelection(UserLogTypes.ScannedOTC, {
          result: otcItems.description,
        });
        if (otcItems.itemType.id === 16 && invoice.pseCheckResult === 1) {
          notification({
            title: 'PSE Items Locked',
            description:
              'The user has already passed the NPLEx check and these items cannot be edited.',
            type: 'warning',
            icon: 'Warning',
          });
        } else {
          const items = [otcItems].reduce(
            createInvoiceOtcReducer(invoice),
            invoice.items || [],
          );
          updateInvoice({
            items,
          });
        }
      } else {
        notification({
          title: 'Item Not Found',
          description: 'Item couldn’t be found in the database.',
          type: 'warning',
          icon: 'Warning',
        });
        track(AnalyticType.ScanningError, {
          message: 'Scanned OTC item couldn’t be found in the database.',
        });
      }
    }
  }

  return (
    <Transaction
      invoice={invoice}
      canDeleteInvoice={canDelete}
      totals={totals}
      settings={(settingsResult && settingsResult.data) || []}
      otcItems={(otcItemsResult && otcItemsResult.data) || []}
      barcodes={(barcodesResult && barcodesResult.data) || EMPTY_BARCODES}
      multiselect={multiselect}
      navigate={navigate}
      onRemoveInvoice={({invoiceId}: Invoice) => {
        updateSession(
          prevSession => ({
            invoices: prevSession.invoices.map(inv =>
              inv.invoiceId === invoiceId
                ? {...inv, isDeleted: true, isSynced: false}
                : inv,
            ),
          }),
          currentInvoiceId,
        );
      }}
      onUpdateInvoice={updateInvoice}
      onUpdateInvoiceItem={updateInvoiceItem}
      setCurrentInvoiceId={setCurrentInvoiceId}
      onScan={onScan}
    />
  );
}

export const TransactionIntegration = memo(
  withRootPage(TransactionIntegrationComponent),
);

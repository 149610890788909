import {NotificationAlert} from '@emporos/components';

export type EndpointsConfig = Record<
  string,
  Record<
    string,
    {
      errorAlert?: Omit<NotificationAlert, 'id'>;
      offline?: boolean;
      preferOffline?: boolean;
    }
  >
>;

export const endpointsConfig: EndpointsConfig = {
  GET: {
    '(.*)(\\/client\\/barcodes)': {
      offline: true,
    },
    '(.*)(\\/client\\/otc-items)(.*)': {
      preferOffline: true,
      offline: true,
    },
    '(.*)(\\/client\\/eligibility\\?q=)(.*)': {
      errorAlert: {
        title: 'Searching Failed',
        description:
          'Something happened while searching eligibility. Please try searching again.',
        icon: 'Warning',
        type: 'warning',
      },
    },
    '(.*)(\\/client\\/sites)(.*)': {
      preferOffline: false,
      offline: true,
      errorAlert: {
        title: 'Failed To Get Sites',
        description:
          'Something happened while loading sites. Please check your internet connection and reload the app.',
        icon: 'Warning',
        type: 'warning',
      },
    },
    '(.*)(\\/client\\/clientCacheSessionsMyGet)(.*)': {
      preferOffline: false,
      offline: true,
      errorAlert: {
        title: 'Failed To Get Get Session',
        description:
          'Something happened while loading Session. Please check your internet connection and reload the app.',
        icon: 'Warning',
        type: 'warning',
      },
    },
  },
  POST: {
    '(.*)(\\/accounts\\/ar)': {
      errorAlert: {
        title: 'Create AR Account',
        description:
          'The new AR Account could not be created. Please try again.',
        icon: 'Warning',
        type: 'warning',
      },
    },
  },
  PUT: {
    '(.*)(\\/client\\/will-call)(.*)': {
      errorAlert: {
        title: 'Will Call Error',
        description:
          'Customer prescription info could not be pulled down. Please try again.',
        icon: 'Warning',
        type: 'warning',
      },
    },
  },
  PATCH: {},
  DELETE: {},
};

import {
  Invoice,
  InvoiceItem,
  OtcItem,
  Prescription,
} from '@emporos/api-enterprise';
import {OfflineInvoiceItem, OfflineSynced} from '../';
import {
  mapOTCInvoiceItem,
  mapPrescriptionInvoiceItem,
  updateQuantity,
} from './';

export const createInvoicePrescriptionReducer = (invoice: Invoice) => (
  acc: Array<InvoiceItem>,
  prescription: Prescription,
): InvoiceItem[] =>
  createInvoiceReducer(
    acc,
    prescription.itemNumber || '',
    () => mapPrescriptionInvoiceItem(invoice.invoiceId, prescription),
    () =>
      ({isVerified: true, isSynced: false} as OfflineInvoiceItem &
        OfflineSynced),
  );

export const createInvoiceOtcReducer = (invoice: Invoice) => (
  acc: Array<InvoiceItem>,
  otcItem: OtcItem,
): InvoiceItem[] =>
  createInvoiceReducer(
    acc,
    otcItem.itemNumber || '',
    () => mapOTCInvoiceItem(invoice.invoiceId, otcItem),
    prevInvoiceItem => ({
      ...updateQuantity(prevInvoiceItem, prevInvoiceItem.quantity + 1),
      isSynced: false,
    }),
  );

const createInvoiceReducer = (
  invoiceItems: InvoiceItem[],
  itemNumber: string,
  onNew: () => InvoiceItem,
  onUpdate: (prevInvoiceItem: InvoiceItem) => Partial<InvoiceItem>,
): InvoiceItem[] => {
  const index = invoiceItems.findIndex(
    ({itemNumber: _itemNumber}) => itemNumber === _itemNumber,
  );
  if (index > -1) {
    const invoiceItem = invoiceItems[index];
    invoiceItems[index] = {
      ...invoiceItem,
      ...onUpdate(invoiceItem),
    };
  } else {
    invoiceItems.push(onNew());
  }
  return invoiceItems;
};

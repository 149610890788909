import {Gutter, InputSquare, Row} from '@emporos/components';
import {memo} from 'react';
import styled from 'styled-components';
import {AcceptedPaymentTypes} from '../';

interface Props {
  paymentOptions: Set<string>; // TODO: this should be a union of strings
  customerPaymentsAvailable: boolean;
  activePaymentType: AcceptedPaymentTypes;
  setPaymentType: (v: AcceptedPaymentTypes) => void;
  disabled: boolean;
  isOnline: boolean;
}

const PaymentButton = styled(InputSquare)`
  margin-top: 20px;
  width: 96px;
  @media (max-width: 1077px) {
    width: 85px;
  }
  @media (max-width: 1047px) {
    width: 75px;
  }
`;

function PaymentOptionsComponent({
  paymentOptions,
  customerPaymentsAvailable,
  activePaymentType,
  setPaymentType,
  disabled,
  isOnline,
}: Props): JSX.Element {
  const hasUDP = Array.from(paymentOptions).some(n => n.startsWith('User'));
  return (
    <Row gutter={Gutter.None} justify="space-around">
      {paymentOptions.has('Cash') && (
        <PaymentButton
          icon="Cash"
          name="payment-option"
          text="Cash"
          value="Cash"
          checked={activePaymentType === 'Cash'}
          disabled={disabled}
          onChange={() => {
            setPaymentType('Cash');
          }}
        />
      )}
      {paymentOptions.has('Check') && (
        <PaymentButton
          icon="Check"
          name="payment-option"
          text="Check"
          value="Check"
          checked={activePaymentType === 'Check'}
          disabled={disabled}
          onChange={() => setPaymentType('Check')}
        />
      )}
      {paymentOptions.has('Card') && (
        <PaymentButton
          icon="CreditCard"
          name="payment-option"
          text="Credit"
          value="Credit Card"
          checked={activePaymentType === 'Credit Card'}
          disabled={disabled || !isOnline}
          onChange={() => setPaymentType('Credit Card')}
        />
      )}
      {paymentOptions.has('Account Receivable') && (
        <PaymentButton
          icon="Bill"
          name="payment-option"
          text="AR"
          value="AR"
          checked={activePaymentType === 'AR'}
          disabled={disabled || !customerPaymentsAvailable || !isOnline}
          onChange={() => setPaymentType('AR')}
        />
      )}
      {paymentOptions.has('Payroll Deduct') && (
        <PaymentButton
          icon="IdCard"
          name="payment-option"
          text="PD"
          value="PD"
          checked={activePaymentType === 'PD'}
          disabled={disabled || !isOnline}
          onChange={() => {
            setPaymentType('PD');
          }}
        />
      )}
      {hasUDP && (
        <PaymentButton
          icon="MoneyBag"
          name="payment-option"
          text="Custom"
          value="UDP"
          checked={activePaymentType === 'UDP'}
          disabled={disabled}
          onChange={() => {
            setPaymentType('UDP');
          }}
        />
      )}
    </Row>
  );
}
export const PaymentOptions = memo(PaymentOptionsComponent);

import {
  CustomerCreditCard,
  Invoice,
  InvoicePayment,
  PaymentType,
} from '@emporos/api-enterprise';
import {DeviceUnreachable, Processor} from '@emporos/card-payments';
import {
  Button,
  CardOnFile,
  CardOnFileProps,
  Gutter,
  Modal,
  Row,
  ScrollContainer,
  Select,
  Stack,
  Variant,
} from '@emporos/components';
import {NavigateFn} from '@reach/router';
import {
  Dispatch,
  forwardRef,
  SetStateAction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useReducer,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import {
  AlertLogTypes,
  ApiLogTypes,
  Credentials,
  mapCardsToProps,
  PaymentProvider,
  UpdateInvoiceFn,
  useLog,
} from '../../../../../';
import {
  BillBoard,
  HandleTransactionResponse,
  HandleTransactionResponseRef,
  PaymentRef as Handle,
} from './';

interface Props {
  cardsOnFile: Array<CustomerCreditCard>;
  showVantiv?: boolean;
  showHostedPayment?: boolean;
  creditCardPendingPaymentExpirationMinutes: number;
  amount: number;
  setAmount: Dispatch<SetStateAction<number>>;
  setSelectedPayment: Dispatch<SetStateAction<string>>;
  totalDue: number;
  qhpAmount: number;
  deviceCredentials: Credentials;
  onContinue: () => void;
  onChangeActivePayment: () => void;
  navigate: NavigateFn;
  paymentTenders: PaymentType[];
  paymentProvider: PaymentProvider;
  invoice: Invoice;
  updateInvoice: UpdateInvoiceFn;
  cardHolderName?: string;
}

export enum DeviceStatus {
  Unknown,
  Disconnected,
  Connecting,
  Connected,
  DeviceError,
  Timeout,
  DeviceIsUnreachable,
  AppCancelled,
  UserCancelled,
  PaymentError,
  PaymentProcessing,
  CCOFPaymentProcessing,
  PaymentDeclined,
  PaymentWarning,
  PaymentExpired,
  PaymentSuccess,
  PartialPaymentSuccess,
  CancelTransactionFailed,
  CheckStatus,
}
const HOSTED_PAYMENT = 'HostedPayment';

const CardColumns = styled('div')`
  display: inline-flex;
  justify-content: center;
  width: 50%;
  @media (max-width: 1600px) {
    width: 100%;
  }
`;

export const CreditCard = forwardRef<Handle, Props>(function CreditCard(
  {
    cardsOnFile,
    showVantiv,
    showHostedPayment,
    creditCardPendingPaymentExpirationMinutes,
    amount,
    totalDue,
    qhpAmount,
    setAmount,
    deviceCredentials,
    onContinue,
    onChangeActivePayment,
    navigate,
    paymentTenders,
    invoice,
    updateInvoice,
    setSelectedPayment,
    paymentProvider: {
      checkDevice,
      stageTransaction,
      initiateTransaction,
      cancelTransaction,
      getTransactionDetails,
      vaultTransaction,
    },
    cardHolderName,
  }: Props,
  ref,
): JSX.Element {
  const {logAlert, logApi} = useLog();
  const [status, setStatus] = useState(DeviceStatus.Unknown);
  const [value, setValue] = useState<Processor | string>(
    deviceCredentials.processor,
  );
  const [pendingPayment, setPendingPayment] = useState<InvoicePayment | null>(
    null,
  );
  const handleRef = useRef<HandleTransactionResponseRef | null>(null);

  const [creditMethod, setCreditMethod] = useState('Device');

  useEffect(() => {
    onChangeActivePayment();
  }, [value]);

  const logPaymentResult = useCallback(
    (result: string, logStatus?: 'info' | 'error') => {
      logAlert(
        ApiLogTypes.CreditPaymentResult,
        {result},
        logStatus ? logStatus : 'info',
      );
    },
    [logAlert],
  );

  const onPay = useCallback(
    async function onConfirmPayment() {
      setPendingPayment(null);
      switch (value) {
        case Processor.Vantiv:
        case Processor.Cayan:
          {
            try {
              logApi(ApiLogTypes.CreditPaymentPending, {info: 'Cayan'});

              setStatus(DeviceStatus.Connecting);
              logAlert(AlertLogTypes.DeviceConnecting);
              await checkDevice();
              setStatus(DeviceStatus.Connected);
              logAlert(AlertLogTypes.DeviceConnected);

              const getPendingPayment = await stageTransaction({
                totalAmount: Number(amount),
                qhpAmount,
              });
              setPendingPayment(getPendingPayment);
              if (!getPendingPayment.cardToken) {
                throw getPendingPayment;
              }
              const result = await initiateTransaction(
                getPendingPayment.cardToken,
                Number(amount),
              );
              handleRef.current?.handleTransactionResponse(
                result,
                getPendingPayment,
              );
            } catch (e) {
              // if we get back a TransactionAbortSignal message, we know that we
              // called the cancelTransaction() message
              if (e === Symbol.for('TransactionAbortSignal')) {
                return null;
              }
              if ((e as Error).message === DeviceUnreachable) {
                setStatus(DeviceStatus.DeviceIsUnreachable);
                return null;
              }
              setStatus(DeviceStatus.Timeout);
            }
          }
          break;
        default: {
          setStatus(DeviceStatus.CCOFPaymentProcessing);
          const card = cardsOnFile.find(f => f.token === value);
          /* istanbul ignore next */
          if (!card) {
            throw new Error('Unable to find card');
          }
          logApi(ApiLogTypes.CreditPaymentPending, {
            info: 'Using card from file',
          });
          try {
            const result = await vaultTransaction({
              totalAmount: Number(amount),
              qhpAmount,
              cardToken: card.token,
              paymentTypeId: card.paymentTypeId,
              last4OfCard: card.last4OfCard,
              paymentInformationId: card.paymentInformationId,
            });
            if (!result.jsonPaymentProcessorResponse) {
              throw result;
            }
            const processorStatus = JSON.parse(
              result.jsonPaymentProcessorResponse,
            ).Status;
            if (processorStatus !== 'Approved') {
              throw result;
            }
            updateInvoice(prevInvoice => ({
              payments: prevInvoice.payments.concat(result),
            }));
            setStatus(DeviceStatus.PaymentSuccess);
          } catch (e) {
            setStatus(DeviceStatus.PaymentError);
          }
        }
      }
      return null;
    },
    [amount, value, logPaymentResult, pendingPayment],
  );

  const onCheckStatus = useCallback(async () => {
    if (!pendingPayment) return;
    try {
      setStatus(DeviceStatus.CheckStatus);
      setSelectedPayment(pendingPayment.invoicePaymentId);
      const result = await getTransactionDetails(pendingPayment);
      handleRef.current?.handleTransactionResponse(result, pendingPayment);
    } catch (err) {
      setStatus(DeviceStatus.Timeout);
    }
  }, [pendingPayment]);

  useImperativeHandle(ref, () => ({
    onConfirmPayment: onPay,
    isAcceptablePayment: () => value !== HOSTED_PAYMENT,
  }));

  const _onContinue = useCallback(
    function _onContinue() {
      setStatus(DeviceStatus.Unknown);
      onContinue();
    },
    [setStatus, onContinue],
  );

  const onCancel = useCallback(
    function onCancel() {
      setStatus(DeviceStatus.Unknown);
    },
    [setStatus],
  );

  const onCancelTransaction = useCallback(
    async function onCancelTransaction() {
      try {
        await cancelTransaction();
        setStatus(DeviceStatus.AppCancelled);
      } catch (err) {
        setStatus(DeviceStatus.CancelTransactionFailed);
      }
    },
    [setStatus],
  );

  const optionsValue = () => {
    const values: (Processor | string)[] = [Processor.Cayan];
    if (showVantiv) {
      values.push(Processor.Vantiv);
    }
    return [...values];
  };

  const optionsText = () => {
    const texts = ['Cayan Device'];
    if (showVantiv) {
      texts.push('Vantiv Device');
    }
    return [...texts];
  };

  const initialState = {
    cards: mapCardsToProps(cardsOnFile, cardHolderName as string),
  };

  // TODO: figure out how to type these parameters
  // eslint-disable-next-line
  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case 'selectCard':
        const updatedCard = {
          ...action.card,
          isSelected: true,
        };
        const tokenToUse = cardsOnFile.find(
          card => card.last4OfCard === updatedCard.lastFour,
        )?.token;
        if (tokenToUse) {
          setValue(tokenToUse);
        }
        const updatedCards = [...state.cards];
        updatedCards[action.index] = updatedCard;
        const prevSelectedIndex = state.cards.findIndex(
          // TODO: figure out how to type this
          // eslint-disable-next-line
          (card: any) => card.isSelected === true,
        );
        if (prevSelectedIndex > -1) {
          const prevSelectedCardUpdate = {
            ...state.cards[prevSelectedIndex],
            isSelected: false,
          };
          updatedCards[prevSelectedIndex] = prevSelectedCardUpdate;
        }
        const newState = {cards: updatedCards};
        return newState;
      case 'default':
        return state;
    }
  };

  const [stateCards, dispatch] = useReducer(reducer, initialState);

  return (
    <>
      <ScrollContainer>
        <Stack style={{flex: 1}}>
          <BillBoard value={amount} onChange={setAmount} totalDue={totalDue} />
          <Row
            gutter={Gutter.XL}
            noWrap
            data-testid="creditcardpayments-entryoptions"
          >
            <Button
              type="button"
              onClick={() => {
                setCreditMethod('Manual');
                setValue('HOSTED_PAYMENT');
              }}
              variant={
                creditMethod === 'Manual' ? Variant.Primary : Variant.Secondary
              }
              disabled={!showHostedPayment}
              flex
            >
              Manual
            </Button>
            <Button
              type="button"
              onClick={() => setCreditMethod('Device')}
              variant={
                creditMethod === 'Device' ? Variant.Primary : Variant.Secondary
              }
              flex
            >
              Device
            </Button>
            <Button
              type="button"
              onClick={() => {
                setCreditMethod('On File');
                const defaultCard = cardsOnFile.find(
                  card => card.isPrimary === true,
                );
                defaultCard && setValue(defaultCard.token);
              }}
              variant={
                creditMethod === 'On File' ? Variant.Primary : Variant.Secondary
              }
              disabled={cardsOnFile.length === 0}
              flex
            >
              On File
            </Button>
          </Row>
          {creditMethod === 'Manual' && (
            <Row data-testid="creditcardpayments-manualentry">
              <Button
                onClick={() => navigate('hosted-payment', {state: {amount}})}
                style={{flex: 1}}
              >
                Launch Payment Portal
              </Button>
            </Row>
          )}
          {creditMethod === 'Device' && (
            <Row
              style={{marginTop: 36}}
              data-testid="creditcardpayments-deviceentry"
            >
              <Select
                options={optionsValue()}
                optionsText={optionsText()}
                value={value}
                onChangeValue={setValue}
                label="Credit Card"
              />
            </Row>
          )}

          {creditMethod === 'On File' && stateCards && stateCards.cards && (
            <div data-testid="creditcardpayments-onfileentry">
              {stateCards.cards.map((card: CardOnFileProps, index: number) => (
                <CardColumns
                  key={index}
                  onClick={() =>
                    dispatch({
                      type: 'selectCard',
                      card: card,
                      index: index,
                    })
                  }
                >
                  <CardOnFile {...card} key={index} />
                </CardColumns>
              ))}
            </div>
          )}
          {value === HOSTED_PAYMENT && (
            <Row>
              <Button
                onClick={() => navigate('hosted-payment', {state: {amount}})}
                style={{flex: 1}}
              >
                Launch Payment Portal
              </Button>
            </Row>
          )}
        </Stack>
      </ScrollContainer>
      <Modal
        data-testid="Modal__DeviceUnreachable"
        visible={status === DeviceStatus.DeviceIsUnreachable}
        icon="Warning"
        color="warning"
        onCancel={onCancel}
        onContinue={onPay}
        buttonText="Retry"
        title="Device Unreachable"
        subtitle="The device could not be reached, please check your payment device settings."
      />
      <Modal
        data-testid="Modal__DeviceError"
        visible={status === DeviceStatus.Timeout}
        icon="Warning"
        color="warning"
        onCancel={onCancel}
        onContinue={onCheckStatus}
        buttonText="Check Status"
        title="Payment Device Timeout"
        subtitle="Something didn’t work with the payment device. Would you like to try again?"
      />
      <Modal
        visible={status === DeviceStatus.Connected}
        data-testid="Modal__Connected"
        icon="Spinner"
        color="primary"
        iconSpinning={true}
        title="Connection is Established"
        subtitle="Connection has been established. Please complete payment on the device."
        buttonText="Cancel"
        onContinue={onCancelTransaction}
      />
      <Modal
        visible={status === DeviceStatus.CCOFPaymentProcessing}
        data-testid="Modal__CCOFPaymentProcessing"
        icon="Spinner"
        color="primary"
        iconSpinning={true}
        title="Processing CCOF Payment"
        subtitle="The payment is currently processing. Please hold tight."
      />
      <HandleTransactionResponse
        onCancel={onCancel}
        onContinue={_onContinue}
        invoice={invoice}
        updateInvoice={updateInvoice}
        paymentTenders={paymentTenders}
        status={status}
        setStatus={setStatus}
        setSelectedPayment={setSelectedPayment}
        navigate={navigate}
        ref={handleRef}
        creditCardPendingPaymentExpirationMinutes={
          creditCardPendingPaymentExpirationMinutes
        }
      />
    </>
  );
});

import {InvoiceItem} from '@emporos/api-enterprise';
import {CompleteBarcodeComponent} from '@emporos/barcodes';
import {Redirect} from '@reach/router';
import {memo, useCallback, useEffect, useMemo} from 'react';
import {
  Intermission,
  Button,
  FooterGroup,
  Gutter,
  Header,
  Stack,
  Variant as BV,
} from '@emporos/components';
import {
  AddItemsResult,
  createInvoiceOtcReducer,
  InvoiceLogTypes,
  OfflineInvoice,
  OtcItemsPanel,
  useCustomerSearch,
  useGlobalData,
  useLog,
  useNetworkAvailable,
  usePendingInvoice,
  useTransactionsState,
  withChildPage,
} from '../../../../';

const OtcSearchPageComponent = withChildPage(function (props) {
  const {logUserSelection} = useLog();
  const {navigate} = props;
  const {currentInvoiceId} = useTransactionsState();

  if (!currentInvoiceId) {
    return <Redirect to="/sales" noThrow />;
  }

  const online = useNetworkAvailable();
  const {
    pmsName,
    barcodesResult,
    barcodeName,
    otcItemsResult,
    loading,
  } = useGlobalData();
  const {customer: searchCustomer} = useCustomerSearch();
  const onCancel = useCallback(() => navigate('../'), [navigate]);
  const {
    invoice,
    commit,
    tryAttachCustomer,
    invoiceChanged,
  } = usePendingInvoice();
  const customer = useMemo(() => invoice.customer, [invoice]);
  const barcodeComponents = useMemo(
    () =>
      barcodesResult && barcodesResult.data
        ? (barcodesResult.data.barcodeComponents.filter(
            barcodeComponent => barcodeComponent.barcodeName === barcodeName,
          ) as CompleteBarcodeComponent[])
        : [],
    [barcodesResult],
  );

  useEffect(() => {
    if (searchCustomer) {
      tryAttachCustomer(searchCustomer);
    }
  }, [searchCustomer]);

  const onAdd = useCallback(
    (addItemsResult: AddItemsResult) => {
      const {otc} = addItemsResult;
      let items: InvoiceItem[] = [...invoice.items];
      if (otc) {
        items = otc.reduce(createInvoiceOtcReducer(invoice), items);
      }
      logUserSelection(InvoiceLogTypes.AddItem, {item: items});
      commit({items});
      navigate('../');
    },
    [logUserSelection, navigate, invoice, customer],
  );

  return (
    <>
      {loading && (
        <Stack
          gutter={Gutter.XL}
          justify="stretch"
          style={{height: 'calc(var(--vh, 1vh) * 100 - 140px)'}}
          as="form"
        >
          <Header title="OTC Items Loading..." />

          <Intermission />

          <FooterGroup>
            <Button
              type="button"
              onClick={onCancel}
              variant={BV.Secondary}
              flex
            >
              Cancel
            </Button>
          </FooterGroup>
        </Stack>
      )}
      {!loading && (
        <OtcItemsPanel
          barcodeComponents={barcodeComponents}
          currentInvoiceId={currentInvoiceId}
          invoice={invoice as OfflineInvoice}
          invoiceChanged={invoiceChanged}
          onAdd={onAdd}
          onCancel={onCancel}
          otcItems={(otcItemsResult && otcItemsResult.data) || []}
          online={online}
          pmsName={pmsName}
          title="OTC Search"
        />
      )}
    </>
  );
});

export const OtcSearchPage = memo(OtcSearchPageComponent);

import {ReactNode} from 'react';
import {ThemeProvider} from 'styled-components';
import {theme} from './';

interface Props {
  children: ReactNode;
}
export function TestThemeContainer({children}: Props): JSX.Element {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

import {token} from 'brandi';
import {
  IInvoiceDatabaseAccess,
  IInvoicePaymentsDatabaseAccess,
} from './localDb/IDatabaseAccess';
import {IInvoiceService} from './services/IInvoiceService';

export const TOKENS = {
  InvoiceService: token<IInvoiceService>('InvoiceService'),
  InvoiceDatabaseAccess: token<IInvoiceDatabaseAccess>('InvoiceDatabaseAccess'),
  InvoicePaymentsDatabaseAccess: token<IInvoicePaymentsDatabaseAccess>(
    'IInvoicePaymentsDatabaseAccess',
  ),
};

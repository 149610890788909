/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InvoiceTaxRequest
 */
export interface InvoiceTaxRequest {
    /**
     * 
     * @type {string}
     * @memberof InvoiceTaxRequest
     */
    invoiceTaxId: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceTaxRequest
     */
    taxID: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceTaxRequest
     */
    transactionID: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceTaxRequest
     */
    taxAmount: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceTaxRequest
     */
    taxRateID: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceTaxRequest
     */
    taxRate: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceTaxRequest
     */
    taxableSubTotal: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceTaxRequest
     */
    taxGroupID?: number | null;
}

export function InvoiceTaxRequestFromJSON(json: any): InvoiceTaxRequest {
    return InvoiceTaxRequestFromJSONTyped(json, false);
}

export function InvoiceTaxRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceTaxRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'invoiceTaxId': json['invoiceTaxId'],
        'taxID': json['taxID'],
        'transactionID': json['transactionID'],
        'taxAmount': json['taxAmount'],
        'taxRateID': json['taxRateID'],
        'taxRate': json['taxRate'],
        'taxableSubTotal': json['taxableSubTotal'],
        'taxGroupID': !exists(json, 'taxGroupID') ? undefined : json['taxGroupID'],
    };
}

export function InvoiceTaxRequestToJSON(value?: InvoiceTaxRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'invoiceTaxId': value.invoiceTaxId,
        'taxID': value.taxID,
        'transactionID': value.transactionID,
        'taxAmount': value.taxAmount,
        'taxRateID': value.taxRateID,
        'taxRate': value.taxRate,
        'taxableSubTotal': value.taxableSubTotal,
        'taxGroupID': value.taxGroupID,
    };
}



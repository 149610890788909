import {InvoiceTax} from '@emporos/api-enterprise';
import {mapInvoiceTax, round, useGlobalData, useInvoice} from '../';

const READY_TO_DELIVER = 1;
const LEAVE = 3;
const DELIVERED = 4;
const MEDICATION = 14;

export interface TotalsResult {
  prescriptionCopay: number;
  overTheCounter: number;
  salesTax: number;
  qhpAmount: number;
  totalDue: number;
  changeDue: number;
  transactionTotal: number;
  subTotal: number;
  taxableSubTotal: number;
  discount: number;
  qhpRxQty: number;
  qhpRxAmount: number;
  qhpOtherQty: number;
  qhpOtherAmount: number;
  invoiceTaxes: InvoiceTax[];
}

export const useTotals = (): {totals: TotalsResult; taxGroupId: number} => {
  const {settingsResult, taxGroupsResult} = useGlobalData();
  const {invoice, processedPayments} = useInvoice();
  const {invoiceId, items} = invoice;
  const taxes = ((taxGroupsResult && taxGroupsResult.data) || []).map(tax =>
    mapInvoiceTax(invoiceId, tax),
  );

  const defaultTaxGroupId =
    (settingsResult &&
      settingsResult.data?.find(({name}) => name === 'DefaultTaxGroup')
        ?.value) ||
    '0';

  const totals = (): TotalsResult => {
    let prescriptionCopay = 0;
    let overTheCounter = 0;
    let salesTax = 0;
    let qhpTax = 0;
    let qhpRxQty = 0;
    let qhpRxAmount = 0;
    let qhpOtherQty = 0;
    let qhpOtherAmount = 0;
    let taxableSubTotal = 0;
    let subTotal = 0;
    let discount = 0;

    items.forEach(item => {
      const isOtc = !item.rx?.trim();
      const isQhp = item.qhpIndicator || item.qhpPrivateIndicator;
      const isRxMedication = item.itemTypeID === MEDICATION;
      const itemPrice = item.extension;

      if (
        // If the Medication is not marked ReadyToDeliver (or Delivered) and it's not OTC (meaning Rx) we don't count the cost
        (item.medicationStatus !== READY_TO_DELIVER &&
          item.medicationStatus !== DELIVERED &&
          !isOtc) ||
        // If the item is an OTC and the status is leave, we don't count the cost
        (isOtc && item.medicationStatus === LEAVE)
      ) {
        return;
      }

      discount += item.discount;
      subTotal += item.extensionBeforeDiscount || 0;
      if (isOtc) {
        overTheCounter += itemPrice;
      } else {
        prescriptionCopay += itemPrice;
      }

      if (item.taxableIndicator) {
        taxableSubTotal += itemPrice;
      }

      if (item.taxableIndicator) {
        taxes
          .filter(tax => tax.taxGroupID === item.taxGroupID)
          .forEach(tax => {
            const taxAmount = round(
              tax.taxRate ? itemPrice * (tax.taxRate / 100) : 0,
              4,
            );

            item.taxGroupID = tax.taxGroupID || 0;
            tax.taxAmount = round((tax.taxAmount || 0) + taxAmount, 4);
            salesTax += round(taxAmount, 4);
            if (isQhp) {
              qhpTax += round(taxAmount, 4);
            }
          });
      }

      if (isRxMedication) {
        qhpRxAmount += itemPrice;
        qhpRxQty++;
      } else if (isQhp) {
        qhpOtherAmount += itemPrice;
        qhpOtherQty++;
      }
    });

    prescriptionCopay = round(prescriptionCopay);
    overTheCounter = round(overTheCounter);
    salesTax = round(salesTax);

    //Sum only the active payments.
    const totalPaymentAmount = processedPayments.reduce(
      (accumulator, payment) => accumulator + payment.amount,
      0,
    );

    const transactionTotal = round(subTotal + salesTax - discount);
    const totalDue =
      transactionTotal - totalPaymentAmount <= 0
        ? 0
        : round(transactionTotal - totalPaymentAmount);
    const changeDue =
      transactionTotal - totalPaymentAmount < 0
        ? transactionTotal - totalPaymentAmount
        : 0;
    qhpOtherAmount = qhpOtherAmount + round(qhpTax);
    const qhpAmount = qhpOtherAmount + qhpRxAmount;

    return {
      prescriptionCopay,
      overTheCounter,
      salesTax,
      qhpAmount,
      totalDue,
      changeDue,
      transactionTotal,
      subTotal,
      taxableSubTotal,
      discount,
      qhpRxQty,
      qhpRxAmount,
      qhpOtherQty,
      qhpOtherAmount,
      invoiceTaxes: taxes,
    };
  };

  return {totals: totals(), taxGroupId: Number(defaultTaxGroupId)};
};

import {render} from 'react-dom';
import App from './App';
import {ContainerProvider} from 'brandi-react';
import {DIFactory} from './DIFactory';
import React from 'react';

// DI
const container = DIFactory.buildDefaultContainer();

render(
  <React.StrictMode>
    <ContainerProvider container={container}>
      <App />
    </ContainerProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

import {Table} from 'dexie';
import {InvoicePaymentConsolidate} from '../api';
import {HiloDb} from './dbcontext';
import {IInvoicePaymentsDatabaseAccess} from './IDatabaseAccess';

const devLogging = String(process.env.NODE_ENV).trim() === 'development';

// instantiate with DIFactory.getInvoiceDatabaseAccess
export class InvoicePaymentLocaldb implements IInvoicePaymentsDatabaseAccess {
  db!: HiloDb;
  table!: Table<InvoicePaymentConsolidate>;
  private _sessionId!: string;

  initialize = (sessionId: string): void => {
    this._sessionId = sessionId;
    this.db = new HiloDb(this._sessionId);
    this.table = this.db.InvoicePaymetLocaldb;
  };

  async getAll(invoiceId: string): Promise<InvoicePaymentConsolidate[]> {
    return this.table
      .where({sessionId: this._sessionId, invoiceId: invoiceId})
      .toArray();
  }

  async get(invoicePaymentId: string): Promise<InvoicePaymentConsolidate> {
    const invoicePaymentresult = await this.table.get(invoicePaymentId);

    if (invoicePaymentresult) {
      return invoicePaymentresult;
    } else {
      return {} as InvoicePaymentConsolidate;
    }
  }

  async add(
    invoicePayment: InvoicePaymentConsolidate,
  ): Promise<InvoicePaymentConsolidate> {
    await this.table.put(invoicePayment);

    return invoicePayment;
  }

  async update(
    invoicePayment: InvoicePaymentConsolidate,
  ): Promise<InvoicePaymentConsolidate> {
    await this.table.put(invoicePayment);

    return invoicePayment;
  }

  async delete(invoicePaymentId: string): Promise<void> {
    return this.table.delete(invoicePaymentId);
  }

  async deleteAll(): Promise<void> {
    devLogging
      ? console.log('%cDeleteAll !', 'background-color:#FFAEC3; color: black')
      : null;
    return this.table.clear();
  }
}

import {Invoice, InvoiceRequest} from '@emporos/api-enterprise';
import {ConvertInvoiceExtrasToRequests} from './InvoiceExtraConverter';
import {ConvertInvoiceIdentificationToRequest} from './InvoiceIdentificationConverter';
import {ConvertInvoiceItemsToRequests} from './InvoiceItemConverter';
import {ConvertInvoiceSignaturesToRequests} from './InvoiceSignatureConverter';
import {ConvertInvoiceTaxesToRequests} from './InvoiceTaxConverter';

export function ConvertInvoicesToRequests(
  invoices: Invoice[],
): InvoiceRequest[] {
  const requests: InvoiceRequest[] = [];
  invoices.forEach(invoice => {
    requests.push(ConvertInvoiceToRequest(invoice));
  });
  return requests;
}

export function ConvertInvoiceToRequest(invoice: Invoice): InvoiceRequest {
  return {
    invoiceId: invoice.invoiceId,
    sessionId: invoice.sessionId,
    customerId: invoice.customerId,
    saleDate: invoice.saleDate,
    subTotal: invoice.subTotal,
    taxableSubTotal: invoice.taxableSubTotal,
    totalCost: invoice.totalCost,
    discount: invoice.discount,
    totalTax: invoice.totalTax,
    totalSale: invoice.totalSale,
    source: invoice.source,
    qhpRxQty: invoice.qhpRxQty,
    qhpRxAmount: invoice.qhpRxAmount,
    qhpOtherQty: invoice.qhpOtherQty,
    qhpOtherAmount: invoice.qhpOtherAmount,
    qhpCount: invoice.qhpCount,
    qhpAmount: invoice.qhpAmount,
    qhpPartial: invoice.qhpPartial,
    pseApprovedTransID: invoice.pseApprovedTransID,
    consumerVerifyID: invoice.consumerVerifyID,
    deliveryMethods: invoice.deliveryMethods,
    station: invoice.station,
    employeeID: invoice.employeeID,
    taxExemptIndicator: invoice.taxExemptIndicator,
    xFileIndicator: invoice.xFileIndicator,
    xFileOriginatingTransactionID: invoice.xFileOriginatingTransactionID,
    xFileTransactionID: invoice.xFileTransactionID,
    taxExemptNumber: invoice.taxExemptNumber,
    orderID: invoice.orderID,
    reference: invoice.reference,
    sourceID: invoice.sourceID,
    onHoldAtServerIndicator: invoice.onHoldAtServerIndicator,
    saleSiteID: invoice.saleSiteID,
    salesAuditDate: invoice.salesAuditDate,
    salesAuditIndicator: invoice.salesAuditIndicator,
    dispensingID: invoice.dispensingID,
    signatureStatus: invoice.signatureStatus,
    rxSigType: invoice.rxSigType,
    sudafedSigType: invoice.sudafedSigType,
    safetyCapsSigType: invoice.safetyCapsSigType,
    pmsStatus: invoice.pmsStatus,
    ageSource: invoice.ageSource,
    age: invoice.age,
    onDeliveryBypassCounselPrompt: invoice.onDeliveryBypassCounselPrompt,
    onDeliveryBypassHIPAAPrompt: invoice.onDeliveryBypassHIPAAPrompt,
    emailReceipt: invoice.emailReceipt,
    poNumber: invoice.poNumber,
    pseCheckResult: invoice.pseCheckResult,
    roomNumber: invoice.roomNumber,
    notes: invoice.notes,
    dataVersion: invoice.dataVersion,
    items: ConvertInvoiceItemsToRequests(invoice.items),
    signatures: ConvertInvoiceSignaturesToRequests(invoice.signatures),
    extras: ConvertInvoiceExtrasToRequests(invoice.extras),
    taxes: ConvertInvoiceTaxesToRequests(invoice.taxes),
    identification: ConvertInvoiceIdentificationToRequest(
      invoice.identification,
    ),
  };
}

import {
  InvoicePayment,
  PaymentProcessorResponse,
  PaymentStateEnum,
  PaymentVoidResponseApiResponse,
} from '@emporos/api-enterprise';
import {
  CayanTransactionResponse,
  PaymentTypes,
  VantivReversalRequest,
  VantivSalesResponse,
  VantivSalesTransaction,
} from '@emporos/card-payments';
import assert from 'assert';

export interface PaymentInfoData {
  paymentId: string;
  amountApproved: number;
  epsReference?: string;
  paymentTypeId?: number;
  paymentTypeDesc?: string;
  recordStatus?: string;
  jsonPaymentProcessorResponse?: string;
}

/**
 * Each payment type that has logic for creating a payment or whether the
 * <Button>Confirm Payment</Button> should be active is delegated to...shoot
 */
export type PaymentRef = {
  onConfirmPayment(): Promise<PaymentInfoData | null>;

  /**
   * This method is used for inversion of control between the CustomerPayment
   * integration manager and the payment type that contains it’s allowed logic.
   * The intent here is to keep the managing component ignorant of the
   * requirements of each payment area.
   */
  isAcceptablePayment(): boolean;
};

export type HandleTransactionResponseRef = {
  handleTransactionResponse(
    result: TransactionResponse,
    pendingPayment?: InvoicePayment,
  ): void;
};

export type Logger = typeof console['log'];

type PaymentType = 'MasterCard' | 'Visa' | string;
export interface CayanVoidProxyResponse {
  data: {
    authorizationCode: 'string';
    status:
      | 'Unknown'
      | 'Approved'
      | 'Declined'
      | 'Cancelled'
      | 'NotAccessible'
      | 'Retry';
    referenceNumber: string;
    messages: Array<string>;
  };
}

export interface CayanVaultProxyResponse {
  data: {
    status: 'Unknown' | 'Approved' | 'Declined';
    amountApproved: 0;
    paymentType: PaymentType;
    paymentTypeDesc: string;
    accountNumber: string;
    message: string;
    authorizationCode: string;
    referenceNumber: string;
  };
}
export interface CayanConfig {
  // common, for integration
  gatewayUsername: string;
  gatewayId: string;
  gatewayPassword: string;
  // mapped to cayan ip
  gatewayTerminal: string;
  // mapped to terminalId
  processorLaneId: string;

  // for our client device implementation. should we use https or http?
  useSecureProtocol?: boolean;
  // for our API proxy
  accessToken: string;

  // common, for our code
  appName: string;
  appVersion: string;
  siteId: string;
}

export interface VantivConfig {
  // common, for integration
  gatewayUsername: string;
  gatewayId: string;
  gatewayPassword: string;
  // mapped to terminal id
  gatewayTerminal: string;

  // common, for our code
  appName: string;
  appVersion: string;
  siteId: string;

  partnerId: string;
  processorLaneId: number;
}

export interface Device {
  checkStatus(): Promise<void>;
  requestTransaction(t: TransactionRequest): Promise<TransactionResponse>;
  requestTransactionDetails(
    t: TransactionDetailsRequest,
  ): Promise<TransactionResponse>;
  requestVaultTransaction(
    t: TransactionVaultRequest,
  ): Promise<TransactionResponse>;
  cancelTransaction(): Promise<'Okay'>;
  voidTransaction(request: VoidRequest): Promise<VoidResponse>;

  // we use this hook to expose internals for testing purposes. Since this is
  // only for testing we provide no type guarantees around it
  __TEST_UTILS__?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getClient: () => any;
  };
}

export interface TransactionRequest {
  clerkId: string;
  orderId: string;
  amount: number;
  taxAmount: number;
  qhpAmount: number;
}

export interface TransactionDetailsRequest {
  orderId: string;
}
export interface TransactionVaultRequest {
  vaultToken: string;
  clerkId: string;
  orderId: string;
  amount: number;
  taxAmount: number;
  qhpAmount: number;
}

export interface VoidRequest {
  clerkId: string;
  orderId: string;
  amount: number;
  transactionId: string;
}

const CreditSaleSignaturePrompt = {
  Always: 0,
  Never: 1,
  UseThreshold: 2,
  None: -1,
};

export function transformTransactionToVantiv(
  r: TransactionRequest,
  c: VantivConfig,
): VantivSalesTransaction {
  return {
    clerkNumber: r.clerkId,
    // 'TransactionId',
    referenceNumber: r.orderId,
    // TODO: in enterprise this is stationNumber + transactionId
    ticketNumber: r.orderId,
    transactionAmount: r.amount,
    salesTaxAmount: r.taxAmount,
    laneId: c.processorLaneId,
    shiftId: 'ShiftA',
    healthcare: {
      total: r.qhpAmount,
    },
    configuration: {
      allowPartialApprovals: true,
      checkForDuplicateTransactions: true,
      marketCode: 'Retail', // the only instance of not Retail in the API is for testing
      allowDebit: true,
      promptForSignature: CreditSaleSignaturePrompt.Never.toString(),
    },
  };
}

export function transformVoidToVantiv(
  r: VoidRequest,
  c: VantivConfig,
): VantivReversalRequest {
  return {
    cardHolderPresentCode: 'Default',
    laneId: c.processorLaneId,
    transactionAmount: r.amount,
    // transactionId: r.transactionId, this is required, but part of the Path
    referenceNumber: r.transactionId, // ReferenceNumber
    shiftId: 'ShiftA',
    clerkNumber: r.clerkId,
    ticketNumber: 'TODO: PurchaseOrderNumber',
    configuration: {
      marketCode: 'Retail',
    },
  };
}

export type Status =
  | 'APPROVED'
  | 'DECLINED'
  | 'ERROR'
  | 'FAILED'
  | 'CANCELLED'
  | 'OVERCHARGED'
  // Vantiv codes
  | 'HOSTERROR'
  | 'DUPLICATE'
  | 'PARTIAL APPROVED'
  | 'EXPIRED CARD'
  | 'DECLINED - PICK UP CARD'
  | 'CALL ISSUER'
  | 'NOT DEFINED'
  | 'INVALID DATA'
  | 'INVALID ACCOUNT'
  | 'INVALID REQUEST'
  | 'AUTHENTICATION FAILED'
  | 'OUT OF BALANCE'
  | 'NOTACCESSIBLE'
  | 'NOT AUTHORIZED';

// accountNumber and transactionId can be undefined in error scenarios, but we
// are keeping our types as more restrictive for the moment.
export interface TransactionResponse {
  amountApproved: string | number;
  accountNumber: string;
  entryMode: // Cayan
  | 'PROXIMITY'
    | 'CARDREADER'
    // Vantiv
    | 'Swiped'
    | 'VAULT'
    | string;
  paymentType: 'MasterCard' | 'Visa' | string;
  status: Status;
  transactionDate: string;
  transactionId: string;
  rawResponse: string;
}
export interface VoidResponse {
  approvalNumber: string;
  isApproved: boolean;
  totalAmount: number;
  transactionId: string;
  balanceAmount: number;
}
export interface VantivVoidResponse {
  accountNumber: string;
  approvalNumber: string;
  balanceAmount: number;
  cardLogo: string;
  convenienceFeeAmount: number;
  isApproved: boolean;
  isOffline: boolean;
  merchantId: string;
  paymentType: string;
  statusCode: string;
  terminalId: string;
  tokenId: string;
  tokenProvider: string;
  totalAmount: number;
  transactionDateTime: string;
  transactionId: string;
}

export function transformCayanToTransactionResponse(
  r: CayanTransactionResponse,
  amount: number,
): TransactionResponse {
  return {
    amountApproved: r.AmountApproved,
    entryMode: r.EntryMode,
    accountNumber: r.AccountNumber,
    paymentType:
      Object.keys(PaymentTypes)
        .filter(k => k === r.PaymentType)
        .map(k => PaymentTypes[k])[0] || '',
    // TODO: we don’t have an enum of these, so we need to reverse engineer them
    // into our common set
    status: (() => {
      switch (r.Status) {
        case 'DECLINED': {
          // Cayan doesn’t have as robust of response types as Vantiv, so we do
          // some duck punching for common failure cases we have detected.
          if (r.AuthorizationCode === 'Invalid_Expiration_Date') {
            return 'EXPIRED CARD';
          }
          if (/Insufficient Funds/.test(r.ErrorMessage)) {
            return 'OUT OF BALANCE';
          }
          return r.Status;
        }
        case 'DECLINED_DUPLICATE':
          return 'DUPLICATE';
        case 'POSCancelled':
        case 'UserCancelled':
          return 'CANCELLED';
        case 'APPROVED':
          if (Number(r.AmountApproved) < amount) {
            return 'PARTIAL APPROVED';
          } else if (Number(r.AmountApproved) > amount) {
            return 'OVERCHARGED';
          } else {
            return r.Status;
          }
        default:
          return r.Status;
      }
    })(),
    transactionDate: r.TransactionDate,
    transactionId: r.Token,
    rawResponse: JSON.stringify(r),
  };
}

export function transformCayanVaultToTransactionResponse(
  r: PaymentProcessorResponse,
): TransactionResponse {
  return {
    amountApproved: r.amountApproved,
    entryMode: 'Vault' as const,
    accountNumber: r.accountNumber,
    paymentType: r.paymentType,
    status: (() => {
      // this is only until Tom implements real status responses and documents
      // them
      switch (r.status) {
        // Unknown
        case PaymentStateEnum.Unknown:
          if (r.amountApproved) {
            return 'APPROVED';
          }
          break;
        // Approved
        case PaymentStateEnum.Approved:
          return 'APPROVED';
        // Declined
        case PaymentStateEnum.Declined:
          // Cayan doesn’t have as robust of response types as Vantiv, so we do
          // some duck punching for common failure cases we have detected.
          if (r.authorizationCode === 'Invalid_Expiration_Date') {
            return 'EXPIRED CARD';
          }
          /* TODO: force this error case in the API and see how the details call
           * handles it.
          if (/Insufficient Funds/.test(r.ErrorMessage)) {
            return 'OUT OF BALANCE';
          }
          */
          return 'DECLINED';
        // Failed
        case PaymentStateEnum.Failed:
          return 'FAILED';
        // Cancelled
        case PaymentStateEnum.Cancelled:
          return 'CANCELLED';

        /* TODO: force this case in the API and test how the details call
             * responds with partially approved Cayan responses
          case 'APPROVED':
            if (Number(r.AmountApproved) < t.amount) {
              return 'PARTIAL APPROVED';
            } else {
              return r.Status;
            }
            */
      }
      return 'FAILED';
    })(),
    transactionDate: new Date().toISOString(),
    transactionId: r.referenceNumber,
    rawResponse: JSON.stringify(r.rawResponse),
  };
}
export function transformCayanToVoidResponse(
  r: PaymentVoidResponseApiResponse,
): VoidResponse {
  assert(r.data, 'Missing data');
  return {
    approvalNumber: r.data.authorizationCode,
    isApproved: r.data.status === 'Approved',
    // unused by cayan
    totalAmount: 0,
    transactionId: r.data.referenceNumber,
    // unused by cayan
    balanceAmount: 0,
  };
}

export function transformVantivToVoidResponse(
  r: VantivVoidResponse,
): VoidResponse {
  return {
    approvalNumber: r.approvalNumber,
    isApproved: r.isApproved,
    totalAmount: r.totalAmount,
    transactionId: r.transactionId,
    balanceAmount: r.balanceAmount,
  };
}
export function transformVantivToTransactionResponse(
  r: VantivSalesResponse,
): TransactionResponse {
  const status = (r._processor.hasOwnProperty('processorResponseMessage')
    ? r._processor.processorResponseMessage.toUpperCase()
    : r.statusCode.toUpperCase()) as Status;
  return {
    amountApproved: r.approvedAmount,
    entryMode: r.entryMode, // 'Swiped' | [...]
    accountNumber: r.accountNumber,
    paymentType: r.paymentType,
    status: status,
    transactionDate: r.transactionDateTime,
    transactionId: r.transactionId,
    rawResponse: JSON.stringify(r),
  };
}

import {
  AuthenticationProvider as OIDCAuthenticationProvider,
  oidcLog,
} from '@axa-fr/react-oidc-context';
import {Intermission, StackProvider, theme} from '@emporos/components';
import {Redirect, Router} from '@reach/router';
import styled, {ThemeProvider} from 'styled-components';
import {
  AlertStateProvider,
  AnalyticsProvider,
  AuthenticationProvider,
  BarcodeScannerProvider,
  BetaFeatureProvider,
  CardReaderCredentialsProvider,
  CloseSessionContainer as CloseSession,
  CreateSessionContainer as CreateSession,
  CreditCardProcessingProvider,
  GlobalDataProvider,
  GlobalStyles,
  LoggingProvider,
  NetworkAvailableProvider,
  oidcCallbackComponents,
  oidcConfiguration,
  Routes,
  SettingsProvider,
  SyncWarnings,
  TransactionsConfigProvider,
  TransactionsStateProvider,
  useTransactionsConfig,
} from './';
import {ApiProvider} from './contexts/ApiProvider';

const {NODE_ENV, OIDC_LOG_LEVEL} = process.env;

const Background = styled.div`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

/* istanbul ignore next: we don’t care about testing log level configuration */
let loggerLevel: number | undefined;

/* istanbul ignore next: we don’t care about testing log level configuration */
if (String(NODE_ENV) === 'production') {
  loggerLevel = oidcLog.ERROR;
} else if (OIDC_LOG_LEVEL) {
  loggerLevel =
    oidcLog[OIDC_LOG_LEVEL as 'NONE' | 'ERROR' | 'WARN' | 'INFO' | 'DEBUG'];
} else {
  loggerLevel = oidcLog.DEBUG;
}

function ConfigureOrLoadSession() {
  const {
    session,
    loading: sessionLoading,
    ready,
    hardLoadingSession,
    sessionClosed,
  } = useTransactionsConfig();

  if (!hardLoadingSession && (sessionLoading || !ready)) {
    return <Intermission />;
  }

  if (!session) {
    return sessionClosed ? (
      <Router>
        <CloseSession path="/login/close-session" />
        <Redirect from="/" to="/login/close-session" default noThrow />
      </Router>
    ) : (
      <Router>
        <CreateSession path="/login/create-session" />
        <Redirect from="/" to="/login/create-session" default noThrow />
      </Router>
    );
  }

  return (
    <ApiProvider>
      <TransactionsStateProvider>
        <GlobalDataProvider>
          <SyncWarnings>
            <CardReaderCredentialsProvider>
              <BarcodeScannerProvider>
                <CardReaderCredentialsProvider>
                  <CreditCardProcessingProvider>
                    <Routes />
                  </CreditCardProcessingProvider>
                </CardReaderCredentialsProvider>
              </BarcodeScannerProvider>
            </CardReaderCredentialsProvider>
          </SyncWarnings>
        </GlobalDataProvider>
      </TransactionsStateProvider>
    </ApiProvider>
  );
}

export default function App(): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Background>
        <ApiProvider>
          <SettingsProvider>
            <NetworkAvailableProvider>
              <LoggingProvider>
                <AnalyticsProvider>
                  <AlertStateProvider>
                    <StackProvider>
                      <OIDCAuthenticationProvider
                        configuration={oidcConfiguration}
                        isEnabled={String(NODE_ENV) !== 'test'}
                        loggerLevel={loggerLevel}
                        {...oidcCallbackComponents}
                      >
                        <AuthenticationProvider>
                          <TransactionsConfigProvider>
                            <BetaFeatureProvider>
                              <ConfigureOrLoadSession />
                            </BetaFeatureProvider>
                          </TransactionsConfigProvider>
                        </AuthenticationProvider>
                      </OIDCAuthenticationProvider>
                    </StackProvider>
                  </AlertStateProvider>
                </AnalyticsProvider>
              </LoggingProvider>
            </NetworkAvailableProvider>
          </SettingsProvider>
        </ApiProvider>
      </Background>
    </ThemeProvider>
  );
}

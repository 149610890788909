import {BarcodeComponent} from '@emporos/api-enterprise';
import {orderBy, trimEnd, trimStart} from 'lodash';
import {ScanResult, Symbology} from './';

interface BarcodeParsedResult {
  rx: string | void;
  partial: string | void;
  site: string | void;
  fill: string | void;
}

type Complete<T> = {
  [P in keyof Required<T>]: Required<T>[P] extends null | infer X
    ? X
    : Required<T>[P];
};

export type CompleteBarcodeComponent = Complete<BarcodeComponent>;

const IDENTIFICATION_BARCODE_COMPONENT_NAME = 'HoneywellXenon1900DriverLicense';
const DELIMITER = 'delimiter';
const PREFIX = 'prefix';
const RX_NUMBER = 'rxnumber';
const PAYOR_PLAN_1 = 'payorplan1';
const PAYOR_PLAN_2 = 'payorplan2';
const PAYOR_PLAN_3 = 'payorplan3';
const PAYOR_PLAN_4 = 'payorplan4';
const PAYOR_PLAN_5 = 'payorplan5';
const payorPlanNames = new Set([
  PAYOR_PLAN_1,
  PAYOR_PLAN_2,
  PAYOR_PLAN_3,
  PAYOR_PLAN_4,
  PAYOR_PLAN_5,
]);

function isRx(symbology: Symbology): boolean {
  return symbology === Symbology.Aztec || symbology === Symbology.Code128;
}

export function decode(
  barcodeComponents: CompleteBarcodeComponent[],
  scanResult: ScanResult,
  pmsName: string,
): BarcodeParsedResult {
  const barcode = scanResult.raw;
  let delimiter = '';
  const componentValues: Array<string> = [];
  const componentNames: Array<string> = [];
  // we precalc this so we can get hidden class optimizations
  const parsedResult = {
    rx: undefined,
    fill: undefined,
    partial: undefined,
    site: undefined,
  } as BarcodeParsedResult;
  let regexPattern = '';

  if (
    barcodeComponents.length === 0 ||
    (scanResult.symbology && !isRx(scanResult.symbology))
  ) {
    return parsedResult;
  } else if (barcodeComponents.length === 1) {
    componentNames.push(barcodeComponents[0].componentName.toLowerCase());
    regexPattern = barcodeComponents[0].componentMask;
  } else {
    orderBy(barcodeComponents, ['componentIndex'], ['asc']).forEach(
      barcodeComp => {
        if (barcodeComp.componentName && barcodeComp.componentMask) {
          const name = barcodeComp.componentName.toLowerCase();
          if (barcodeComp.componentIndex <= 0) {
            if (name === DELIMITER) {
              delimiter = barcodeComp.componentMask;
            }
          } else {
            componentNames.push(name);
            const mask = trimEnd(
              trimStart(barcodeComp.componentMask, '^'),
              '$',
            );

            if (
              barcodeComp.componentName === PREFIX ||
              (barcodeComp.componentName === RX_NUMBER &&
                barcodeComp.barcodeName === 'StandardAztecEPIC')
            ) {
              regexPattern = regexPattern + '(' + mask + ')';
            } else if (payorPlanNames.has(name)) {
              regexPattern = regexPattern + '(\\' + delimiter + mask + ')?';
            } else {
              regexPattern =
                regexPattern +
                '(\\' +
                (barcode.includes(delimiter) ? delimiter : '') +
                mask +
                ')';
            }
          }
        }
      },
    );

    if (regexPattern.trim() && 'epic' !== pmsName.toLowerCase()) {
      regexPattern = '^' + regexPattern.replace(/\\/g, '') + '$';
    }
  }

  // TODO: is there a single example that uses this? All tests pass without it
  regexPattern = regexPattern.replace(/\//g, '\\/');
  // Used to remove invalid regex patterns from epic barcode components
  regexPattern = regexPattern.replace(/(\(\?\i)\)|(\(\?\-\i)\)/g, '');

  const matches = barcode.match(regexPattern);

  if (!matches) {
    return parsedResult;
  }
  for (let i = 1; i < matches.length; i++) {
    const match = matches[i];
    if (match) {
      componentValues.push(
        delimiter.trim()
          ? match.replace(new RegExp('\\' + delimiter, 'g'), '')
          : match,
      );
    }
  }

  // Added this because scanned RX items were not matching
  // Epic barcode components (don't always have partials)
  if (
    pmsName.toLowerCase() === 'epic' &&
    componentNames.indexOf('partial') === -1
  ) {
    parsedResult.partial = '';
  }

  return componentNames.reduce((accumulator, name, index) => {
    const value =
      name && payorPlanNames.has(name) ? '' : componentValues[index];
    switch (name) {
      case 'rxnumber':
        accumulator.rx = value;
        return accumulator;
      case 'refillnumber':
      case 'fillid':
        accumulator.fill = value;
        return accumulator;
      case 'partial':
        accumulator.partial = value;
        return accumulator;
      case 'store':
      case 'ncpdpidnumber':
        accumulator.site = value;
        return accumulator;
      default:
        return accumulator;
    }
  }, parsedResult);
}

export interface IdBarcodeResult {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  gender?: string;
  dateOfBirth?: string;
  address1?: string;
  address2?: string;
  city?: string;
  zip?: string;
  expirationDate?: string;
  state?: string;
  idNumber?: string;
}

export function decodeID(
  barcodeComponents: CompleteBarcodeComponent[],
  barcode: string,
): IdBarcodeResult {
  if (!barcode.startsWith('$')) {
    return decodeUsDrivingLicense(barcode);
  }

  let prefix = '';

  barcodeComponents = orderBy(barcodeComponents, ['componentIndex'], ['asc']);

  const delimiterComponents = barcodeComponents.filter(
    barcodeComponent => barcodeComponent.componentIndex === 0,
  );

  const firstDelimiterComponent = delimiterComponents[0];

  if (!firstDelimiterComponent) {
    throw Error('Expecting delimiter BarcodeComponent for ID Scanning.');
  }
  if (!barcode.includes(firstDelimiterComponent.componentMask)) {
    throw Error(
      `Invalid ID barcode format: Expected to be ${firstDelimiterComponent.componentMask} seperated.`,
    );
  }

  const delimiter = firstDelimiterComponent.componentMask;

  barcodeComponents.forEach(barcodeComponent => {
    if (barcodeComponent.componentName.toLowerCase() === PREFIX) {
      prefix = barcodeComponent.componentMask;
    }
  });

  barcodeComponents = barcodeComponents.filter(
    barcodeComponent =>
      barcodeComponent.componentMask != firstDelimiterComponent.componentMask,
  );

  const barcodeSplit = barcode.split(delimiter);
  const components: Array<string> = [];

  barcodeSplit.forEach(component => {
    let componentsAdded = false;
    delimiterComponents.forEach(delimiterComponent => {
      const tmpDelimiter = delimiterComponent.componentMask;
      const tmpComponents = component.split(tmpDelimiter);
      if (tmpComponents.length > 1) {
        componentsAdded = true;
        tmpComponents.forEach(tempComponent => {
          components.push(tempComponent);
          components.push(tmpDelimiter);
        });
        components.pop();
      }
    });
    if (!componentsAdded) {
      components.push(component);
    }
  });

  const leftBracket = prefix.indexOf('[') + 1;
  const rightBracket = prefix.indexOf(']');
  prefix = prefix.substring(leftBracket, rightBracket);
  if (prefix === '\\\\') {
    prefix = '\\';
  }

  const finalComponents: Array<string> = [];
  finalComponents.push('');
  if (barcode.toLowerCase().indexOf(prefix.toLowerCase()) > -1) {
    finalComponents.push(prefix);
  } else {
    finalComponents.push('');
  }

  components.forEach(component => {
    if (component.toLowerCase().indexOf(prefix.toLowerCase()) > -1) {
      const index = component.toLowerCase().indexOf(prefix.toLowerCase());
      let copyComponent = component;
      if (index > 0) {
        copyComponent = component.substring(index);
      }
      const componentValue = copyComponent
        .toUpperCase()
        .replace(prefix.toUpperCase(), '');
      if (
        !barcodeComponents.some(
          barcodeComponent =>
            barcodeComponent.barcodeName ===
            IDENTIFICATION_BARCODE_COMPONENT_NAME,
        )
      )
        finalComponents.push(componentValue);
    } else {
      finalComponents.push(component);
    }
  });

  return barcodeComponents
    .filter(barcodeComponent => barcodeComponent.componentIndex > 0)
    .reduce((accumulator, barcodeComponent) => {
      switch (barcodeComponent.componentName) {
        case 'Address1':
          accumulator.address1 =
            finalComponents[barcodeComponent.componentIndex];
          return accumulator;
        case 'Address2':
          accumulator.address2 =
            finalComponents[barcodeComponent.componentIndex];
          return accumulator;
        case 'City':
          accumulator.city = finalComponents[barcodeComponent.componentIndex];
          return accumulator;
        case 'State':
          accumulator.state = finalComponents[barcodeComponent.componentIndex];
          return accumulator;
        case 'Gender':
          accumulator.gender = finalComponents[barcodeComponent.componentIndex];
          return accumulator;
        case 'ExpirationDate':
          accumulator.expirationDate =
            finalComponents[barcodeComponent.componentIndex];
          return accumulator;
        case 'Birthdate':
          accumulator.dateOfBirth =
            finalComponents[barcodeComponent.componentIndex];
          return accumulator;
        case 'Zipcode':
          accumulator.zip = finalComponents[barcodeComponent.componentIndex];
          return accumulator;
        case 'FirstName':
          accumulator.firstName =
            finalComponents[barcodeComponent.componentIndex];
          return accumulator;
        case 'MiddleName':
          accumulator.middleName =
            finalComponents[barcodeComponent.componentIndex];
          return accumulator;
        case 'LastName':
          accumulator.lastName =
            finalComponents[barcodeComponent.componentIndex];
          return accumulator;
        case 'DLnumber':
          accumulator.idNumber =
            finalComponents[barcodeComponent.componentIndex];
          return accumulator;
        default:
          return accumulator;
      }
    }, {} as IdBarcodeResult);
}

const decodeUsDrivingLicense = (barcode: string): IdBarcodeResult => {
  const result: IdBarcodeResult = {};
  const lines = barcode.split('\n');
  lines.forEach(line => {
    const code = line.slice(0, 3);
    const value = line.slice(3);

    const formatDate = (): string =>
      `${value.slice(0, 2)}/${value.slice(2, 4)}/${value.slice(4)}`;

    switch (code) {
      case '\u001e\rA':
        result.idNumber = line.slice(line.indexOf('DAQ') + 3);
        return;
      case 'DAC':
        result.firstName = value;
        return;
      case 'DAD':
        result.middleName = value;
        return;
      case 'DCS':
        result.lastName = value;
        return;
      case 'DBC':
        result.gender = value === '1' ? 'M' : 'F';
        return;
      case 'DBB':
        result.dateOfBirth = formatDate();
        return;
      case 'DAG':
        result.address1 = value;
        return;
      case 'DAH':
        result.address2 = value;
        return;
      case 'DAI':
        result.city = value;
        return;
      case 'DAK':
        result.zip = value.trim();
        return;
      case 'DBA':
        result.expirationDate = formatDate();
        return;
      case 'DAJ':
        result.state = value;
        return;
      default:
        return;
    }
  });

  return result;
};

import {
  Customer,
  CustomerAccount,
  CustomerResponseApiResponse,
  EligibilitySearchMatch,
  EligibilitySearchMatchListApiResponse,
  InvoicePayment,
  PaymentOptionResult,
  PaymentType,
  Setting,
  SignatureType,
} from '@emporos/api-enterprise';
import {
  Button,
  ButtonShape,
  ButtonSize as Size,
  FooterGroup,
  Gutter,
  Header,
  Illustration,
  Modal,
  Row,
  SmallSidebarWrapper,
  Stack,
  Text,
  TextVariant as TV,
  Variant as BV,
} from '@emporos/components';
import {navigate} from '@reach/router';
import assert from 'assert';
import {
  createRef,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import {
  AcceptedPaymentTypes,
  AlertLogTypes,
  AnalyticType,
  ARPayment,
  CashPayment,
  CheckPayment,
  CreditCard,
  getPaymentTypeId,
  Invoice,
  InvoiceUpdates,
  mapComplianceIndicators,
  mapIdCheckIndicators,
  mapInvoicePayment,
  Paid,
  PaymentInfoData,
  PaymentOptions,
  PaymentProvider,
  PaymentRef,
  PD as PDPayment,
  TotalsResult,
  UDPayment,
  useAnalyticsProvider,
  useCardReaderCredentials,
  useInvoice,
  useLog,
} from '../../../../../';

const RightSidebar = styled(SmallSidebarWrapper)`
  padding: 0 20px 20px;
  @media (max-width: 1034px) {
    flex: 1;
    padding: 0 12px 20px 12px;
  }
`;

function formatMoney(money: number) {
  return money.toFixed(2);
}

function mapPaymentOptions(paymentOptions: PaymentOptionResult[]): Set<string> {
  return new Set(paymentOptions.map(o => o.displayName));
}

export function mapUDP(
  paymentTenders: PaymentType[],
  paymentOptions: PaymentOptionResult[],
): PaymentType[] {
  return paymentOptions
    .filter(o => o.displayName.startsWith('User'))
    .map(o => paymentTenders.find(t => t.name === o.displayName))
    .filter(Boolean) as PaymentType[];
}

interface Props {
  customer: Customer | null;
  showCreditCardsOnFile: boolean;
  showVantiv?: boolean;
  showHostedPayment?: boolean;
  creditCardPendingPaymentExpirationMinutes: number;
  isOnline: boolean;
  navigateTo: typeof navigate;
  credentials: ReturnType<typeof useCardReaderCredentials>['credentials'];
  invoice: Invoice;
  processedPayments: InvoicePayment[];
  totals: TotalsResult;
  settings: Setting[];
  paymentTenders: PaymentType[];
  paymentOptions: PaymentOptionResult[];
  updateInvoice: (updates: InvoiceUpdates) => void;
  setSelectedPayment: Dispatch<SetStateAction<string>>;
  onSearchEligibility: (
    query: string,
  ) => Promise<EligibilitySearchMatchListApiResponse>;
  onGetCustomer: () => void;
  onGetEmployee: (
    customer: EligibilitySearchMatch,
  ) => Promise<CustomerResponseApiResponse>;
  onCreateAR: () => Promise<CustomerAccount | undefined>;
  signatureTypes: Array<SignatureType>;
  paymentProvider: PaymentProvider;
}

export function CustomerPayment({
  customer,
  showCreditCardsOnFile,
  showVantiv,
  showHostedPayment,
  creditCardPendingPaymentExpirationMinutes,
  isOnline,
  navigateTo,
  credentials,
  invoice,
  processedPayments,
  totals,
  settings,
  paymentTenders,
  paymentOptions,
  updateInvoice,
  onSearchEligibility,
  onGetCustomer,
  onGetEmployee,
  onCreateAR,
  setSelectedPayment,
  signatureTypes,
  paymentProvider,
}: Props): JSX.Element {
  const {track} = useAnalyticsProvider();
  const {logAlert} = useLog();
  const {pendingPayments} = useInvoice();
  const [amount, setAmount] = useState(0);
  const [awaitingContinue, setAwaitingContinue] = useState(false);
  const [canPayWithPD, setCanPayWithPD] = useState(false);
  const [canPayWithAR, setCanPayWithAR] = useState(false);
  const [canPayWithUDP, setCanPayWithUDP] = useState(false);
  const [canPayWithCreditCard, setCanPayWithCreditCard] = useState(false);
  const [changeDueOpen, setChangeDueOpen] = useState(false);
  const [paymentNumber, setPaymentNumber] = useState<string | null>(null);
  const [paymentTypeId, setPaymentTypeId] = useState<number>(0);
  const [paymentTypeDesc, setPaymentTypeDesc] = useState<string>('');
  const [paymentType, setPaymentType] = useState<AcceptedPaymentTypes>(
    '<unknown>',
  );
  const paymentRef = createRef<PaymentRef>();
  const {totalDue, qhpAmount} = totals;
  const showingPaidView = totalDue === 0 && !awaitingContinue;

  const isConfirmPaymentDisabled =
    amount === 0 ||
    !paymentTypeId ||
    (paymentType !== 'Cash' && amount > totalDue) ||
    (paymentType === 'Check' && !paymentNumber) ||
    (paymentType === 'PD' && !canPayWithPD) ||
    (paymentType === 'UDP' && !canPayWithUDP) ||
    (paymentType === 'Credit Card' && !canPayWithCreditCard) ||
    (paymentType === 'AR' && !canPayWithAR);

  useEffect(() => {
    // whenever the payment type changes, we refresh this to exact...
    // this could be implemented by moving amount management to each payment
    // option
    setAmount(paymentType === 'Cash' ? 0 : totals.totalDue);
    if (paymentType !== 'UDP') {
      setPaymentNumber('');
    }

    if (paymentType !== 'Credit Card' && awaitingContinue) {
      setAwaitingContinue(false);
    }
  }, [paymentType, processedPayments.length]);

  const getButtonText = (signatures: SignatureType[]) =>
    signatures.length ? 'Customer Signature' : 'Receipt Delivery';

  const _setPaymentType = useCallback(
    (type: AcceptedPaymentTypes) => {
      setPaymentType(type);
      setPaymentTypeId(getPaymentTypeId(type, paymentTenders) || 0);
    },
    [paymentType, paymentTenders],
  );

  function addPaymentWithoutSideEffects(paymentInfo?: PaymentInfoData) {
    const paymentAmount = paymentInfo?.amountApproved || amount;

    const newPayment = mapInvoicePayment(
      invoice.invoiceId,
      paymentInfo?.paymentTypeId || paymentTypeId,
      paymentInfo?.paymentTypeDesc || paymentTypeDesc,
      paymentAmount,
      {
        ...(amount > totalDue && {
          amountReturned: amount - totalDue,
          paymentTypeDesc: paymentInfo?.paymentTypeDesc || paymentTypeDesc,
        }),
        paymentNumber: paymentInfo?.paymentId || paymentNumber || '',
        gatewayPaymentID: paymentInfo?.epsReference,
        recordStatus: paymentInfo?.recordStatus || 'Active',
        jsonPaymentProcessorResponse: paymentInfo?.jsonPaymentProcessorResponse,
      },
    );
    newPayment.paymentTypeDesc =
      paymentInfo?.paymentTypeDesc || paymentTypeDesc;

    updateInvoice(prevInvoice => ({
      payments: [...prevInvoice.payments, newPayment],
    }));
  }

  function addPayment() {
    addPaymentWithoutSideEffects();
    setChangeDueOpen(false);
  }

  async function onConfirmPayment() {
    // Send info to segment about payment type and amount
    track(AnalyticType.AddPaymentType, {
      total: amount,
      revenueType: paymentType,
    });
    switch (paymentType) {
      case 'Credit Card': {
        // flipping this off is the responsibility of the <CreditCard />
        // experience which has confirmation modals for every action
        setAwaitingContinue(true);
        assert(
          paymentRef.current,
          'Attempting to make a card transaction without the Card UI open',
        );
        await paymentRef.current.onConfirmPayment();
        break;
      }
      case 'AR':
      case 'PD': {
        assert(
          paymentRef.current,
          'Attempting to make a AR transaction without the AR UI open',
        );
        const paymentInfo = await paymentRef.current.onConfirmPayment();
        if (paymentInfo) {
          addPaymentWithoutSideEffects(paymentInfo);
        }
        break;
      }
      // Things we are pretty sure are fine. JK, change can only be given for
      // Cash
      case 'Cash':
        if (amount > totalDue) {
          setChangeDueOpen(true);
          logAlert(AlertLogTypes.ChangeDue);
        } else {
          addPayment();
        }
        break;
      case 'Check':
      case 'UDP':
        addPayment();
        break;
      default:
        console.warn('Should we do special handling for ', paymentType);
    }
  }

  return (
    <>
      <Stack gutter={Gutter.L}>
        <Header title="Customer Payment">
          <ButtonShape
            disabled={!!pendingPayments.length}
            size={Size.Small}
            icon="User"
            data-testid="customer-info-btn"
            onClick={() => navigateTo('customer-info')}
          />
        </Header>

        <Row gutter={Gutter.XXL} style={{height: '100%', minHeight: 0}} noWrap>
          <Stack style={{flex: 2}}>
            {(showingPaidView && <Paid />) ||
              (paymentType === 'Cash' && (
                <CashPayment
                  amount={amount}
                  setAmount={setAmount}
                  totalDue={totalDue}
                />
              )) ||
              (paymentType === 'Check' && (
                <CheckPayment
                  amount={amount}
                  setAmount={setAmount}
                  paymentNumber={paymentNumber || ''}
                  setPaymentNumber={setPaymentNumber}
                  totalDue={totalDue}
                />
              )) ||
              (paymentType === 'Credit Card' && (
                <CreditCard
                  onChangeActivePayment={() => {
                    assert(paymentRef.current);
                    setCanPayWithCreditCard(
                      paymentRef.current.isAcceptablePayment(),
                    );
                  }}
                  cardsOnFile={
                    (showCreditCardsOnFile && customer?.creditCards) || []
                  }
                  showVantiv={showVantiv}
                  showHostedPayment={showHostedPayment}
                  creditCardPendingPaymentExpirationMinutes={
                    creditCardPendingPaymentExpirationMinutes
                  }
                  amount={amount}
                  setAmount={setAmount}
                  totalDue={totalDue}
                  qhpAmount={qhpAmount}
                  // TODO: verify what tax amount we should send through. Is
                  // this additive to the totalDue or subtractive or ignored?
                  deviceCredentials={credentials}
                  onContinue={() => setAwaitingContinue(false)}
                  navigate={navigateTo}
                  paymentTenders={paymentTenders}
                  ref={paymentRef}
                  paymentProvider={paymentProvider}
                  invoice={invoice}
                  updateInvoice={updateInvoice}
                  setSelectedPayment={setSelectedPayment}
                  cardHolderName={`${customer?.lastName}, ${customer?.firstName}`}
                />
              )) ||
              (paymentType === 'AR' && customer && (
                <ARPayment
                  onChangeActivePayment={() => {
                    assert(paymentRef.current);
                    setCanPayWithAR(paymentRef.current.isAcceptablePayment());
                  }}
                  customer={customer}
                  amount={amount}
                  setAmount={setAmount}
                  totalDue={totalDue}
                  onChangeCustomer={onGetCustomer}
                  onCreateAR={onCreateAR}
                  arCreditLimit={Number(
                    settings.find(({name}) => name === 'ARCreditLimit')
                      ?.value || 0,
                  )}
                  ref={paymentRef}
                />
              )) ||
              (paymentType === 'PD' && (
                <PDPayment
                  onChangeActivePayment={() => {
                    assert(paymentRef.current);
                    setCanPayWithPD(paymentRef.current.isAcceptablePayment());
                  }}
                  amount={amount}
                  setAmount={setAmount}
                  totalDue={totalDue}
                  onGetEmployee={onGetEmployee}
                  onSearchEligibility={onSearchEligibility}
                  isOnline={isOnline}
                  paymentTenders={paymentTenders}
                  ref={paymentRef}
                />
              )) ||
              (paymentType === 'UDP' && (
                <UDPayment
                  onChangeActivePayment={() => {
                    assert(paymentRef.current);
                    setCanPayWithUDP(paymentRef.current.isAcceptablePayment());
                  }}
                  udps={mapUDP(paymentTenders, paymentOptions)}
                  amount={amount}
                  setAmount={setAmount}
                  setPaymentTypeId={setPaymentTypeId}
                  setPaymentTypeDesc={setPaymentTypeDesc}
                  totalDue={totalDue}
                  ref={paymentRef}
                />
              )) || (
                <Stack
                  justify="center"
                  align="center"
                  gutter={Gutter.None}
                  style={{flex: 1}}
                >
                  <Illustration illustration="SelectPayment" />
                  <Text variant={TV.T2} align="center">
                    Please Select Payment Type
                  </Text>
                </Stack>
              )}

            {totalDue > 0 || awaitingContinue ? (
              <FooterGroup>
                <Button
                  type="button"
                  data-testid="back-button"
                  variant={BV.Secondary}
                  disabled={
                    !!processedPayments.length || !!pendingPayments.length
                  }
                  flex
                  onClick={() => {
                    const {
                      showCounsel,
                      showHipaa,
                      showRelationship,
                    } = mapComplianceIndicators(invoice, settings);
                    if (showCounsel || showHipaa || showRelationship) {
                      return navigateTo(
                        '/sales/transactions/payments/compliance',
                      );
                    }

                    const {
                      isNplex,
                      isControlledSubstance,
                      isRequiredAge,
                    } = mapIdCheckIndicators(invoice);
                    if (
                      !invoice.identification &&
                      ((isNplex && invoice.pseCheckResult !== 1) ||
                        isControlledSubstance ||
                        isRequiredAge)
                    ) {
                      return navigateTo(
                        '/sales/transactions/payments/id-check',
                      );
                    }

                    // time for animation to complete, then navigate
                    setTimeout(() => navigateTo('/sales/transactions'), 400);
                  }}
                >
                  Back
                </Button>
                <Button
                  flex
                  disabled={
                    isConfirmPaymentDisabled || !!pendingPayments.length
                  }
                  onClick={onConfirmPayment}
                >
                  Confirm Payment
                </Button>
              </FooterGroup>
            ) : (
              <FooterGroup>
                <Button
                  flex
                  onClick={() =>
                    navigateTo(
                      signatureTypes.length
                        ? '../acknowledgements'
                        : '../receipts',
                    )
                  }
                >
                  {getButtonText(signatureTypes)}
                </Button>
              </FooterGroup>
            )}
          </Stack>

          <RightSidebar data-testid="Sidebar">
            <PaymentOptions
              paymentOptions={mapPaymentOptions(paymentOptions)}
              customerPaymentsAvailable={!!customer}
              disabled={showingPaidView || !!pendingPayments.length}
              activePaymentType={paymentType}
              setPaymentType={_setPaymentType}
              isOnline={isOnline}
            />
          </RightSidebar>
        </Row>
      </Stack>

      <Modal
        data-testid="Modal__ChangeDue"
        visible={changeDueOpen}
        icon="Cash"
        color="success"
        onContinue={addPayment}
        buttonText="Okay"
        title={`Change Due: $${formatMoney(amount - totalDue)}`}
        subtitle="Please provide change to the customer. Amount can be viewed again in the payment details."
      />
    </>
  );
}

import {CayanConfig, Processor, VantivConfig} from '@emporos/card-payments';
import React, {createContext, useState} from 'react';
import {useAuthentication, useSettings, useTransactionsState} from '../';

const {APP_NAME, APP_VERSION} = process.env;

export type Credentials =
  | {
      processor: Processor.Cayan;
      config: CayanConfig;
    }
  | {
      processor: Processor.Vantiv;
      config: VantivConfig;
    };

export const defaultCayanCredentials: CayanConfig = {
  gatewayUsername: '',
  gatewayId: '',
  gatewayPassword: '',
  gatewayTerminal: '',
  processorLaneId: '9999',
  useSecureProtocol: true,
  accessToken: '',
  appName: APP_NAME || '',
  appVersion: APP_VERSION || '',
  siteId: '',
  fetchTimeoutSeconds: 0,
};

export const defaultVantivCredentials: VantivConfig = {
  gatewayUsername: '1055121',
  gatewayId: '3928907',
  gatewayPassword:
    'B45B9FAE81AF8E6D02635D59F55108E114A6106CDB186CA25D1194BD67035DB051950101',
  gatewayTerminal: '0001', // gatewayTerminalId
  partnerId: '5238',
  processorLaneId: 9999,

  appName: APP_NAME || '',
  appVersion: APP_VERSION || '',
  siteId: '',
};

export const defaultCredentials: Credentials = {
  processor: Processor.Vantiv,
  config: defaultVantivCredentials,
};

interface TContext {
  credentials: Credentials;
  setCredentials(creds: Credentials): void;
}
const Context = createContext<TContext>({
  credentials: defaultCredentials,
  setCredentials: (_: Credentials) => {
    throw new Error('You must provide a CardReaderCredentialsProvider ');
  },
});
Context.displayName = 'CardReaderCredentialsContext';

interface Props {
  children: React.ReactElement;
}
export function CardReaderCredentialsProvider({children}: Props): JSX.Element {
  const {user} = useAuthentication();
  const [credentials, setCredentials] = useState({
    processor: Processor.Cayan,
    config: defaultCayanCredentials,
  });
  const {session} = useTransactionsState();
  const {creditCardDeviceRequestTimeoutSeconds} = useSettings();
  const {siteId} = session;
  const finalCredentials =
    credentials.processor === Processor.Vantiv
      ? credentials
      : {
          processor: Processor.Cayan,
          config: {
            ...credentials.config,
            siteId: String(siteId),
            gatewayTerminal:
              session?.paymentDeviceAddress ||
              defaultCayanCredentials.gatewayTerminal,
            accessToken: user
              ? user.access_token
              : credentials.config.accessToken,
            fetchTimeoutSeconds: creditCardDeviceRequestTimeoutSeconds,
          },
        };
  return (
    // eslint-disable-next-line
    // @ts-ignore
    <Context.Provider value={{credentials: finalCredentials, setCredentials}}>
      {children}
    </Context.Provider>
  );
}
export const useCardReaderCredentials = (): TContext =>
  React.useContext(Context);

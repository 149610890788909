import {PropsWithChildren} from 'react';
import {CustomerSearchProvider, WithPageProps} from '../../../';
import {CustomerInfoIntegration, CustomerInfoIntegrationWillCall} from '../';

interface WillCallProps {
  canAddCustomer?: boolean;
  disableWillCall?: boolean;
}

export function CustomerInfoWillCall(
  props: PropsWithChildren<WithPageProps<WillCallProps>>,
): JSX.Element {
  return (
    <CustomerSearchProvider>
      <CustomerInfoIntegrationWillCall {...props} />
    </CustomerSearchProvider>
  );
}

export {CustomerInfoIntegration as CustomerInfo};

import {RouteComponentProps} from '@reach/router';
import assert from 'assert';
import {memo} from 'react';
import {
  AuthClaim,
  useAlertState,
  useAuthentication,
  useGlobalData,
  useInvoice,
  useTransactionsState,
  withChildPage,
} from '../../../../../';
import {useApi} from '../../../../../contexts/ApiProvider';
import {IdCheck} from './';

export const IdCheckIntegration = memo(
  withChildPage(
    ({
      navigate,
    }: RouteComponentProps<{
      location: {
        state: {
          animatePanel?: boolean;
        };
      };
    }>) => {
      const api = useApi();
      const {barcodesResult, settingsResult} = useGlobalData();
      const {notification} = useAlertState();
      const {
        session: {siteId, stationId},
      } = useTransactionsState();
      const {user} = useAuthentication();
      const userId = user?.profile[AuthClaim.UserId] || '';
      const {loading: loadingPseCheck, run: putPseCheck} = api.PutPSECheck();
      const {NODE_ENV} = process.env;

      const {invoice, updateInvoice} = useInvoice();

      assert(
        navigate,
        '<IdCheck /> must have a `navigate` prop.' + String(NODE_ENV) ===
          'production'
          ? ''
          : ' This likely means that you need to have it as a direct child of a <Router />',
      );
      assert(invoice, 'Missing invoice');
      assert(settingsResult && settingsResult.data, 'Missing settings');
      assert(barcodesResult && barcodesResult.data, 'Missing barcodes');

      return (
        <IdCheck
          onUpdateInvoice={updateInvoice}
          invoice={invoice}
          barcodes={barcodesResult.data}
          onBack={() => {
            navigate('/sales/transactions');
          }}
          loadingPseCheck={loadingPseCheck}
          putPseCheck={putPseCheck}
          navigate={navigate}
          settings={settingsResult.data}
          siteID={String(siteId)}
          stationID={String(stationId)}
          userID={userId}
          notification={notification}
        />
      );
    },
  ),
);

import {SignatureType} from '@emporos/api-enterprise';
import assert from 'assert';
import {getPaymentType, useGlobalData, useInvoice} from '../';

export const useSignatures = (): Array<SignatureType> => {
  const {invoice} = useInvoice();
  const {items, payments} = invoice;
  const {paymentTendersResult, signatureTypesResult} = useGlobalData();
  assert(
    paymentTendersResult && paymentTendersResult.data,
    'Missing payment tenders',
  );
  assert(signatureTypesResult, 'Missing signatures types');

  const hasNplex = () =>
    items.some(({itemTypeID, rx}) => !rx && itemTypeID === 16);

  const hasRx = () => items.some(({rx}) => rx);

  const hasSignatureRequired = () =>
    items.some(({signatureIndicator}) => signatureIndicator);

  const hasCreditCardPayment = () =>
    payments.some(
      p =>
        getPaymentType(
          p.paymentTypeID || 0,
          paymentTendersResult.data || [],
        ) === 'Credit Card',
    );

  const hasARPayment = () =>
    payments.some(p => {
      const paymentType = getPaymentType(
        p.paymentTypeID || 0,
        paymentTendersResult.data || [],
      );
      return paymentType === 'AR' || paymentType === 'PD';
    });

  const signatures: Array<SignatureType> = [];
  if (hasNplex()) {
    const nplex = signatureTypesResult.find(
      type => type.description === 'PSE/NPLEX',
    );
    nplex && signatures.push(nplex);
  }
  if (hasRx()) {
    const rx = signatureTypesResult.find(
      type => type.description === 'Rx/Prescription',
    );
    rx && signatures.push(rx);
  }
  if (hasARPayment()) {
    const ar = signatureTypesResult.find(
      type => type.description === 'Accounts Receivable',
    );
    ar && signatures.push(ar);
  }
  if (hasCreditCardPayment()) {
    const cc = signatureTypesResult.find(
      type => type.description === 'Credit Card',
    );
    cc && signatures.push(cc);
  }
  if (hasSignatureRequired()) {
    const signatureRequired = signatureTypesResult.find(
      type => type.description === 'Item Restrictions',
    );
    signatureRequired && signatures.push(signatureRequired);
  }
  return signatures;
};

/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InvoiceTax
 */
export interface InvoiceTax {
    /**
     * 
     * @type {string}
     * @memberof InvoiceTax
     */
    invoiceTaxId: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceTax
     */
    invoiceId: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceTax
     */
    taxID: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceTax
     */
    transactionID?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InvoiceTax
     */
    taxAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InvoiceTax
     */
    taxRateID: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceTax
     */
    taxRate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InvoiceTax
     */
    taxableSubTotal?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InvoiceTax
     */
    taxGroupID?: number | null;
}

export function InvoiceTaxFromJSON(json: any): InvoiceTax {
    return InvoiceTaxFromJSONTyped(json, false);
}

export function InvoiceTaxFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceTax {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'invoiceTaxId': json['invoiceTaxId'],
        'invoiceId': json['invoiceId'],
        'taxID': json['taxID'],
        'transactionID': !exists(json, 'transactionID') ? undefined : json['transactionID'],
        'taxAmount': !exists(json, 'taxAmount') ? undefined : json['taxAmount'],
        'taxRateID': json['taxRateID'],
        'taxRate': !exists(json, 'taxRate') ? undefined : json['taxRate'],
        'taxableSubTotal': !exists(json, 'taxableSubTotal') ? undefined : json['taxableSubTotal'],
        'taxGroupID': !exists(json, 'taxGroupID') ? undefined : json['taxGroupID'],
    };
}

export function InvoiceTaxToJSON(value?: InvoiceTax | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'invoiceTaxId': value.invoiceTaxId,
        'invoiceId': value.invoiceId,
        'taxID': value.taxID,
        'transactionID': value.transactionID,
        'taxAmount': value.taxAmount,
        'taxRateID': value.taxRateID,
        'taxRate': value.taxRate,
        'taxableSubTotal': value.taxableSubTotal,
        'taxGroupID': value.taxGroupID,
    };
}



import {CredentialTypeEnum, InvoicePayment} from '@emporos/api-enterprise';
import {
  Button,
  FooterGroup,
  Gutter,
  Header,
  Modal,
  Row,
  ScrollContainer,
  Stack,
  TextInput,
  Variant,
} from '@emporos/components';
import {NavigateFn, RouteComponentProps} from '@reach/router';
import assert from 'assert';
import {memo, useCallback, useEffect, useState} from 'react';
// import {setInterval} from 'timers';
import {
  FISHostedPayment,
  getPaymentTypeIdByDescriptor,
  mapInvoicePayment,
  OfflineSynced,
  useGlobalData,
  useInvoice,
  useScript,
  withChildPage,
} from '../../../../../';
import {useApi} from '../../../../../contexts/ApiProvider';

export enum PaymentStatus {
  Loading,
  Success,
  Partial,
  Declined,
  Error,
}

const HostedPaymentIntegrationComponent = withChildPage(
  ({
    navigate,
    location,
  }: RouteComponentProps<{
    location: {
      state: {
        amount: number;
      };
    };
  }>): JSX.Element => {
    const api = useApi();
    const [validationErrors, setValidationErrors] = useState<
      Array<ValidationError>
    >([]);
    const [paymentStatus, setPaymentStatus] = useState<PaymentStatus | null>(
      null,
    );
    const {settingsResult, paymentTendersResult} = useGlobalData();
    const {
      invoice: {sessionId, invoiceId, qhpAmount},
      updateInvoice,
    } = useInvoice();
    const {
      error: postPaymentError,
      status: postPaymentStatus,
      data: postPaymentResult,
      run: postPayment,
    } = api.PostInvoicePayment();

    const settings = settingsResult?.data;
    const paymentTenders = paymentTendersResult?.data;
    assert(navigate, 'Missing navigate');
    assert(location, 'Missing location');
    assert(settings, 'Missing settingsResult');
    assert(paymentTenders, 'Missing paymentTendersResult');

    const paymentPortalType = settings.find(
      ({name}) => name === 'PAYMENT_GATEWAY_INTERFACE',
    )?.value;
    const transactionID = '2EC1A41C-B3FE-4D02-8CF6-2E8CDBCD35E3';

    // Uncomment when API integration can be done.
    // let checkStatus: ReturnType<typeof setInterval>;
    // const clearTimeout = () => {
    //   clearInterval(checkStatus);
    //   checkStatus = null;
    // };

    const webApiKey = settings.find(
      ({name}) => name === 'GPIGeniusCheckoutWebApiKey',
    )?.value;
    // inject external script in react
    useScript(
      'https://ecommerce.merchantware.net/v1/CayanCheckoutPlus.js',
      () => {
        if (webApiKey && paymentPortalType !== 'element') {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          CayanCheckoutPlus.setWebApiKey(webApiKey);
        }
      },
    );

    useEffect(() => {
      if (paymentPortalType !== 'element') {
        if (postPaymentError) {
          return setPaymentStatus(PaymentStatus.Error);
        }
        if (!postPaymentResult) {
          return;
        }
        switch (postPaymentStatus) {
          case undefined:
            return;
          case 201:
            updateInvoice(prevInvoice => ({
              ...prevInvoice,
              payments: prevInvoice.payments.concat({
                ...postPaymentResult,
                isSynced: true,
              } as OfflineSynced & InvoicePayment),
            }));
            return setPaymentStatus(PaymentStatus.Success);
          case 461:
            return setPaymentStatus(PaymentStatus.Declined);
          default:
            return setPaymentStatus(PaymentStatus.Error);
        }
      }
    }, [
      paymentPortalType,
      postPaymentStatus,
      postPaymentError,
      postPaymentResult,
    ]);

    const successCallback = async (tokenResponse: {token: string}) =>
      postPayment({
        sessionId,
        invoiceId,
        invoicePaymentRequest: mapInvoicePayment(
          invoiceId,
          getPaymentTypeIdByDescriptor('UnknownCard', paymentTenders) || 0,
          '',
          location.state.amount,
          {
            ccofPaymentInfoID: null,
            originalCCProcessorCredentialType: CredentialTypeEnum.NUMBER_0,
            cardToken: tokenResponse.token,
            qhpRxAmount:
              qhpAmount > location.state.amount
                ? location.state.amount
                : qhpAmount,
          },
        ),
      }).catch(console.error);

    const failureCallback = (errors: Array<ValidationError>) => {
      setPaymentStatus(null);
      setValidationErrors(errors);
    };

    const onSubmit = useCallback(() => {
      setPaymentStatus(PaymentStatus.Loading);
      setValidationErrors([]);
      // validates the form
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      CayanCheckoutPlus.createPaymentToken({
        success: successCallback,
        error: failureCallback,
      });
    }, []);

    const resetStatus = useCallback(() => {
      setPaymentStatus(null);
    }, []);

    const onFISSubmit = useCallback(() => {
      // TODO update with endpoint to poll for payment results.
      // if (!checkStatus) {
      //   checkStatus = setInterval(() => {
      //     // payment check API call here
      //   }, 3000);
      // }
    }, []);

    return paymentPortalType === 'element' ? (
      <FISHostedPayment
        getPaymentResult={onFISSubmit}
        transactionId={transactionID}
      />
    ) : (
      <HostedPayment
        amount={location.state.amount}
        amountApproved={location.state.amount}
        resetStatus={resetStatus}
        errors={validationErrors}
        status={paymentStatus}
        onSubmit={onSubmit}
        navigate={navigate}
      />
    );
  },
);

export interface ValidationError {
  reason: string;
}

interface Props {
  amount: number;
  amountApproved: number;
  errors: Array<ValidationError>;
  status: PaymentStatus | null;
  onSubmit: () => void;
  resetStatus: () => void;
  navigate: NavigateFn;
}

export const HostedPayment = ({
  amount,
  amountApproved,
  errors,
  status,
  onSubmit,
  resetStatus,
  navigate,
}: Props): JSX.Element => {
  const [cardNumber, setCardNumber] = useState('');
  const [cvv, setCvv] = useState('');
  const [expiration, setExpiration] = useState('');
  const [expirationMonth, setExpirationMonth] = useState('');
  const [expirationYear, setExpirationYear] = useState('');

  const onContinue = () => navigate('../');

  const reset = () => {
    resetStatus();
    setCardNumber('');
    setCvv('');
    setExpiration('');
    setExpirationMonth('');
    setExpirationYear('');
  };

  return (
    <ScrollContainer>
      <Stack justify="space-between" style={{height: '100%'}}>
        <Stack gutter={Gutter.L}>
          <Header title={`Payment Portal: $${amount.toFixed(2)}`} />
          <Row style={{flex: 1, display: 'flex'}}>
            <TextInput
              label="Card number"
              value={cardNumber}
              onChange={e => setCardNumber(e.target.value)}
              data-cayan="cardnumber"
              style={{flex: 5}}
              isError={errors.some(({reason}) => reason === 'cardnumber')}
            />
            <TextInput
              label="CVV"
              value={cvv}
              onChange={e => setCvv(e.target.value)}
              data-cayan="cvv"
              style={{flex: 2}}
              isError={errors.some(({reason}) => reason === 'cvv')}
            />
            <TextInput
              label="Expiration"
              value={expiration}
              onChange={e => {
                setExpiration(e.target.value);
                const expirationDate = e.target.value.split('/');
                setExpirationMonth(expirationDate[0]);
                setExpirationYear(expirationDate[1]);
              }}
              mask="99/99"
              inputMode="numeric"
              style={{flex: 3}}
              isError={errors.some(({reason}) =>
                [
                  'expirationmonth',
                  'expirationyear',
                  'expirationdate',
                ].includes(reason),
              )}
            />
            <input
              type="hidden"
              value={expirationMonth}
              data-cayan="expirationmonth"
            />
            <input
              type="hidden"
              value={expirationYear}
              data-cayan="expirationyear"
            />
          </Row>
        </Stack>
        <FooterGroup>
          <Button
            style={{flex: 1}}
            onClick={() => navigate('../')}
            variant={Variant.Danger}
          >
            Cancel
          </Button>
          <Button
            style={{flex: 1}}
            onClick={onSubmit}
            loading={status === PaymentStatus.Loading}
          >
            Submit
          </Button>
        </FooterGroup>
      </Stack>
      <Modal
        data-testid="Modal__CheckPayment"
        visible={status === PaymentStatus.Loading}
        icon="CreditCard"
        color="primary"
        title="Checking Payment Status"
        subtitle="Establishing connection with the gateway to verify status of payment."
        iconSpinning={true}
      />
      <Modal
        data-testid="Modal__PaymentSuccess"
        visible={status === PaymentStatus.Success}
        icon="Checkmark"
        color="success"
        onContinue={onContinue}
        buttonText="Close"
        title="Payment Successful"
        subtitle="The card payment has been processed. Please remove card and give back to customer."
      />
      <Modal
        data-testid="Modal__PartialPaymentSuccess"
        visible={status === PaymentStatus.Partial}
        icon="Checkmark"
        color="success"
        onContinue={onContinue}
        buttonText="Close"
        title="Partial Payment Successful"
        subtitle={
          'A partial payment has been processed. $' +
          amountApproved +
          ' was successfully applied.'
        }
      />
      <Modal
        data-testid="Modal__PaymentError"
        visible={status === PaymentStatus.Error}
        icon="Warning"
        color="warning"
        onCancel={reset}
        onContinue={reset}
        buttonText="Retry"
        title="Payment Error"
        subtitle="Transaction could not be processed. Please use another card or try again."
      />
      <Modal
        data-testid="Modal__PaymentDeclined"
        visible={status === PaymentStatus.Declined}
        icon="Warning"
        color="warning"
        onContinue={reset}
        buttonText="Okay"
        title="Payment Declined"
        subtitle="Customer's card has been declined. Please use another card or try again."
      />
    </ScrollContainer>
  );
};

export const HostedPaymentIntegration = memo(HostedPaymentIntegrationComponent);

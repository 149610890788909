import {
  Button,
  Gutter,
  LogosHilo,
  Row,
  Stack,
  Text,
  TextVariant as TV,
} from '@emporos/components';
import {EmptyNavbar} from '@emporos/components-pos';
import {memo, useEffect} from 'react';
import styled from 'styled-components';
import {NavigationLogTypes, useAlertState, useLog} from '../../';

interface PreLoginProps {
  onRedirect: () => void | Promise<void>;
}

const PreLoginWrapper = styled.div`
  background: ${({theme}) => theme.colors.white};
  border: 1px solid ${({theme}) => theme.colors.gray_200};
  border-radius: 16px;
  height: auto;
  padding: 36px;
  width: 432px;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

function PreLoginComponent({onRedirect}: PreLoginProps) {
  const {logUserSelection} = useLog();
  const {reset} = useAlertState();

  useEffect(() => {
    reset();
  }, [location]);

  useEffect(() => {
    logUserSelection(NavigationLogTypes.UserNavigating, {
      url: location.pathname,
    });
  }, [location.pathname]);

  return (
    <>
      <EmptyNavbar />
      <PreLoginWrapper>
        <Stack justify="center" gutter={Gutter.XL}>
          <Row justify="center">
            <LogosHilo.HiloMarkColor height={80} />
          </Row>
          <Row justify="center">
            <Stack align="center" gutter={Gutter.XXS}>
              <Text align="center" variant={TV.T3}>
                Login Needed
              </Text>

              <Text align="center" variant={TV.MainLight}>
                Please login to continue. You will be redirected to verify your
                login credentials.
              </Text>
            </Stack>
          </Row>
          <Row justify="center" style={{marginTop: '36px'}}>
            <Button flex onClick={onRedirect}>
              Login with Emporos ID
            </Button>
          </Row>
        </Stack>
      </PreLoginWrapper>
    </>
  );
}

export const PreLogin = memo(PreLoginComponent);

import {Invoice, Setting} from '@emporos/api-enterprise';

export const COUNSELING_LINE1 = 'CounselingLine1';
export const HIPAA_LINE1 = 'HIPAALine1';
export const RELATIONSHIP = 'relationship';
export const HIPAA_ENABLED = 'HIPAAEnabled';
export const RX_RELATIONSHIP_PROMPT_ENABLED = 'RxRelationshipPromptEnabled';
export const HIPAA_RELATIONSHIP_PROMPT_ENABLED =
  'HIPAARelationshipPromptEnabled';

export const COUNSEL_LATER = 'counsel_later';
export const NO_COUNSELING = 'no_counseling';

export const YES_NUM = 0;
export const NO_NUM = 1;
export const YES = 'yes';
export const NO = 'no';

export interface ComplianceIndicators {
  showCounsel: boolean;
  showCounselRequired: boolean;
  showHipaa: boolean;
  showRelationship: boolean;
}

export const mapComplianceIndicators = (
  {items, customer}: Invoice,
  settings: Setting[],
): ComplianceIndicators => ({
  showCounsel:
    !!settings &&
    settings.some(({name, value}) => name === COUNSELING_LINE1 && value) &&
    items.some(({rx}) => !!rx),
  showCounselRequired: items.some(
    ({counselRequiredIndicator, rx}) => rx && counselRequiredIndicator,
  ),
  showHipaa:
    !!customer &&
    customer.showHIPAA &&
    items.some(({rx}) => rx) &&
    !!settings &&
    settings.some(({name, value}) => name === HIPAA_ENABLED && value === '1') &&
    settings.some(({name, value}) => name === HIPAA_LINE1 && value),
  showRelationship:
    !!settings &&
    (settings.some(
      ({name, value}) =>
        name === HIPAA_RELATIONSHIP_PROMPT_ENABLED && value === '1',
    ) ||
      settings.some(
        ({name, value}) =>
          name === RX_RELATIONSHIP_PROMPT_ENABLED && value === '1',
      )) &&
    items.some(({rx}) => rx),
});

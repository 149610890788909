import {
  InvoiceSignature,
  InvoiceSignatureRequest,
} from '@emporos/api-enterprise';

export function ConvertInvoiceSignaturesToRequests(
  sigs: InvoiceSignature[],
): InvoiceSignatureRequest[] {
  const requests: InvoiceSignatureRequest[] = [];
  sigs.forEach(sig => {
    requests.push(ConvertInvoiceSignatureToRequest(sig));
  });
  return requests;
}

export function ConvertInvoiceSignatureToRequest(
  sig: InvoiceSignature,
): InvoiceSignatureRequest {
  return {
    invoiceSignatureId: sig.invoiceSignatureId,
    touchless: sig.touchless,
    signatureImageId: sig.signatureImageId,
    signatureTypeId: sig.signatureTypeId,
  };
}

import {InvoiceStatusEnum} from '@emporos/api-enterprise';
import {Table} from 'dexie';
import {InvoiceConsolidate} from '../api';
import {HiloDb} from './dbcontext';
import {IInvoiceDatabaseAccess} from './IDatabaseAccess';

const devLogging = String(process.env.NODE_ENV).trim() === 'development';

// instantiate with DIFactory.getInvoiceDatabaseAccess
export class InvoiceLocaldb implements IInvoiceDatabaseAccess {
  db!: HiloDb;
  table!: Table<InvoiceConsolidate>;
  private _sessionId!: string;

  initialize = (sessionId: string): void => {
    this._sessionId = sessionId;
    this.db = new HiloDb(this._sessionId);
    this.table = this.db.InvoiceLocaldb;
  };

  async getAll(status?: InvoiceStatusEnum): Promise<InvoiceConsolidate[]> {
    if (status) {
      return this.table
        .where({sessionId: this._sessionId, status: status})
        .toArray();
    } else {
      devLogging
        ? console.log(
            '%cGet All Invoices Session ID:',
            'background-color:#FFAEC3; color: black',
            this._sessionId,
          )
        : null;
      return this.table.where({sessionId: this._sessionId}).toArray();
    }
  }

  async get(invoiceId: string): Promise<InvoiceConsolidate> {
    devLogging
      ? console.log(
          '%cGet invoice!',
          'background-color:#FFAEC3; color: black',
          invoiceId,
        )
      : null;

    const invoiceresult = await this.table.get(invoiceId);

    if (invoiceresult) {
      return invoiceresult;
    } else {
      return {} as InvoiceConsolidate;
    }
  }

  async add(invoice: InvoiceConsolidate): Promise<InvoiceConsolidate> {
    devLogging
      ? console.log(
          '%cAdd invoice!',
          'background-color:#FFAEC3; color: black',
          invoice,
        )
      : null;
    await this.table.add(invoice);
    return invoice;
  }

  async update(invoice: InvoiceConsolidate): Promise<InvoiceConsolidate> {
    devLogging
      ? console.log(
          '%cUpdate invoice!',
          'background-color:#FFAEC3; color: black',
          invoice,
        )
      : null;

    await this.table.update(invoice.invoiceId, invoice);

    return invoice;
  }

  async delete(invoiceId: string): Promise<void> {
    devLogging
      ? console.log(
          '%cDelete invoice!',
          'background-color:#FFAEC3; color: black',
          invoiceId,
        )
      : null;
    return this.table.delete(invoiceId);
  }
  async deleteAll(): Promise<void> {
    devLogging
      ? console.log('%cDeleteAll !', 'background-color:#FFAEC3; color: black')
      : null;
    return this.table.clear();
  }
}

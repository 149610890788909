import {
  BaseAPI,
  Configuration,
  FetchParams,
  RequestContext,
  ResponseContext,
} from '@emporos/api-enterprise';
import {invoke} from 'lodash';
import {useCallback, useEffect, useState} from 'react';
import {
  AnalyticType,
  useAlertState,
  useAnalyticsProvider,
  useAuthentication,
} from '../';
import {
  endpointsConfig,
  FetchApiParams,
  MethodKeyOf,
  MethodParametersOf,
  MethodReturnTypeOf,
  PromiseResolveType,
} from './';

const devLogging = String(process.env.NODE_ENV).trim() === 'development';

export type ClientConstructor<T> = {new (config: Configuration): T};

export type UseOpenApiHook<T extends BaseAPI, K extends MethodKeyOf<T>> = {
  run: (...params: MethodParametersOf<T, K>) => MethodReturnTypeOf<T, K>;
  loading: boolean;
  data?: PromiseResolveType<MethodReturnTypeOf<T, K>>;
  error?: string;
  status?: number;
};

export type ApiVersion = '1.0' | '1.1' | '1.2' | '1.3' | '1.5'; // needs updated when version increments

export const useOpenApi = <T extends BaseAPI, K extends MethodKeyOf<T>>(
  clientConstructor: ClientConstructor<T>,
  method: K,
  version: ApiVersion,
  initialParams?: MethodParametersOf<T, K>,
): UseOpenApiHook<T, K> => {
  const {user} = useAuthentication();
  const {notification} = useAlertState();
  const {track} = useAnalyticsProvider();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<
    PromiseResolveType<MethodReturnTypeOf<T, K>>
  >();
  const [error, setError] = useState<string>();
  const [status, setStatus] = useState<number>();

  const dependency =
    initialParams?.length === 1 ? JSON.stringify(initialParams) : null;

  useEffect(() => {
    if (initialParams) {
      run(...initialParams).catch(e => console.error(e));
    }
  }, [dependency]);

  const tokenPreMiddleware = useCallback(
    async (context: RequestContext): Promise<FetchParams | void> => {
      const headers = new Headers();
      headers.append('Authorization', `Bearer ${user?.access_token}`);
      headers.append('Content-Type', `application/json;v=${version}`);
      return {
        url: context.url,
        init: {...context.init, headers: headers},
      };
    },
    [user],
  );

  const errorPostMiddleWare = useCallback(
    async (context: ResponseContext): Promise<Response | void> => {
      const {response} = context;
      const {ok, status: _status, statusText} = response;
      setStatus(_status);
      if (!ok) {
        setError(statusText);
        setLoading(false);
        throw response;
      }
    },
    [],
  );

  const offlinePostMiddleware = useCallback(
    async (context: ResponseContext): Promise<Response | void> => {
      const {url, response, init} = context;
      const {method: iMethod} = init;

      const offline = endpointConfig(url, iMethod)?.offline;
      if (offline) {
        await global.caches
          ?.open('api-offline-cache')
          .then(async cache => cache.put(url, response.clone()))
          .catch(failSilently);
      }
    },
    [],
  );

  const run = useCallback(
    async (...params: MethodParametersOf<T, K>): MethodReturnTypeOf<T, K> => {
      let deferred: (request: FetchApiParams) => void;
      const deferRequest = new Promise<FetchApiParams>(resolve => {
        deferred = resolve;
      });
      const {CLIENT_API_URL} = process.env;

      const client = new clientConstructor(
        new Configuration({
          basePath: CLIENT_API_URL,
          fetchApi: (requestInfo: RequestInfo, requestInit?: RequestInit) => {
            deferred({requestInfo, requestInit});
            return fetch(requestInfo, requestInit);
          },
        }),
      );

      const invokePromise = invoke(
        client
          .withPreMiddleware(tokenPreMiddleware)
          .withPostMiddleware(errorPostMiddleWare)
          .withPostMiddleware(offlinePostMiddleware),
        method,
        ...params,
      ) as Promise<PromiseResolveType<MethodReturnTypeOf<T, K>>>;

      setLoading(true);
      setError('');
      setStatus(undefined);
      const fetchParams = await deferRequest;
      const url = fetchParams.requestInfo as string;
      const preferOffline = endpointConfig(url, fetchParams.requestInit?.method)
        ?.preferOffline;
      const offline = endpointConfig(url, fetchParams.requestInit?.method)
        ?.offline;

      // if preferOffline get result from cache if any
      if (preferOffline) {
        const cache = await global.caches
          ?.open('api-offline-cache')
          .catch(failSilently);
        const response = await cache?.match(fetchParams.requestInfo);
        if (response) {
          const responseJson = await response.json();
          setData(responseJson);
          setLoading(false);
          invokePromise.then(result => setData(result));
          return responseJson;
        }
      }

      try {
        if (devLogging) {
          console.log(
            '%cuseOpenApi - Try Call!',
            'background-color:magenta; color: black',
            {
              url,
              headers: fetchParams.requestInit?.headers,
              method: fetchParams.requestInit?.method || 'GET',
              body: JSON.stringify(fetchParams.requestInit?.body),
            },
          );
        }
        const result = await invokePromise;
        setData(result);
        return result;
      } catch (e) {
        if (offline) {
          const cache = await global.caches
            ?.open('api-offline-cache')
            .catch(failSilently);
          const response = await cache?.match(fetchParams.requestInfo);
          if (response) {
            const responseJson = await response.json();
            setData(responseJson);
            setLoading(false);
            return responseJson;
          }
        }
        const alert = endpointConfig(url, fetchParams.requestInit?.method)
          ?.errorAlert;
        alert && notification({...alert});
        track(AnalyticType.ApiError, {
          error: error || '',
          url,
          headers: fetchParams.requestInit?.headers,
          method: fetchParams.requestInit?.method || 'GET',
          body: JSON.stringify(fetchParams.requestInit?.body),
        });
        setStatus(500);
        setError(`Error fetching ${String(method)} - v${version}`);
        if (devLogging) {
          console.log(
            '%cuseOpenApi - Catch Call!',
            'background-color:magenta; color: black',
            {
              error: error || '',
              url,
              headers: fetchParams.requestInit?.headers,
              method: fetchParams.requestInit?.method || 'GET',
              body: JSON.stringify(fetchParams.requestInit?.body),
            },
          );
        }
        throw e;
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  return {run, loading, data, error, status};
};

const endpointConfig = (url: string, method?: string) => {
  const methodMappings = endpointsConfig[method || 'GET'];
  const match = Object.keys(methodMappings).find(exp => url.match(exp));
  if (match) {
    return methodMappings[match];
  }
};

const failSilently = (err: Error) => {
  console.error(err);
  return null;
};

import {Invoice} from '@emporos/api-enterprise';
import * as yup from 'yup';
import {diffYears, isAfterNow, isBeforeNow} from '../../../../../';
import {mapIdCheckIndicators} from './';

export const mapSchema = (
  invoice: Invoice,
  maxAge = 125,
  maxIdExpiration = 50,
): yup.ObjectSchema => {
  const idNumber = yup.string().max(50).required();
  let firstName = yup.string().max(50);
  const middleName = yup.string().max(50);
  let lastName = yup.string().max(50);

  let dob = yup
    .string()
    .required()
    .test(
      'dob',
      'Invalid dob',
      _dateOfBirth =>
        !!_dateOfBirth &&
        isBeforeNow(_dateOfBirth) &&
        Math.abs(diffYears(_dateOfBirth as string)) <= Math.abs(maxAge),
    );

  const idExpirationDate = yup
    .string()
    .required()
    .test('idExpirationDate', 'Invalid idExpirationDate', _expirationDate => {
      return (
        !!_expirationDate &&
        isAfterNow(_expirationDate) &&
        Math.abs(diffYears(_expirationDate as string)) <=
          Math.abs(maxIdExpiration)
      );
    });

  let address1 = yup
    .string()
    .matches(/^[A-Za-z0-9- ,.'#/]*$/)
    .max(100);
  const address2 = yup
    .string()
    .matches(/^[A-Za-z0-9- ,.'#/]*$/)
    .max(100);
  let city = yup.string().max(50);
  let state = yup.string().nullable();
  const zipCode = yup
    .string()
    .matches(/^\d{5}$/)
    .required();
  let gender = yup.string().nullable();

  const {isNplex, isRequiredAge, age} = mapIdCheckIndicators(invoice);

  if (isNplex) {
    firstName = firstName.required();
    lastName = lastName.required();
    address1 = address1.required();
    city = city.required();
    state = state.required();
    gender = gender.required();
  }

  if (isRequiredAge && age) {
    dob = dob.test(
      'dob',
      '',
      _dateOfBirth => !!_dateOfBirth && diffYears(_dateOfBirth) >= age,
    );
  }

  return yup.object().shape({
    idNumber,
    firstName,
    middleName,
    lastName,
    dob,
    idExpirationDate,
    address1,
    address2,
    city,
    state,
    zipCode,
    gender,
  });
};

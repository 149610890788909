import {Customer} from '@emporos/api-enterprise';
import {createContext, useCallback, useContext, useState} from 'react';
import {mapInvoiceCustomer, useInvoice} from '../';
import {useApi} from './ApiProvider';

type TCustomerSearchContext = {
  customer: Customer | null;
  setCustomer: (customer: Customer | null) => void;
  refresh: () => Promise<void>;
};

export const CustomerSearchContext = createContext<TCustomerSearchContext>({
  customer: null,
  setCustomer: () => null,
  refresh: async () => console.log(),
});

type CustomerSearchProviderProps = {children: React.ReactNode};

export function CustomerSearchProvider(
  props: CustomerSearchProviderProps,
): JSX.Element {
  const api = useApi();
  const {invoice} = useInvoice();
  const [customer, setCustomer] = useState<Customer | null>(
    invoice.customer || null,
  );
  const {run: getCustomerById} = api.GetCustomer();

  const refresh = useCallback(async () => {
    if (!customer) {
      return;
    }
    return getCustomerById({customerId: customer.id}).then(
      ({data}) => data && setCustomer(mapInvoiceCustomer(data)),
    );
  }, [customer]);

  return (
    <CustomerSearchContext.Provider value={{customer, setCustomer, refresh}}>
      {props.children}
    </CustomerSearchContext.Provider>
  );
}

export const useCustomerSearch = (): TCustomerSearchContext =>
  useContext(CustomerSearchContext);

import {CashDrawer, Site, Station} from '@emporos/api-enterprise';
import {
  Button,
  FooterGroup,
  Gutter,
  Header,
  Row,
  ScrollContainer,
  Select,
  Stack,
  TextInputWithPlaceholder,
  TextSize as Size,
  toCurrency,
  Variant,
} from '@emporos/components';
import {memo, useEffect, useReducer, useState} from 'react';
import {useNetworkAvailable} from '../../../contexts';

type FormState = {
  site: Site;
  station: Station;
  paymentDeviceAddress: string;
  paymentDevicePort: string;
  till: CashDrawer;
  tillStartingAmount: number;
};

type FormStateWithSite = Pick<FormState, 'site'> &
  {[K in keyof Omit<FormState, 'site'>]: null};
type FormStateWithStation = Pick<FormState, 'site' | 'station'> &
  {
    [K in keyof Omit<FormState, 'site' | 'station'>]:
      | Omit<FormState, 'site' | 'station'>[K]
      | null;
  };
export type SessionConfig =
  | {[K in keyof FormState]: null}
  | FormStateWithSite
  | FormStateWithStation;

export function isSessionConfigCompleted(
  config: SessionConfig,
): config is FormState {
  return (
    config.site !== null &&
    config.station !== null &&
    config.till !== null &&
    config.tillStartingAmount !== null
  );
}

interface Props {
  sites: Site[];
  stations: Station[];
  tills: CashDrawer[];
  config: SessionConfig;
  configSession?: boolean;
  loading: boolean;
  onChange: (config: SessionConfig) => void;
  onLogout?: () => void;
  onConfirm?: () => void;
}
function getTitle({name}: Station): string {
  return name || '';
}

type FormOptionsState = {
  stations: Station[] | null;
  tills: CashDrawer[] | null;
};

type FormOptionsReducerAction = {
  type?: 'clear' | 'setStations' | 'setTills';
  value?: Station[] | CashDrawer[];
};

const formOptionsReducer = (
  state: FormOptionsState,
  {type, value}: FormOptionsReducerAction,
) => {
  switch (type) {
    case 'clear':
      return {...state, stations: null, tills: null};
    case 'setStations':
      return {...state, stations: value as Station[]};
    case 'setTills':
      return {...state, tills: value as CashDrawer[]};
    default:
      return {...state};
  }
};

function CreateSessionComponent({
  sites,
  stations,
  tills,
  config,
  configSession = false,
  onChange,
  onLogout,
  onConfirm,
  loading,
}: Props): JSX.Element {
  const {site, station, paymentDeviceAddress, till} = config;
  const [tillStartingAmount, setTillStartingAmount] = useState('');
  const [paymentDeviceAddressInput, setpaymentDeviceAddressInput] = useState(
    '',
  );
  const [
    formOptionsState,
    dispatchFormOptionsState,
  ] = useReducer(formOptionsReducer, {stations: null, tills: null});
  const confirmDisabled = !isSessionConfigCompleted(config);
  const disableField = configSession || !site;
  const disableFieldStation = !station;
  const online = useNetworkAvailable();

  useEffect(() => {
    dispatchFormOptionsState({type: 'clear'});
  }, [site]);

  useEffect(() => {
    // update station options when the stations prop changes
    dispatchFormOptionsState({type: 'setStations', value: stations});
  }, [stations]);

  useEffect(() => {
    // update till options when the tills prop changes
    dispatchFormOptionsState({type: 'setTills', value: tills});
  }, [tills]);

  return (
    <Stack>
      {!configSession && <Header title="Create Session" />}
      <ScrollContainer>
        <Stack
          gutter={Gutter.L}
          style={{flex: 1, marginTop: 6, paddingTop: configSession ? 16 : 0}}
        >
          <Row>
            <Select
              disabled={!online || configSession}
              name="site"
              label="Site*"
              options={sites.map(({id}) => id)}
              optionsText={sites.map(s => s.shortName)}
              value={site?.id || null}
              onChangeValue={value => {
                const foundSite = sites.find(({id}) => id === value);
                if (foundSite && !configSession) {
                  onChange({
                    site: foundSite,
                    station: null,
                    paymentDeviceAddress: null,
                    paymentDevicePort: null,
                    till: null,
                    tillStartingAmount: null,
                  });
                }
              }}
            />
            <Select
              disabled={
                !online || disableField || !formOptionsState.stations?.length
              }
              name="station"
              label="Station*"
              loading={Boolean(site && !formOptionsState.stations)}
              options={(formOptionsState.stations || []).map(({id}) => id)}
              optionsText={(formOptionsState.stations || []).map(getTitle)}
              value={station?.id || null}
              onChangeValue={value => {
                const foundStation = (formOptionsState.stations || []).find(
                  ({id}) => id === value,
                );
                if (foundStation) {
                  const foundPaymentDevice = {
                    address: (foundStation as Station)?.paymentDeviceAddress,
                    port: (foundStation as Station)?.paymentDevicePort,
                  };
                  console.log('foundPaymentDevice', foundPaymentDevice.address);
                  onChange({
                    site: config.site as Site,
                    station: foundStation,
                    paymentDeviceAddress: foundPaymentDevice.address || '',
                    paymentDevicePort: foundPaymentDevice.port || '',
                    till: config.till,
                    tillStartingAmount: config.tillStartingAmount,
                  });
                }
              }}
            />
          </Row>
          <Row>
            <Select
              disabled={
                !online || disableField || !formOptionsState.tills?.length
              }
              name="till"
              label="Till*"
              loading={Boolean(site && !formOptionsState.tills)}
              options={(formOptionsState.tills || []).map(
                ({cashDrawerId}) => cashDrawerId,
              )}
              optionsText={(formOptionsState.tills || []).map(
                ({cashDrawerName}) => cashDrawerName || '',
              )}
              value={till?.cashDrawerId || null}
              onChangeValue={value => {
                const foundTill = (formOptionsState.tills || []).find(
                  ({cashDrawerId}) => cashDrawerId === value,
                );
                if (foundTill) {
                  onChange({
                    site: config.site as Site,
                    station: config.station as Station,
                    paymentDeviceAddress: config.paymentDeviceAddress,
                    paymentDevicePort: config.paymentDevicePort,
                    till: foundTill,
                    tillStartingAmount: config.tillStartingAmount,
                  });
                }
              }}
            />
            <TextInputWithPlaceholder
              disabled={!online || disableField}
              name="tillStartingAmount"
              label="Till Starting Amount*"
              placeholder="$0.00"
              value={
                configSession && config.tillStartingAmount
                  ? `$${toCurrency(config.tillStartingAmount, '.')}`
                  : tillStartingAmount
              }
              onFocus={() => {
                if (!tillStartingAmount) {
                  setTillStartingAmount(' ');
                  onChange({
                    site: config.site as Site,
                    station: config.station as Station,
                    paymentDeviceAddress: config.paymentDeviceAddress,
                    paymentDevicePort: config.paymentDevicePort,
                    till: config.till,
                    tillStartingAmount: null,
                  });
                }
              }}
              onBlur={() => {
                if (tillStartingAmount.trim() === '') {
                  setTillStartingAmount('');
                }
              }}
              onChange={value => {
                const valueAsCurrency = toCurrency(value.target.value, '.');
                setTillStartingAmount(`$${valueAsCurrency}`);
                onChange({
                  site: config.site as Site,
                  station: config.station as Station,
                  paymentDeviceAddress: config.paymentDeviceAddress,
                  paymentDevicePort: config.paymentDevicePort,
                  till: config.till,
                  tillStartingAmount: Number(valueAsCurrency),
                });
              }}
              onClear={() => {
                setTillStartingAmount('');
                onChange({
                  site: config.site as Site,
                  station: config.station as Station,
                  paymentDeviceAddress: config.paymentDeviceAddress,
                  paymentDevicePort: config.paymentDevicePort,
                  till: config.till,
                  tillStartingAmount: null,
                });
              }}
              style={{flex: '1'}}
              inputSize={Size.Large}
              inputMode="numeric"
            />
          </Row>
          <Row>
            <TextInputWithPlaceholder
              disabled={!online || disableFieldStation}
              name="paymentDeviceAddress"
              label="Payment Device Address"
              placeholder=""
              value={
                paymentDeviceAddress
                  ? paymentDeviceAddress
                  : paymentDeviceAddressInput
              }
              onChange={value => {
                setpaymentDeviceAddressInput(value.target.value);
                onChange({
                  site: config.site as Site,
                  station: config.station as Station,
                  paymentDeviceAddress: value.target.value,
                  paymentDevicePort:
                    (config.station as Station)?.paymentDevicePort || '',
                  till: config.till,
                  tillStartingAmount: config.tillStartingAmount,
                });
              }}
              onClear={() => {
                setpaymentDeviceAddressInput('');
                onChange({
                  site: config.site as Site,
                  station: config.station as Station,
                  paymentDeviceAddress: '',
                  paymentDevicePort: config.paymentDevicePort,
                  till: config.till,
                  tillStartingAmount: config.tillStartingAmount,
                });
              }}
              style={{flex: '0.5', marginRight: '15px'}}
              inputSize={Size.Large}
            />
          </Row>
        </Stack>
      </ScrollContainer>
      {!configSession && onLogout && onConfirm && (
        <FooterGroup>
          <Button
            variant={Variant.Danger}
            flex
            disabled={!online}
            onClick={onLogout}
          >
            Logout
          </Button>
          <Button
            flex
            disabled={!online || confirmDisabled}
            onClick={confirmDisabled ? undefined : onConfirm}
            loading={loading}
          >
            Confirm
          </Button>
        </FooterGroup>
      )}
    </Stack>
  );
}

export const CreateSession = memo(CreateSessionComponent);

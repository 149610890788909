import {Invoice, PseItem} from '@emporos/api-enterprise';

export const mapItemMilligrams = (invoice: Invoice): Array<PseItem> =>
  invoice.items
    .filter(item => item.controlGrams > 0)
    .map<PseItem>(({controlGrams, description, itemNumber, quantity}) => ({
      boxes: quantity,
      description: description || '',
      milligramsPerBox: controlGrams,
      number: (itemNumber || 0).toString(),
      pillsPerBox: '', //nothing similar in InvoiceItem
      dosagesPerBox: '', //nothing similar in InvoiceItem
      upc: '', //nothing similar in InvoiceItem
    }));

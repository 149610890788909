import React, {memo, useRef} from 'react';
// import swiper js
import {Swiper, SwiperSlide} from 'swiper/react';

// import required modules
import {Navigation, Pagination, Swiper as SwiperType} from 'swiper';

const swiper = {
  width: '100%',
  height: '100%',
};

const slideWidth = {
  width: 'auto',
};

export const Carousel = memo(
  ({...props}: React.PropsWithoutRef<JSX.IntrinsicElements['div']>) => {
    // store a ref to the swiper instance we get so that we can have some control over nav buttons/arrows
    const swiperRef = useRef<SwiperType>();

    // logic for controlling whether nav buttons/arrows are 'hidden' depending on where user is with carousel
    /* istanbul ignore next - cannot effectively test as we are relying on a mock of swiper here*/
    const updateNavButtons = () => {
      if (swiperRef && swiperRef.current) {
        // NOTE: Swiper seems to know to hide the nav buttons itself if width of elements does not exceed screen, but logic could be added to hide nav buttons for less than min no. of slides
        switch (swiperRef.current.realIndex) {
          // if user hasn't nav'd to the right (we are still on slide 0) with nav button/arrow, don't show the left arrow
          case 0:
            swiperRef.current.navigation.prevEl.style.display = 'none';
            return;
          default:
            swiperRef.current.navigation.prevEl.style.display = '';
            return;
        }
      }
    };

    // throws up an indicator that no child elements were submitted as props and therefore we have an empty carousel
    const onEmptyProps = () => {
      return (
        <SwiperSlide style={slideWidth}>
          <h2 data-testid="carousel-emptyindicator">
            No slide element props detected!
          </h2>
        </SwiperSlide>
      );
    };

    return (
      <Swiper
        style={swiper}
        slidesPerView={'auto'}
        spaceBetween={30}
        centeredSlides={false}
        pagination={{
          clickable: true,
          type: 'custom',
        }}
        // event emitted by Swiper when user changes slides/navs through carousel
        onSlideChange={
          /* istanbul ignore next - cannot effectively test as we are relying on a mock of swiper here */
          () => {
            if (swiperRef && swiperRef.current) {
              updateNavButtons();
            }
          }
        }
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
        // onBeforeInit and onAfterInit events are also available (though many of the Swiper dom elements have not actually been built out yet with onBeforeInit)
        onInit={
          /* istanbul ignore next - cannot effectively test as we are relying on a mock of swiper here*/
          swiper => {
            swiperRef.current = swiper;
            updateNavButtons();
          }
        }
      >
        {props &&
          React.Children.map(props.children, (element: any, index: number) => (
            <SwiperSlide style={slideWidth} key={index}>
              {element}
            </SwiperSlide>
          ))}
        {!props.children && onEmptyProps()}
      </Swiper>
    );
  },
);

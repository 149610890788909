import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';

interface Props {
  loadingPendingInvoicePaymentId?: string;
  setLoadingPendingInvoicePaymentId: Dispatch<
    SetStateAction<string | undefined>
  >;
}

// the string represents the invoicePaymentId that is currently processing
const CreditCardProcessingContext = createContext<Props>({
  setLoadingPendingInvoicePaymentId: console.log,
});

export function CreditCardProcessingProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [
    loadingPendingInvoicePaymentId,
    setLoadingPendingInvoicePaymentId,
  ] = useState<string>();

  return (
    <CreditCardProcessingContext.Provider
      value={{
        loadingPendingInvoicePaymentId,
        setLoadingPendingInvoicePaymentId,
      }}
    >
      {children}
    </CreditCardProcessingContext.Provider>
  );
}
export const useCreditCardProcessing = (): Props =>
  useContext(CreditCardProcessingContext);

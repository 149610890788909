import {memo} from 'react';
import {
  OfflineInvoice,
  useInvoice,
  useNetworkAvailable,
  useTotals,
  withChildPage,
  WithPageProps,
} from '../../../';
import {CustomerInfoPanel} from '../';

interface Props {
  viewOnly?: boolean;
}

function CustomerInfoComponent({viewOnly}: WithPageProps<Props>) {
  const online = useNetworkAvailable();
  const {invoice, updateInvoice} = useInvoice();
  const {taxGroupId} = useTotals();

  return (
    <CustomerInfoPanel
      online={online}
      invoice={invoice as OfflineInvoice}
      taxGroupId={taxGroupId}
      viewOnly={viewOnly}
      onCancel={() => {
        history.back();
      }}
      onSave={updates => {
        updateInvoice(updates);
        history.back();
      }}
    />
  );
}

export const CustomerInfoIntegration = memo(
  withChildPage(CustomerInfoComponent),
);

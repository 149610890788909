import React, {memo} from 'react';
import styled from 'styled-components';
import {
  Gutter,
  Icon,
  IconSize,
  Row,
  Stack,
  Text,
  TextVariant as Variant,
} from './';
import {
  PaymentVisa,
  PaymentAmex,
  PaymentMastercard,
  PaymentDiscover,
} from './IconsPayment';

export interface CardOnFileProps {
  cardHolder?: string;
  expirationDate?: string;
  lastFour?: string;
  cardType?: string;
  nickName?: string;
  isDefault?: boolean;
  isExpired?: boolean;
  isSelected?: boolean;
}

const BaseCard = styled(Stack)`
  display: inline-flex;
  padding: 24px;
  height: 250px;
  min-width: 400px;
  background-color: rgba(247, 252, 255, 1);
  border-radius: 8px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
  align-content: space-between;
  position: relative;
`;

const DefaultLabel = styled(Row)`
  position: absolute;
  font-size: 18px;
  font-family: ubuntu;
  font-weight: 500;
  color: limegreen;
  z-index: 1;
  top: 24px;
  left: 24px;
`;

const ExpiredLabel = styled(Row)`
  position: absolute;
  font-size: 18px;
  font-family: ubuntu;
  font-weight: 500;
  color: red;
  z-index: 1;
  top: 24px;
  left: 24px;
`;

const LastFourText = styled(Text)`
  font-size: 32px;
  font-family: ubuntu;
  font-weight: 400;
  color: white;
  margin-top: 30px;
  z-index: 1;
  align-self: flex-start;
`;

const HolderRow = styled(Row)`
  width: 100%;
  margin-top: 50px;
`;

const LowerRow = styled(Row)`
  width: 400px;
  margin-top: 10px;
`;

const IconRow = styled(Row)`
  flex-direction: row-reverse;
  width: 100%;
  height: 80px;
  z-index: 1;
`;

const container = {
  position: 'relative' as 'relative',
  padding: '10px',
};

const expDate = {
  fontSize: '18px',
  fontFamily: 'ubuntu',
  fontWeight: '500',
  color: 'black',
  marginLeft: 'auto',
  display: 'flex',
  flexDirection: 'column' as 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',
};

const expText = {
  color: 'rgba(200,210,225,1.00)',
  alignSelf: 'flex-center',
};

const expDigits = {
  fontSize: '20px',
  marginTop: '5px',
  alignSelf: 'flex-center',
};

const nickNameStyle = {
  fontSize: '18px',
  fontFamily: 'ubuntu',
  fontWeight: '500',
  color: 'grey',
  marginLeft: '24px',
  marginTop: '24px',
};

const gradientBox = {
  width: '400px',
  height: '155px',
  backgroundImage:
    'linear-gradient(90deg, rgba(16,111,229,1) 0%, rgba(193,133,254,1) 100%)',
  backgroundColor: 'black',
  position: 'absolute' as 'absolute',
  top: '0px',
  margin: '0px',
  borderTopRightRadius: '8px',
  borderTopLeftRadius: '8px',
};

const mapCardLogo = (cardType: string | undefined) => {
  switch (cardType) {
    case 'Amex':
      return (
        <PaymentAmex width={80} height={60} data-testid="cardonfile-cardLogo" />
      );
    case 'Discover':
    case 'Disc':
      return (
        <PaymentDiscover
          width={80}
          height={60}
          data-testid="cardonfile-cardLogo"
        />
      );
    case 'MasterCard':
    case 'MC':
      return (
        <PaymentMastercard
          width={80}
          height={60}
          data-testid="cardonfile-cardLogo"
        />
      );
    case 'Visa':
      return (
        <PaymentVisa width={80} height={60} data-testid="cardonfile-cardLogo" />
      );
    default:
      return (
        <Icon
          variant={'inverted'}
          icon="CreditCard"
          size={IconSize.CardOnFile}
          data-testid="cardonfile-cardLogo"
        />
      );
  }
};

// this could probably be converted to a useState hook so that we can set isSelected based on click,
// but seems likely that click handler will be within parent component, in which case we will pass isSelected in
const mapCardStyles = (
  isExpired: boolean | undefined,
  isSelected: boolean | undefined,
) => {
  let cardStyles = {};
  if (isExpired) {
    cardStyles = {...cardStyles, opacity: '.5'};
  }
  if (isSelected) {
    cardStyles = {
      ...cardStyles,
      boxShadow: '0px 0px 4px 5px rgba(16,111,229,1)',
    };
  }
  return cardStyles;
};

export const CardOnFile = memo(
  ({
    cardHolder,
    expirationDate,
    lastFour,
    cardType,
    nickName,
    isDefault,
    isExpired,
    isSelected,
    ...props
  }: CardOnFileProps & React.PropsWithoutRef<JSX.IntrinsicElements['div']>) => {
    const cardLogo = mapCardLogo(cardType);
    const cardStyles = mapCardStyles(isExpired, isSelected);

    return (
      <div style={container}>
        {isExpired && (
          <ExpiredLabel data-testid="cardonfile-expiredlabel">
            EXPIRED
          </ExpiredLabel>
        )}
        {isDefault && (
          <DefaultLabel data-testid="cardonfile-defaultlabel">
            DEFAULT
          </DefaultLabel>
        )}
        <BaseCard
          style={cardStyles}
          gutter={Gutter.S}
          align="center"
          data-testid="cardonfile-basecard"
        >
          <IconRow
            align="start"
            justify="space-between"
            gutter={Gutter.XL}
            noWrap
          >
            {cardLogo}
          </IconRow>
          <LastFourText data-testid="cardonfile-lastfour">
            XXXX - XXXX - {lastFour}
          </LastFourText>
          <HolderRow align="end" justify="space-between" noWrap>
            <Text variant={Variant.T3} data-testid="cardonfile-cardholder">
              {cardHolder}
            </Text>
            <div style={expDate}>
              <div style={expText}>Exp</div>
              <div style={expDigits} data-testid="cardonfile-expirationdate">
                {expirationDate}
              </div>
            </div>
          </HolderRow>
          <div style={gradientBox}></div>{' '}
          {/* gives us the purple-pink gradient across top of card */}
        </BaseCard>

        <LowerRow
          align="center"
          justify="space-between"
          gutter={Gutter.XL}
          noWrap
        >
          <p style={nickNameStyle} data-testid="cardonfile-nickname">
            {nickName}
          </p>
          <Row align="center">{props.children}</Row>
        </LowerRow>
      </div>
    );
  },
);

// TODO: this component should not know how we communicate with the API. This
// should be moved up towards the http integration layer
import {
  Customer,
  CustomerAccount,
  Invoice,
  InvoiceItem,
  Prescription,
  WillCallRequest,
} from '@emporos/api-enterprise';
import {CompleteBarcodeComponent, decode, ScanResult} from '@emporos/barcodes';
import {
  Button,
  ButtonShape,
  CardOnFile,
  Carousel,
  FooterGroup,
  Gutter,
  Header,
  Illustration,
  Numberpad,
  PendingChangesModal,
  Row,
  RowItem,
  ScrollContainer,
  SegmentSlider,
  ButtonSize as Size,
  Stack,
  Text,
  TextInput,
  TextVariant as TV,
  Variant,
  VerificationModal,
  theme,
} from '@emporos/components';
import {PageBarcodeScanner} from '@emporos/components-pos';
import assert from 'assert';
import {memo, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {
  AlertLogTypes,
  AnalyticType,
  ars,
  createInvoicePrescriptionReducer,
  formatMedication,
  InvoiceLogTypes,
  isRxItem,
  ManualRXFormData,
  normalizeCustomer,
  OfflineCustomer,
  OfflineInvoice,
  OfflineSynced,
  pds,
  useAlertState,
  useAnalyticsProvider,
  useBetaFeatures,
  useGlobalData,
  useLog,
  UserLogTypes,
  WillCallPanel,
} from '../../../';

interface Props {
  barcodeComponents?: CompleteBarcodeComponent[];
  forceCanSave?: boolean;
  initialPrescription?: ManualRXFormData | null;
  invoice: OfflineInvoice;
  invoiceChanged?: boolean;
  loadingCustomer?: boolean;
  online?: boolean;
  pmsName?: string;
  taxGroupId?: number;
  viewOnly?: boolean;
  onAddCustomerClick?: () => void;
  onCancel: () => void;
  onSave: (updates: Partial<Invoice>) => void;
  onUnresolvedRxScan?: (willCallParams: WillCallRequest) => void;
}

const findRx = (prescriptions: Prescription[], item: ManualRXFormData) =>
  prescriptions.find(
    ({fill, partial, rx, site}) =>
      fill === item.fill &&
      partial === item.partial &&
      rx === item.rx &&
      site === item.site,
  );

const willCallError = new Error(
  'Customer prescription info could not be pulled down. Please try again.',
);
const mismatchedError = new Error(
  'This prescription doesn’t match the customer attached to the sale.',
);
const notFoundError = new Error(
  'This prescription cannot be found on the customer’s Will Call.',
);
const doubleScanError = (medication: string) => {
  const doubleScan = new Error(
    `${formatMedication(medication)} has already been scanned on this sale.`,
  );
  doubleScan.name = 'Prescription Already Scanned';
  return doubleScan;
};

const Footer = (onCancel: () => void, canSave: boolean, loading: boolean) => {
  return (
    <div style={{marginTop: 'auto'}}>
      <FooterGroup>
        <Button
          type="button"
          onClick={onCancel}
          variant={canSave ? Variant.Danger : Variant.Secondary}
          flex
        >
          Cancel
        </Button>
        <Button
          variant={Variant.Primary}
          type="submit"
          flex
          loading={loading}
          disabled={!canSave}
        >
          Save
        </Button>
      </FooterGroup>
    </div>
  );
};

const RenderEmptyRow = (type: string) => (
  <RowItem title="None" variant="inverted" data-testid={`no-${type}`} />
);

const ARAccounts = (arAccounts: CustomerAccount[]) =>
  !arAccounts.length
    ? RenderEmptyRow('ar')
    : arAccounts.map(ar => (
        <RowItem
          key={ar.accountNumber}
          title={ar.accountNumber}
          subtitle={`Limit: $${ar.creditLimit.toFixed(2)}`}
          rightText={`Due: $${ar.balance.toFixed(2)}`}
          leftIcon="Bill"
          leftIconColor="indigo"
          noClick
        />
      ));

const CreditCards = (customer: Customer | undefined) => {
  const elements: JSX.Element[] = [];
  if (customer && customer.creditCards && customer.creditCards.length) {
    customer.creditCards.forEach((cc, i) =>
      elements.push(
        <CardOnFile
          cardHolder={`${customer.lastName}, ${customer.firstName}`}
          expirationDate={formatExpDate(cc.expiration)}
          lastFour={cc.last4OfCard}
          cardType={cc.cardType}
          nickName="nickname"
          isExpired={compareDates(cc.expiration)}
          isSelected={false}
          isDefault={cc.isPrimary}
          key={i}
        >
          <ButtonShape size={Size.Small} icon="Pencil" />
          <ButtonShape size={Size.Small} icon="Trash" />
        </CardOnFile>,
      ),
    );
  } else {
    elements.push(RenderEmptyRow('cc'));
  }
  return elements;
};

const CCRow = styled('div')`
  padding: 16px;
  border-radius: 8px;
  border: 2px solid ${theme.colors.gray_200};
`;

const formatExpDate = (date: Date) => {
  const month = (date.getMonth() + 1).toString();
  const year = date.getFullYear().toString().slice(2);
  const exp = month + '/' + year;
  return exp;
};

const compareDates = (cardExp: Date) => {
  const today = new Date();
  const isExpired = cardExp < today;
  return isExpired;
};

const PDAccounts = (pdAccounts: CustomerAccount[]) =>
  !pdAccounts.length
    ? RenderEmptyRow('pd')
    : pdAccounts.map(pd => (
        <RowItem
          key={pd.accountNumber}
          title={pd.accountNumber}
          subtitle={`Limit: $${pd.creditLimit.toFixed(2)}`}
          rightText={`Due: $${pd.balance.toFixed(2)}`}
          leftIcon="IdCard"
          leftIconColor="indigo"
          noClick
        />
      ));

const CustomerInfoPanelComponent = ({
  barcodeComponents,
  forceCanSave = false,
  initialPrescription,
  invoice,
  invoiceChanged,
  loadingCustomer = false,
  online,
  pmsName,
  viewOnly = false,
  onCancel,
  onSave,
  onAddCustomerClick,
  onUnresolvedRxScan,
}: Props): JSX.Element | null => {
  const {notification, reset} = useAlertState();
  const {track} = useAnalyticsProvider();
  // To ensure feature flag prevents verification functionality
  const {verificationModal} = useBetaFeatures();
  const {logAlert, logUserSelection} = useLog();
  const [active, setActive] = useState(0);
  const [invoiceNotes, setInvoiceNotes] = useState(invoice.notes || '');
  const [roomNumber, setRoomNumber] = useState(invoice.roomNumber || '');
  const [selectedRX, setSelectedPrescriptions] = useState<Array<string>>([]);
  const [showPendingChangesModal, setShowPendingChangesModal] = useState(false);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const {skipWillCall, setSkipWillCall} = useGlobalData();
  const {customer} = invoice;
  const customerHasCards = customer?.creditCards.length ? true : false;
  const creditCards = CreditCards(customer);
  const invoicePrescriptions = invoice.items.reduce((acc, {itemNumber, rx}) => {
    if (rx) {
      itemNumber && acc.push(itemNumber);
    }
    return acc;
  }, [] as string[]);
  const canSave =
    forceCanSave ||
    (customer &&
      !(customer as OfflineCustomer)?.prescriptions &&
      invoiceChanged) ||
    (!viewOnly &&
      selectedRX.length &&
      selectedRX.every(rx => !invoicePrescriptions.includes(rx))) ||
    (invoice.roomNumber || '') !== roomNumber ||
    (invoice.notes || '') !== invoiceNotes;

  const arAccounts = customer ? ars(customer) : [];
  const pdAccounts = customer ? pds(customer) : [];

  useEffect(() => {
    if (initialPrescription && customer) {
      handleRxScan(initialPrescription);
    }
  }, [initialPrescription, customer]);

  useEffect(() => {
    if (skipWillCall && customer) {
      setShowPendingChangesModal(false);
      addItems();
      setSkipWillCall(false);
    }
  }, [skipWillCall, customer]);

  const getPrescriptionItemNumber = (
    manualRxFormData: ManualRXFormData,
  ): string => {
    const prescriptions = (customer as OfflineCustomer | undefined)
      ?.prescriptions;

    if (!prescriptions) {
      willCallError.name = 'Will Call Error';
      throw willCallError;
    }

    const prescription = findRx(prescriptions, manualRxFormData);

    if (!prescription) {
      if (invoiceChanged) {
        notFoundError.name = 'Prescription Not Found';
        throw notFoundError;
      } else {
        mismatchedError.name = "Customer Doesn't Match";
        throw mismatchedError;
      }
    }

    const {itemNumber, description} = prescription;
    assert(itemNumber, 'Missing itemNumber');
    logUserSelection(UserLogTypes.ScannedRX, {
      result: prescription.description,
    });

    if (
      invoicePrescriptions.includes(itemNumber) ||
      (selectedRX && selectedRX.includes(itemNumber))
    ) {
      throw doubleScanError(description || '');
    }

    return itemNumber;
  };

  const addPrescriptionItemNumber = (itemNumber: string) => {
    setActive(0);
    setSelectedPrescriptions(curr => [...curr, itemNumber]);
  };

  const handleManualRxFormData = useCallback(
    (manualRxFormData: ManualRXFormData) =>
      addPrescriptionItemNumber(getPrescriptionItemNumber(manualRxFormData)),
    [invoicePrescriptions, selectedRX],
  );

  const handleRxScan = useCallback(
    (item: ManualRXFormData) => {
      if (Number(active) !== 0) {
        setActive(0);
      }

      if (customer) {
        try {
          handleManualRxFormData(item);
        } catch (error) {
          switch ((error as Error).name) {
            case 'Will Call Error':
              notification({
                title: (error as Error).name,
                description: (error as Error).message,
                type: 'warning',
                icon: 'Warning',
              });
              // Not placing tracking here since API related errors are tracked in alerts provider
              break;
            case 'Prescription Not Found':
              notification({
                title: (error as Error).name,
                description: (error as Error).message,
                type: 'error',
                icon: 'X',
              });
              track(AnalyticType.UserError, {
                message:
                  'User scanned prescription that no longer applies to the customer attached to the sale.',
              });
              break;
            case "Customer Doesn't Match":
              notification({
                title: (error as Error).name,
                description: (error as Error).message,
                type: 'error',
                icon: 'X',
              });
              track(AnalyticType.UserError, {
                message:
                  'User scanned prescription that does not match the customer attached to the sale.',
              });
              break;
            case 'Prescription Already Scanned':
              notification({
                title: (error as Error).name,
                description: (error as Error).message,
                type: 'warning',
                icon: 'Warning',
              });
              track(AnalyticType.UserError, {
                message: (error as Error).message,
              });
              break;
            default:
              notification({
                title: 'Error Occurred',
                description:
                  'Uh-oh! Something isn’t right with the Emporos API.',
                type: 'warning',
                icon: 'Warning',
              });
              // Not placing tracking here since API related errors are tracked in alerts provider
              break;
          }
        }
      } else if (!loadingCustomer) {
        // Fetch a customer if the will call screen is available and
        // not already fetching
        onUnresolvedRxScan &&
          onUnresolvedRxScan({
            rx: item.rx,
            fill: item.fill,
            partial: item.partial,
            site: item.site,
          });
      } else {
        notification({
          title: 'Will Call Loading',
          description:
            "We're on it! Please wait while we fetch customer information.",
          type: 'warning',
          icon: 'Warning',
        });
      }
    },
    [handleManualRxFormData, customer, loadingCustomer],
  );

  const addItems = useCallback(() => {
    let items: InvoiceItem[] = [...invoice.items];
    const rx = (customer as OfflineCustomer)?.prescriptions
      ?.filter(({itemNumber}) => itemNumber && selectedRX.includes(itemNumber))
      .map(p => ({...p, medicationStatus: 1}));
    if (rx?.length) {
      items = rx.reduce(createInvoicePrescriptionReducer(invoice), items);
      logUserSelection(InvoiceLogTypes.AddItem, {item: items});
      setShowVerificationModal(false);
    }
    onSave({
      isVerified: invoice.isVerified || (rx && rx.length > 0),
      items,
      notes: invoiceNotes,
      roomNumber,
      isSynced: false,
    } as Partial<Invoice> & OfflineSynced);
    logUserSelection(InvoiceLogTypes.CustomerUpdated);
  }, [
    customer,
    invoice,
    invoiceNotes,
    roomNumber,
    selectedRX,
    logUserSelection,
  ]);

  const _onCancel = useCallback(() => {
    if (canSave) {
      setShowPendingChangesModal(true);
      logAlert(AlertLogTypes.PendingChanges, {
        location: 'CustomerInfoPanel',
      });
    } else {
      onCancel();
    }
  }, [onCancel, canSave]);

  const onScan = useCallback(
    (scanResult: ScanResult) => {
      if (barcodeComponents && pmsName) {
        const item = decode(barcodeComponents, scanResult, pmsName);
        reset();
        if (isRxItem(item) && item.rx && item.fill && item.site) {
          handleRxScan(item);
        }
      }
    },
    [barcodeComponents, pmsName, handleRxScan],
  );

  function onSubmit(e: React.FormEvent) {
    e.preventDefault();
    setShowPendingChangesModal(false);
    // Show the verification modal first if selecting prescriptions and the
    // invoice is not verified
    if (selectedRX.length && !invoice?.isVerified && verificationModal) {
      logAlert(AlertLogTypes.Verification);
      setShowVerificationModal(true);
    } else {
      addItems();
    }
  }

  const RenderPaymentOrNotesTab = (activeTab: number) =>
    activeTab === 1 ? (
      <>
        <ScrollContainer>
          <Stack
            style={{flex: 1, height: '100%'}}
            data-testid="CustomerPaymentsTab"
          >
            {customer && (
              <Stack gutter={Gutter.S}>
                <Text
                  variant={TV.Caption}
                  style={{paddingLeft: 16, marginBottom: -6}}
                >
                  Credit Cards on File
                </Text>
                {/* {CreditCards(customer)} */}
                {customerHasCards && (
                  <CCRow>
                    <Carousel>{creditCards}</Carousel>
                  </CCRow>
                )}
                {!customerHasCards && RenderEmptyRow('cc')}
                <Text
                  variant={TV.Caption}
                  style={{paddingLeft: 16, marginBottom: -6}}
                >
                  AR Accounts
                </Text>
                {ARAccounts(arAccounts)}
                <Text
                  variant={TV.Caption}
                  style={{paddingLeft: 16, marginBottom: -6}}
                >
                  PD Accounts
                </Text>
                {PDAccounts(pdAccounts)}
              </Stack>
            )}
            {!customer && (
              <Stack
                align="center"
                justify="center"
                gutter={Gutter.None}
                style={{height: '100%'}}
              >
                <Illustration illustration="AddToGroup" />
                <Text variant={TV.Main} align="center">
                  Add customer to see payments
                </Text>
              </Stack>
            )}
          </Stack>
        </ScrollContainer>
        {Footer(_onCancel, canSave, loadingCustomer)}
      </>
    ) : (
      <>
        <Row
          data-testid="CustomerNotesTab"
          gutter={Gutter.XXL}
          style={{overflow: 'hidden', height: '100%'}}
          noWrap
        >
          <Stack style={{marginTop: '5px', width: '100%', flex: 1}}>
            {customer && (
              <TextInput
                {...{as: 'textarea', rows: '5'}}
                placeholder="Customer Notes"
                name="customerNotes"
                value={customer?.notes.join('\n')}
                disabled={true}
              />
            )}
            <TextInput
              {...{as: 'textarea', rows: '5'}}
              placeholder="Transaction Notes"
              name="transactionNotes"
              value={invoiceNotes}
              onChange={event => setInvoiceNotes(event.target.value)}
            />
            {Footer(_onCancel, canSave, loadingCustomer)}
          </Stack>
          <Numberpad
            title="Room Number"
            number={roomNumber}
            setNumber={setRoomNumber}
            onClear={() => setRoomNumber('')}
          />
        </Row>
      </>
    );

  return (
    <>
      <Stack
        style={{height: '100%'}}
        data-testid="CustomerInfoPanel"
        as="form"
        onSubmit={onSubmit}
      >
        <Header title="Customer Info">
          <SegmentSlider
            onSelectIndex={setActive}
            active={active}
            items={['Will Call', 'Payments', 'Notes']}
          />
        </Header>

        {active === 0 ? (
          <>
            <Row
              gutter={Gutter.XXL}
              style={{overflow: 'hidden', height: '100%'}}
              noWrap
              data-testid="CustomerInfoTab"
            >
              <Stack style={{width: '100%', flex: 1}}>
                <WillCallPanel
                  invoice={invoice}
                  customerInfo={customer}
                  loadingCustomer={loadingCustomer}
                  prescriptions={(customer as OfflineCustomer)?.prescriptions}
                  selectedRX={selectedRX}
                  viewOnly={viewOnly}
                  handleAddCustomerClick={onAddCustomerClick}
                  setSelectedPrescriptions={setSelectedPrescriptions}
                  online={!!online}
                />
              </Stack>
            </Row>

            {Footer(_onCancel, canSave, loadingCustomer)}
          </>
        ) : (
          RenderPaymentOrNotesTab(active)
        )}
      </Stack>
      {Number(active) === 0 && !viewOnly && (
        <PageBarcodeScanner onScan={onScan} />
      )}
      {verificationModal && customer && (
        <VerificationModal
          customerInfo={normalizeCustomer(customer)}
          visible={showVerificationModal}
          onCancel={() => setShowVerificationModal(false)}
          onContinue={addItems}
        />
      )}
      <PendingChangesModal
        visible={showPendingChangesModal}
        onCancel={() => setShowPendingChangesModal(false)}
        onContinue={onCancel}
      />
    </>
  );
};

export const CustomerInfoPanel = memo(CustomerInfoPanelComponent);

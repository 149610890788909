/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CustomerAccount,
    CustomerAccountFromJSON,
    CustomerAccountToJSON,
    InvoiceCustomerAccountsRequest,
    InvoiceCustomerAccountsRequestFromJSON,
    InvoiceCustomerAccountsRequestToJSON,
} from '../models';

export interface ClientCacheSessionsSessionIdInvoicesInvoiceIdCustomersCustomerIdAccountsDeleteRequest {
    sessionId: string;
    invoiceId: string;
    customerId: number;
    invoiceCustomerAccountId?: string;
}

export interface ClientCacheSessionsSessionIdInvoicesInvoiceIdCustomersCustomerIdAccountsGetRequest {
    sessionId: string;
    invoiceId: string;
    customerId: number;
}

export interface ClientCacheSessionsSessionIdInvoicesInvoiceIdCustomersCustomerIdAccountsInvoiceCustomerAccountIdGetRequest {
    sessionId: string;
    invoiceId: string;
    customerId: number;
    invoiceCustomerAccountId: string;
}

export interface ClientCacheSessionsSessionIdInvoicesInvoiceIdCustomersCustomerIdAccountsPutRequest {
    sessionId: string;
    invoiceId: string;
    customerId: number;
    invoiceCustomerAccountsRequest?: InvoiceCustomerAccountsRequest;
}

/**
 * 
 */
export class InvoiceCustomerAccountsApi extends runtime.BaseAPI {

    /**
     * Deletes an invoiceCustomer
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdCustomersCustomerIdAccountsDeleteRaw(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdCustomersCustomerIdAccountsDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdCustomersCustomerIdAccountsDelete.');
        }

        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdCustomersCustomerIdAccountsDelete.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdCustomersCustomerIdAccountsDelete.');
        }

        const queryParameters: any = {};

        if (requestParameters.invoiceCustomerAccountId !== undefined) {
            queryParameters['invoiceCustomerAccountId'] = requestParameters.invoiceCustomerAccountId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/invoices/{invoiceId}/customers/{customerId}/accounts`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"invoiceId"}}`, encodeURIComponent(String(requestParameters.invoiceId))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes an invoiceCustomer
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdCustomersCustomerIdAccountsDelete(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdCustomersCustomerIdAccountsDeleteRequest): Promise<void> {
        await this.clientCacheSessionsSessionIdInvoicesInvoiceIdCustomersCustomerIdAccountsDeleteRaw(requestParameters);
    }

    /**
     * Gets a list of invoiceCustomers for an Invoice
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdCustomersCustomerIdAccountsGetRaw(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdCustomersCustomerIdAccountsGetRequest): Promise<runtime.ApiResponse<Array<CustomerAccount>>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdCustomersCustomerIdAccountsGet.');
        }

        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdCustomersCustomerIdAccountsGet.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdCustomersCustomerIdAccountsGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/invoices/{invoiceId}/customers/{customerId}/accounts`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"invoiceId"}}`, encodeURIComponent(String(requestParameters.invoiceId))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CustomerAccountFromJSON));
    }

    /**
     * Gets a list of invoiceCustomers for an Invoice
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdCustomersCustomerIdAccountsGet(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdCustomersCustomerIdAccountsGetRequest): Promise<Array<CustomerAccount>> {
        const response = await this.clientCacheSessionsSessionIdInvoicesInvoiceIdCustomersCustomerIdAccountsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gets an invoiceCustomer accounts by session id, invoice id and invoiceCustomer id
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdCustomersCustomerIdAccountsInvoiceCustomerAccountIdGetRaw(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdCustomersCustomerIdAccountsInvoiceCustomerAccountIdGetRequest): Promise<runtime.ApiResponse<CustomerAccount>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdCustomersCustomerIdAccountsInvoiceCustomerAccountIdGet.');
        }

        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdCustomersCustomerIdAccountsInvoiceCustomerAccountIdGet.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdCustomersCustomerIdAccountsInvoiceCustomerAccountIdGet.');
        }

        if (requestParameters.invoiceCustomerAccountId === null || requestParameters.invoiceCustomerAccountId === undefined) {
            throw new runtime.RequiredError('invoiceCustomerAccountId','Required parameter requestParameters.invoiceCustomerAccountId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdCustomersCustomerIdAccountsInvoiceCustomerAccountIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/invoices/{invoiceId}/customers/{customerId}/accounts/{invoiceCustomerAccountId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"invoiceId"}}`, encodeURIComponent(String(requestParameters.invoiceId))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"invoiceCustomerAccountId"}}`, encodeURIComponent(String(requestParameters.invoiceCustomerAccountId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerAccountFromJSON(jsonValue));
    }

    /**
     * Gets an invoiceCustomer accounts by session id, invoice id and invoiceCustomer id
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdCustomersCustomerIdAccountsInvoiceCustomerAccountIdGet(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdCustomersCustomerIdAccountsInvoiceCustomerAccountIdGetRequest): Promise<CustomerAccount> {
        const response = await this.clientCacheSessionsSessionIdInvoicesInvoiceIdCustomersCustomerIdAccountsInvoiceCustomerAccountIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Inserts or updates a customer account
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdCustomersCustomerIdAccountsPutRaw(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdCustomersCustomerIdAccountsPutRequest): Promise<runtime.ApiResponse<CustomerAccount>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdCustomersCustomerIdAccountsPut.');
        }

        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdCustomersCustomerIdAccountsPut.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdCustomersCustomerIdAccountsPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json; v=1.5';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/invoices/{invoiceId}/customers/{customerId}/accounts`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"invoiceId"}}`, encodeURIComponent(String(requestParameters.invoiceId))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InvoiceCustomerAccountsRequestToJSON(requestParameters.invoiceCustomerAccountsRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerAccountFromJSON(jsonValue));
    }

    /**
     * Inserts or updates a customer account
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdCustomersCustomerIdAccountsPut(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdCustomersCustomerIdAccountsPutRequest): Promise<CustomerAccount> {
        const response = await this.clientCacheSessionsSessionIdInvoicesInvoiceIdCustomersCustomerIdAccountsPutRaw(requestParameters);
        return await response.value();
    }

}

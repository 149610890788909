import {Invoice, InvoiceItem, InvoicePayment} from '@emporos/api-enterprise';
import {Dispatch, SetStateAction} from 'react';
import {
  InvoiceUpdates,
  mapInvoice,
  OfflineInvoice,
  Session,
  useTransactionsState,
} from '../';
import {filterDeletedInvoiceChildren} from './';

export type UpdateInvoiceFn = (
  updates: InvoiceUpdates,
  isCompleted?: boolean,
) => void;
export type UpdateInvoiceItemFn = (
  item: InvoiceItem,
  updates: Partial<InvoiceItem>,
) => void;

export const useInvoice = (): {
  invoice: Invoice;
  processedPayments: InvoicePayment[];
  voidedPayments: InvoicePayment[];
  pendingPayments: InvoicePayment[];
  updateInvoice: UpdateInvoiceFn;
  updateInvoiceItem: UpdateInvoiceItemFn;
  canDelete: boolean;
} => {
  const {session, setSession, currentInvoiceId} = useTransactionsState();

  const invoice =
    session.invoices.find(i => i.invoiceId === currentInvoiceId) ||
    mapInvoice(session.sessionId, session.siteId);

  const _updateInvoice = async (
    updates: InvoiceUpdates,
    isCompleted?: boolean,
  ) => {
    setSession(prevSession => ({
      ...prevSession,
      triggerSync: true,
      invoices: prevSession.invoices.map(iv => ({
        ...iv,
        ...(iv.invoiceId === invoice.invoiceId && {
          isSynced: true, //Todo return from save session a true or false if the sync happend ok
          isCompleted: isCompleted,
          ...(typeof updates === 'function' ? updates(iv) : updates),
        }),
      })),
    }));
    //Todo find the correct place to call the update session once the invoce is updated
    console.log('currentInvoiceId', currentInvoiceId);
  };

  const _updateInvoiceItem = (
    item: InvoiceItem,
    updates: Partial<InvoiceItem>,
  ) => {
    updateInvoiceItem(setSession, invoice, item, updates);
  };

  const invoiceWithoutDeleted = filterDeletedInvoiceChildren(
    invoice as OfflineInvoice,
  );
  const processedPayments = filterPaymentsByRecordStatus(
    invoiceWithoutDeleted,
    'Active',
  );
  const voidedPayments = filterPaymentsByRecordStatus(
    invoiceWithoutDeleted,
    'Voided',
  );
  const pendingPayments = [
    ...filterPaymentsByRecordStatus(invoiceWithoutDeleted, 'Pending'),
    ...filterPaymentsByRecordStatus(invoiceWithoutDeleted, 'OverCharged'),
  ];
  const canDelete = processedPayments.concat(pendingPayments).length === 0;

  return {
    invoice: invoiceWithoutDeleted,
    processedPayments,
    voidedPayments,
    pendingPayments,
    updateInvoice: _updateInvoice,
    updateInvoiceItem: _updateInvoiceItem,
    canDelete,
  };
};

function updateInvoiceItem(
  setSession: Dispatch<SetStateAction<Session>>,
  invoice: Invoice,
  invoiceItem: InvoiceItem,
  updates: Partial<InvoiceItem>,
): void {
  setSession(prevSession => ({
    ...prevSession,
    triggerSync: true,
    invoices: prevSession.invoices.map(iv =>
      iv.invoiceId === invoice.invoiceId
        ? {
            ...iv,
            items: iv.items.map(i =>
              i.invoiceItemId === invoiceItem?.invoiceItemId
                ? {...i, isSynced: true, ...updates}
                : i,
            ),
          }
        : iv,
    ),
  }));
}

const filterPaymentsByRecordStatus = (
  invoice: Invoice,
  rs: string,
): InvoicePayment[] =>
  invoice.payments.filter(({recordStatus}) => recordStatus === rs);

import {
  Customer,
  Customer as InvoiceCustomer,
  Invoice,
  Prescription,
} from '@emporos/api-enterprise';
import {
  Card,
  Gutter,
  Illustration,
  RowItem,
  RowType,
  ScrollContainer,
  Stack,
  Text,
  TextVariant as TV,
} from '@emporos/components';
import assert from 'assert';
import {useEffect, useState} from 'react';
import {
  buildCustomerCardProps,
  formatMedication,
  normalizeCustomer,
  prescriptionIcons,
} from '../../../';

interface WillCallProps {
  invoice: Invoice;
  customerInfo: InvoiceCustomer | Customer | undefined;
  loadingCustomer?: boolean;
  prescriptions: Prescription[] | undefined;
  selectedRX: Array<string>;
  viewOnly?: boolean;
  handleAddCustomerClick?: () => void;
  setSelectedPrescriptions: (prescriptionItems: Array<string>) => void;
  online: boolean;
}
const READY_0 = 0;
const READY_7 = 7;

const NOT_READY_2 = 2;
const NOT_READY_6 = 6;

const UNAVAILABLE_5 = 5;

export function WillCallPanel({
  invoice,
  customerInfo,
  loadingCustomer = false,
  prescriptions,
  selectedRX,
  viewOnly = false,
  handleAddCustomerClick,
  setSelectedPrescriptions,
  online,
}: WillCallProps): JSX.Element {
  const [preSelectedRX, setPreSelections] = useState<string[]>(selectedRX);
  const [selectionMade, setSelection] = useState<boolean>(false);

  useEffect(() => {
    // Effect is needed for cases when selectedRX comes in asynchronously
    if (selectedRX.length && !preSelectedRX?.length && !selectionMade) {
      setPreSelections(selectedRX);
    }
  }, [selectedRX, selectionMade]);

  const headers: Array<number> = prescriptions
    ? prescriptions
        .map(rx => rx.medicationStatus)
        .filter((h, i, arr) => arr.indexOf(h) === i)
        .sort()
    : [];
  const illustrationType = !customerInfo ? 'AddToGroup' : 'RxProduct';

  const getWillCallPanelText = () => {
    if (!customerInfo && viewOnly) return 'Add customer';
    if (!customerInfo) return 'Add customer to see Will Call';
    return 'No dispensed Rx';
  };

  const rxDisplay = (headerVal: number, variant: RowType) => {
    return (
      prescriptions &&
      prescriptions
        .filter(prescription => prescription.medicationStatus === headerVal)
        .map((p: Prescription, i: number) => {
          assert(p.itemNumber, 'Missing itemNumber');
          const itemExists = invoice.items.some(
            ({itemNumber}) => itemNumber === p.itemNumber,
          );
          return headerVal === READY_0 || headerVal === READY_7 ? (
            <RowItem
              key={i}
              variant={variant}
              title={formatMedication(p.description || '')}
              subtitle={p.receiptDescription || ''}
              rightText={`$${p.price.toFixed(2)}`}
              rightIcons={prescriptionIcons(p)}
              selected={selectedRX.indexOf(p.itemNumber) !== -1 || itemExists}
              inactive={itemExists || viewOnly}
              onClick={() => {
                assert(p.itemNumber, 'Missing itemNumber');
                if (selectedRX.indexOf(p.itemNumber) !== -1) {
                  setSelectedPrescriptions(
                    selectedRX.filter(sp => sp !== p.itemNumber),
                  );
                } else {
                  setSelection(true);
                  setSelectedPrescriptions([...selectedRX, p.itemNumber]);
                }
              }}
            />
          ) : (
            <RowItem
              inactive={viewOnly}
              variant={variant}
              title={formatMedication(p.description || '')}
              subtitle={p.receiptDescription || ''}
              rightText={`$${p.price.toFixed(2)}`}
              rightIcons={prescriptionIcons(p)}
              key={i}
            />
          );
        })
    );
  };

  return (
    <ScrollContainer style={{paddingBottom: 16}}>
      <Stack style={{height: '100%'}}>
        <Card
          style={{marginTop: 8}}
          onAddClick={handleAddCustomerClick}
          loading={loadingCustomer}
          disabled={!online}
          customerInfo={buildCustomerCardProps(
            customerInfo ? normalizeCustomer(customerInfo) : undefined,
          )}
        />

        {headers.length === 0 ? (
          <Stack
            align="center"
            justify="center"
            gutter={Gutter.None}
            style={{flex: 1, marginTop: 16}}
          >
            <Illustration illustration={illustrationType} />
            <Text variant={TV.Main} align="center" data-testid="main-text">
              {getWillCallPanelText()}
            </Text>
          </Stack>
        ) : (
          headers.map(h => {
            // NOTE: According to the chart Zak sent, there are technically 3 variables
            // that determine status but MedicationStatus seems like the most relevant
            switch (h) {
              case READY_0:
              case READY_7: {
                return (
                  <Stack
                    gutter={Gutter.S}
                    style={{marginTop: 16, flexShrink: 0}}
                    key={h}
                  >
                    <Text
                      variant={TV.Caption}
                      style={{marginBottom: '-4px', paddingLeft: 16}}
                    >
                      Ready For Customer
                    </Text>
                    {rxDisplay(h, 'generic')}
                  </Stack>
                );
              }
              case NOT_READY_2:
              case NOT_READY_6: {
                return (
                  <Stack
                    gutter={Gutter.S}
                    style={{marginTop: 16, flexShrink: 0}}
                    key={h}
                  >
                    <Text
                      variant={TV.Caption}
                      style={{marginBottom: '-4px', paddingLeft: 16}}
                    >
                      Not Ready
                    </Text>

                    {rxDisplay(h, 'not-ready')}
                  </Stack>
                );
              }
              case UNAVAILABLE_5: {
                return (
                  <Stack
                    gutter={Gutter.S}
                    style={{marginTop: 16, flexShrink: 0}}
                    key={h}
                  >
                    <Text
                      variant={TV.Caption}
                      style={{marginBottom: '-4px', paddingLeft: 16}}
                    >
                      Out for Delivery
                    </Text>

                    {rxDisplay(h, 'unavailable')}
                  </Stack>
                );
              }
              default:
                return null;
            }
          })
        )}
      </Stack>
    </ScrollContainer>
  );
}

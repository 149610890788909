import {CashDrawer, Site, Station} from '@emporos/api-enterprise';
import {Page, PageWrapper, PortraitNotSupported} from '@emporos/components';
import {Navbar} from '@emporos/components-pos';
import {RouteComponentProps} from '@reach/router';
import assert from 'assert';
import {memo, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {
  AuthClaim,
  useAlertState,
  useAuthentication,
  useSyncSession,
  useTransactionsConfig,
} from '../../../';
import {useApi} from '../../../contexts/ApiProvider';
import {
  CreateSession,
  isSessionConfigCompleted,
  SessionConfig,
  SessionSidebar,
} from './';

const MainWrapper = styled.div`
  display: flex;
  height: calc(100vh - 68px);
  height: calc(var(--vh, 1vh) * 100 - 68px);
`;

const CreateSessionChildContainer = (_props: RouteComponentProps) => {
  const api = useApi();
  const {user, logout} = useAuthentication();
  const {sessionStatus, syncSession} = useSyncSession();
  const {createSession, createSessionLoading, sites} = useTransactionsConfig();

  const {notification} = useAlertState();
  const [config, setConfig] = useState<SessionConfig>({
    site: null,
    station: null,
    paymentDeviceAddress: null,
    paymentDevicePort: null,
    till: null,
    tillStartingAmount: null,
  });
  const [stations, setStations] = useState<Station[]>([]);
  const [tills, setTills] = useState<CashDrawer[]>([]);
  const {run: getStations} = api.GetStations();
  const {run: runApiFetchTills} = api.GetTills();

  const onConfirm = useCallback(async () => {
    if (!isSessionConfigCompleted(config)) {
      return;
    }
    const {
      site,
      station,
      till,
      paymentDeviceAddress,
      paymentDevicePort,
      tillStartingAmount,
    } = config;
    //console.log(paymentDevice);
    return createSession(
      site,
      station,
      till,
      tillStartingAmount,
      paymentDeviceAddress,
      paymentDevicePort,
    );
  }, [config]);

  const fetchSiteDependencies = async (site: Site) => {
    const {data: getStation} = await getStations({
      siteId: site.siteId,
    });
    const {data: getTills} = await runApiFetchTills({
      siteId: site.siteId,
    });

    if (!(getStation && getTills)) {
      notification({
        type: 'warning',
        icon: 'Warning',
        title: 'Failed To Load Site Info',
        description:
          'Something went wrong while fetching site details. Please check your internet connection and try reloading the app.',
      });
      return;
    }
    setStations(getStation);
    const emptyTillArray: CashDrawer[] = [];
    setTills(
      getTills?.filter(x => x.drawerType === 'Mobile') ?? emptyTillArray,
    );
    setConfig({
      ...config,
      site,
      station: null,
      till: null,
      paymentDeviceAddress: null,
      paymentDevicePort: null,
      tillStartingAmount: null,
    });
    console.log('config in container', config);
  };

  useEffect(() => {
    if (config.site) {
      fetchSiteDependencies(config.site);
    }
  }, [config.site]);

  assert(
    user !== null,
    'Internal Error: tried to render CreateSession without an authenticated user',
  );

  return (
    <>
      <div className="layout-landscape">
        <Navbar
          username={user.profile[AuthClaim.Name]}
          onSessionStatus={syncSession}
          sessionStatus={sessionStatus}
          isLoadingData={false}
          onLogout={logout}
          hideSessionStatus={true}
        />
        <MainWrapper>
          <SessionSidebar
            fullName={user.profile[AuthClaim.Name]}
            site={config.site}
            station={config.station}
            till={config.till}
            paymentDeviceAddress={config.paymentDeviceAddress}
          />
          <PageWrapper style={{backgroundColor: 'transparent'}}>
            <Page level={1}>
              <CreateSession
                loading={createSessionLoading}
                sites={sites}
                stations={stations}
                config={config}
                onChange={setConfig}
                tills={tills}
                onLogout={logout}
                onConfirm={onConfirm}
              />
            </Page>
          </PageWrapper>
        </MainWrapper>
      </div>
      <div className="layout-portrait">
        <PortraitNotSupported></PortraitNotSupported>
      </div>
    </>
  );
};

export const CreateSessionContainer = memo(CreateSessionChildContainer);

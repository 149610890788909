import {Container, createContainer} from 'brandi';
import {useInjection} from 'brandi-react';
import {
  IInvoiceDatabaseAccess,
  IInvoicePaymentsDatabaseAccess,
} from './localDb/IDatabaseAccess';
import {InvoicePaymentLocaldb} from './localDb/InvoicePaymentLocaldb';
import {InvoiceLocaldb} from './localDb/InvoicesLocaldb';
import {IInvoiceService} from './services/IInvoiceService';
import {InvoiceService} from './services/InvoiceService';
import {TOKENS} from './tokens';

export class DIFactory {
  static buildDefaultContainer = (): Container => {
    const container = createContainer();

    container
      .bind(TOKENS.InvoiceService)
      .toInstance(InvoiceService)
      .inTransientScope();

    container
      .bind(TOKENS.InvoiceDatabaseAccess)
      .toInstance(InvoiceLocaldb)
      .inTransientScope();

    container
      .bind(TOKENS.InvoicePaymentsDatabaseAccess)
      .toInstance(InvoicePaymentLocaldb)
      .inTransientScope();

    return container;
  };

  static getInvoiceService = (
    sessionId: string,
    token: string,
  ): IInvoiceService => {
    const svc = useInjection(TOKENS.InvoiceService);
    svc.initialize(sessionId, token);
    return svc;
  };

  static getInvoiceDatabaseAccess = (
    sessionId: string,
  ): IInvoiceDatabaseAccess => {
    const axs = useInjection(TOKENS.InvoiceDatabaseAccess);
    axs.initialize(sessionId);
    return axs;
  };

  static getInvoicePaymentsDatabaseAccess = (
    sessionId: string,
  ): IInvoicePaymentsDatabaseAccess => {
    const axs = useInjection(TOKENS.InvoicePaymentsDatabaseAccess);
    axs.initialize(sessionId);
    return axs;
  };
}

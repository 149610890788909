import {createContext, ReactNode, useContext, useState} from 'react';

export declare type SessionStatus = 'success' | 'unsynced';

interface SyncSessionContextProps {
  syncing: boolean;
  syncSession: () => void;
  sessionStatus: SessionStatus;
  setSyncing: (bool: boolean) => void;
}

export const SyncSessionContext = createContext<SyncSessionContextProps>({
  syncing: false,
  syncSession: async () => console.log(),
  sessionStatus: 'success',
  setSyncing: () => null,
});

export function SyncSessionProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  // obviously this context is not really functional at this time but it is weaved into the fabric of the project already so it has been left for future use
  // this provider is NOT defined in App.tsx at this time so it only uses the defaults defined in the SyncSessionContext
  // when implemented in the future, logic will need to be added here, as well as state setters, and it will need to be added to App.tsx in the appropriate hierarchical location
  const [syncing] = useState(false);
  const [sessionStatus] = useState<SessionStatus>('success');

  const syncSession = async () => console.log();
  const setSyncing = () => null;

  return (
    <SyncSessionContext.Provider
      value={{
        syncing,
        syncSession,
        sessionStatus,
        setSyncing,
      }}
    >
      {children}
    </SyncSessionContext.Provider>
  );
}

export const useSyncSession = (): SyncSessionContextProps =>
  useContext(SyncSessionContext);

import {CayanTransactionResponse} from './CayanTypes';
import {
  VantivSalesResponse,
  VantivSalesTransaction,
  VantivReversalRequest,
} from './VantivTypes';

export type Logger = typeof console['log'];

type PaymentType = 'MasterCard' | 'Visa' | string;
export interface CayanVoidProxyResponse {
  data: {
    authorizationCode: 'string';
    status:
      | 'Unknown'
      | 'Approved'
      | 'Declined'
      | 'Cancelled'
      | 'NotAccessible'
      | 'Retry';
    referenceNumber: string;
    messages: Array<string>;
  };
}

export interface CayanVaultProxyResponse {
  data: {
    status: 'Unknown' | 'Approved' | 'Declined';
    amountApproved: 0;
    paymentType: PaymentType;
    accountNumber: string;
    message: string;
    authorizationCode: string;
    referenceNumber: string;
  };
}
export interface CayanConfig {
  // common, for integration
  gatewayUsername: string;
  gatewayId: string;
  gatewayPassword: string;
  // mapped to cayan ip
  gatewayTerminal: string;
  // mapped to terminalId
  processorLaneId: string;

  // for our client device implementation. should we use https or http?
  useSecureProtocol?: boolean;
  // for our API proxy
  accessToken: string;

  // common, for our code
  appName: string;
  appVersion: string;
  siteId: string;

  fetchTimeoutSeconds: number;
}

export interface VantivConfig {
  // common, for integration
  gatewayUsername: string;
  gatewayId: string;
  gatewayPassword: string;
  // mapped to terminal id
  gatewayTerminal: string;

  // common, for our code
  appName: string;
  appVersion: string;
  siteId: string;

  partnerId: string;
  processorLaneId: number;
}

export interface Device {
  requestTransaction(t: TransactionRequest): Promise<TransactionResponse>;
  requestTransactionDetails(
    t: TransactionDetailsRequest,
  ): Promise<TransactionResponse>;
  requestVaultTransaction(
    t: TransactionVaultRequest,
  ): Promise<TransactionResponse>;
  cancelTransaction(): Promise<'Okay'>;
  voidTransaction(request: VoidRequest): Promise<VoidResponse>;

  // we use this hook to expose internals for testing purposes. Since this is
  // only for testing we provide no type guarantees around it
  __TEST_UTILS__?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getClient: () => any;
  };
}

export interface TransactionRequest {
  clerkId: string;
  orderId: string;
  amount: number;
  taxAmount: number;
  qhpSubtotal: number;
}

export interface TransactionDetailsRequest {
  orderId: string;
}
export interface TransactionVaultRequest {
  vaultToken: string;
  clerkId: string;
  orderId: string;
  amount: number;
  taxAmount: number;
  qhpSubtotal: number;
}

export interface VoidRequest {
  clerkId: string;
  orderId: string;
  amount: number;
  transactionId: string;
}

const CreditSaleSignaturePrompt = {
  Always: 0,
  Never: 1,
  UseThreshold: 2,
  None: -1,
};

export function transformTransactionToVantiv(
  r: TransactionRequest,
  c: VantivConfig,
): VantivSalesTransaction {
  return {
    clerkNumber: r.clerkId,
    // 'TransactionId',
    referenceNumber: r.orderId,
    // TODO: in enterprise this is stationNumber + transactionId
    ticketNumber: r.orderId,
    transactionAmount: r.amount,
    salesTaxAmount: r.taxAmount,
    laneId: c.processorLaneId,
    shiftId: 'ShiftA',
    healthcare: {
      total: r.qhpSubtotal,
    },
    configuration: {
      allowPartialApprovals: true,
      checkForDuplicateTransactions: true,
      marketCode: 'Retail', // the only instance of not Retail in the API is for testing
      allowDebit: true,
      promptForSignature: CreditSaleSignaturePrompt.Never.toString(),
    },
  };
}

export function transformVoidToVantiv(
  r: VoidRequest,
  c: VantivConfig,
): VantivReversalRequest {
  return {
    cardHolderPresentCode: 'Default',
    laneId: c.processorLaneId,
    transactionAmount: r.amount,
    // transactionId: r.transactionId, this is required, but part of the Path
    referenceNumber: r.transactionId, // ReferenceNumber
    shiftId: 'ShiftA',
    clerkNumber: r.clerkId,
    ticketNumber: 'TODO: PurchaseOrderNumber',
    configuration: {
      marketCode: 'Retail',
    },
  };
}

type Status =
  | 'APPROVED'
  | 'DECLINED'
  | 'ERROR'
  | 'FAILED'
  | 'CANCELLED'
  | 'OVER_CHARGE'
  // Vantiv codes
  | 'HOSTERROR'
  | 'DUPLICATE'
  | 'PARTIAL APPROVED'
  | 'EXPIRED CARD'
  | 'DECLINED - PICK UP CARD'
  | 'CALL ISSUER'
  | 'NOT DEFINED'
  | 'INVALID DATA'
  | 'INVALID ACCOUNT'
  | 'INVALID REQUEST'
  | 'AUTHENTICATION FAILED'
  | 'OUT OF BALANCE'
  | 'NOT AUTHORIZED';

// accountNumber and transactionId can be undefined in error scenarios, but we
// are keeping our types as more restrictive for the moment.
export interface TransactionResponse {
  amountApproved: string | number;
  accountNumber: string;
  entryMode: // Cayan
  | 'PROXIMITY'
    | 'CARDREADER'
    // Vantiv
    | 'Swiped'
    | 'VAULT'
    | string;
  paymentType: 'MasterCard' | 'Visa' | string;
  status: Status;
  transactionDate: string;
  transactionId: string;
  rawResponse: string;
}
export interface VoidResponse {
  approvalNumber: string;
  isApproved: boolean;
  totalAmount: number;
  transactionId: string;
  balanceAmount: number;
}
export interface VantivVoidResponse {
  accountNumber: string;
  approvalNumber: string;
  balanceAmount: number;
  cardLogo: string;
  convenienceFeeAmount: number;
  isApproved: boolean;
  isOffline: boolean;
  merchantId: string;
  paymentType: string;
  statusCode: string;
  terminalId: string;
  tokenId: string;
  tokenProvider: string;
  totalAmount: number;
  transactionDateTime: string;
  transactionId: string;
}

export function transformCayanToTransactionResponse(
  r: CayanTransactionResponse,
  t: TransactionRequest,
): TransactionResponse {
  return {
    amountApproved: r.AmountApproved,
    entryMode: r.EntryMode,
    accountNumber: r.AccountNumber,
    paymentType: r.PaymentType,
    // TODO: we don’t have an enum of these, so we need to reverse engineer them
    // into our common set
    status: (() => {
      switch (r.Status) {
        case 'DECLINED': {
          // Cayan doesn’t have as robust of response types as Vantiv, so we do
          // some duck punching for common failure cases we have detected.
          if (r.AuthorizationCode === 'Invalid_Expiration_Date') {
            return 'EXPIRED CARD';
          }
          if (/Insufficient Funds/.test(r.ErrorMessage)) {
            return 'OUT OF BALANCE';
          }
          return r.Status;
        }
        case 'DECLINED_DUPLICATE':
          return 'DUPLICATE';
        case 'POSCancelled':
        case 'UserCancelled':
          return 'CANCELLED';
        case 'APPROVED':
          if (Number(r.AmountApproved) < t.amount) {
            return 'PARTIAL APPROVED';
          } else if (Number(r.AmountApproved) > t.amount) {
            return 'OVER_CHARGE';
          } else {
            return r.Status;
          }
        default:
          return r.Status;
      }
    })(),
    transactionDate: r.TransactionDate,
    transactionId: r.Token,
    rawResponse: JSON.stringify(r),
  };
}

export function transformCayanVaultToTransactionResponse(
  r: CayanVaultProxyResponse['data'],
): TransactionResponse {
  return {
    amountApproved: r.amountApproved,
    entryMode: 'Vault' as const,
    accountNumber: r.accountNumber,
    paymentType: r.paymentType,
    rawResponse: JSON.stringify(r),
    status: (() => {
      // this is only until Tom implements real status responses and documents
      // them
      if (r.status === 'Unknown') {
        if (r.amountApproved) {
          return 'APPROVED';
        }
      } else if (r.status === 'Declined') {
        // Cayan doesn’t have as robust of response types as Vantiv, so we do
        // some duck punching for common failure cases we have detected.
        if (r.authorizationCode === 'Invalid_Expiration_Date') {
          return 'EXPIRED CARD';
        }
        /* TODO: force this error case in the API and see how the details call
           * handles it.
          if (/Insufficient Funds/.test(r.ErrorMessage)) {
            return 'OUT OF BALANCE';
          }
          */
        return 'DECLINED';
        /* TODO: force this case in the API and test how the details call
           * responds with partially approved Cayan responses
        case 'APPROVED':
          if (Number(r.AmountApproved) < t.amount) {
            return 'PARTIAL APPROVED';
          } else {
            return r.Status;
          }
          */
      }

      return r.status.toUpperCase() as Status;
    })(),
    transactionDate: new Date().toISOString(),
    transactionId: r.referenceNumber,
  };
}
export function transformCayanToVoidResponse(
  r: CayanVoidProxyResponse,
): VoidResponse {
  return {
    approvalNumber: r.data.authorizationCode,
    isApproved: r.data.status === 'Approved',
    // unused by cayan
    totalAmount: 0,
    transactionId: r.data.referenceNumber,
    // unused by cayan
    balanceAmount: 0,
  };
}

export function transformVantivToVoidResponse(
  r: VantivVoidResponse,
): VoidResponse {
  return {
    approvalNumber: r.approvalNumber,
    isApproved: r.isApproved,
    totalAmount: r.totalAmount,
    transactionId: r.transactionId,
    balanceAmount: r.balanceAmount,
  };
}
export function transformVantivToTransactionResponse(
  r: VantivSalesResponse,
): TransactionResponse {
  const status = (r._processor.hasOwnProperty('processorResponseMessage')
    ? r._processor.processorResponseMessage.toUpperCase()
    : r.statusCode.toUpperCase()) as Status;
  return {
    amountApproved: r.approvedAmount,
    entryMode: r.entryMode, // 'Swiped' | [...]
    accountNumber: r.accountNumber,
    paymentType: r.paymentType,
    status: status,
    transactionDate: r.transactionDateTime,
    transactionId: r.transactionId,
    rawResponse: JSON.stringify(r),
  };
}

import {InvoiceExtra, InvoiceExtraRequest} from '@emporos/api-enterprise';

export function ConvertInvoiceExtrasToRequests(
  extras: InvoiceExtra[],
): InvoiceExtraRequest[] {
  const requests: InvoiceExtraRequest[] = [];
  extras.forEach(extra => {
    requests.push(ConvertInvoiceExtraToRequest(extra));
  });
  return requests;
}

export function ConvertInvoiceExtraToRequest(
  extra: InvoiceExtra,
): InvoiceExtraRequest {
  return {
    rowId: extra.rowId,
    dataKey: extra.dataKey ?? '',
    dataValue: extra.dataValue ?? '',
  };
}

import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';

export const DEFAULT_CREDIT_CARD_DEVICE_TIMOUT_REQUEST_SECONDS = 5 * 60;
export const DEFAULT_CREDIT_CARD_PENDING_PAYMENT_EXPIRATION_MINUTES = 5;

interface SettingsProps {
  creditCardDeviceRequestTimeoutSeconds: number;
  setCreditCardDeviceRequestTimeoutSeconds: Dispatch<SetStateAction<number>>;
  creditCardPendingPaymentExpirationMinutes: number;
  setCreditCardPendingPaymentExpirationMinutes: Dispatch<
    SetStateAction<number>
  >;
}
const SettingsContext = createContext<SettingsProps>({
  creditCardDeviceRequestTimeoutSeconds: DEFAULT_CREDIT_CARD_DEVICE_TIMOUT_REQUEST_SECONDS,
  setCreditCardDeviceRequestTimeoutSeconds: console.log,
  creditCardPendingPaymentExpirationMinutes: DEFAULT_CREDIT_CARD_PENDING_PAYMENT_EXPIRATION_MINUTES,
  setCreditCardPendingPaymentExpirationMinutes: console.log,
});

export function SettingsProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [
    creditCardDeviceRequestTimeoutSeconds,
    setCreditCardDeviceRequestTimeoutSeconds,
  ] = useState(DEFAULT_CREDIT_CARD_DEVICE_TIMOUT_REQUEST_SECONDS);
  const [
    creditCardPendingPaymentExpirationMinutes,
    setCreditCardPendingPaymentExpirationMinutes,
  ] = useState(DEFAULT_CREDIT_CARD_PENDING_PAYMENT_EXPIRATION_MINUTES);

  return (
    <SettingsContext.Provider
      value={{
        creditCardDeviceRequestTimeoutSeconds,
        creditCardPendingPaymentExpirationMinutes,
        setCreditCardDeviceRequestTimeoutSeconds,
        setCreditCardPendingPaymentExpirationMinutes,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}
export const useSettings = (): SettingsProps => useContext(SettingsContext);

import {Invoice} from '@emporos/api-enterprise';
import {orderBy} from 'lodash';

export interface IdCheckIndicators {
  isNplex: boolean;
  isControlledSubstance: boolean;
  isRequiredAge: boolean;
  age?: number;
}

export const mapIdCheckIndicators = ({items}: Invoice): IdCheckIndicators => ({
  isNplex: items.some(({itemTypeID, rx}) => !rx && itemTypeID === 16),
  isControlledSubstance: items.some(
    ({proofIDIndicator, rx}) => rx && proofIDIndicator,
  ),
  isRequiredAge: items.some(
    ({requiredAge, rx}) => !rx && (requiredAge || 0) > 0,
  ),
  age: orderBy(items, 'requiredAge', 'desc')[0]?.requiredAge || 0,
});

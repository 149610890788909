/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Invoice,
    InvoiceFromJSON,
    InvoiceToJSON,
    InvoiceRequest,
    InvoiceRequestFromJSON,
    InvoiceRequestToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
} from '../models';

export interface ClientCacheSessionsSessionIdInvoicesGetRequest {
    sessionId: string;
    includeChildren?: boolean;
}

export interface ClientCacheSessionsSessionIdInvoicesInvoiceIdCancelPatchRequest {
    sessionId: string;
    invoiceId: string;
}

export interface ClientCacheSessionsSessionIdInvoicesInvoiceIdCompletePatchRequest {
    sessionId: string;
    invoiceId: string;
}

export interface ClientCacheSessionsSessionIdInvoicesInvoiceIdGetRequest {
    sessionId: string;
    invoiceId: string;
    includeChildren?: boolean;
}

export interface ClientCacheSessionsSessionIdInvoicesPostRequest {
    sessionId: string;
    invoiceRequest?: InvoiceRequest;
}

export interface ClientCacheSessionsSessionIdInvoicesPutRequest {
    sessionId: string;
    invoiceRequest?: InvoiceRequest;
}

/**
 * 
 */
export class InvoicesApi extends runtime.BaseAPI {

    /**
     * Gets a list of invoices for a session
     */
    async clientCacheSessionsSessionIdInvoicesGetRaw(requestParameters: ClientCacheSessionsSessionIdInvoicesGetRequest): Promise<runtime.ApiResponse<Array<Invoice>>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdInvoicesGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.includeChildren !== undefined) {
            queryParameters['includeChildren'] = requestParameters.includeChildren;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/invoices`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InvoiceFromJSON));
    }

    /**
     * Gets a list of invoices for a session
     */
    async clientCacheSessionsSessionIdInvoicesGet(requestParameters: ClientCacheSessionsSessionIdInvoicesGetRequest): Promise<Array<Invoice>> {
        const response = await this.clientCacheSessionsSessionIdInvoicesGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Currently, OTC items and tax info is removed from the invoice, and then sent to Enterprise as ```Canceled```.  Enterprise records the transaction, marks it as ```Deleted```, and returns.  The invoice status then marked as ```Deleted```.
     * Cancels (Deletes) an invoice
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdCancelPatchRaw(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdCancelPatchRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdCancelPatch.');
        }

        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdCancelPatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/invoices/{invoiceId}/cancel`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"invoiceId"}}`, encodeURIComponent(String(requestParameters.invoiceId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Currently, OTC items and tax info is removed from the invoice, and then sent to Enterprise as ```Canceled```.  Enterprise records the transaction, marks it as ```Deleted```, and returns.  The invoice status then marked as ```Deleted```.
     * Cancels (Deletes) an invoice
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdCancelPatch(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdCancelPatchRequest): Promise<void> {
        await this.clientCacheSessionsSessionIdInvoicesInvoiceIdCancelPatchRaw(requestParameters);
    }

    /**
     * Currently, the invoice is posted as a completed transaction to Enterprise.  Changes made by Enterprise (statuses, etc.) are then returned and saved to the invoice itself, and the invoice status is set to ```Complete```.
     * Completes an invoice
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdCompletePatchRaw(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdCompletePatchRequest): Promise<runtime.ApiResponse<Invoice>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdCompletePatch.');
        }

        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdCompletePatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/invoices/{invoiceId}/complete`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"invoiceId"}}`, encodeURIComponent(String(requestParameters.invoiceId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceFromJSON(jsonValue));
    }

    /**
     * Currently, the invoice is posted as a completed transaction to Enterprise.  Changes made by Enterprise (statuses, etc.) are then returned and saved to the invoice itself, and the invoice status is set to ```Complete```.
     * Completes an invoice
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdCompletePatch(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdCompletePatchRequest): Promise<Invoice> {
        const response = await this.clientCacheSessionsSessionIdInvoicesInvoiceIdCompletePatchRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gets an invoice by session id and invoice id
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdGetRaw(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdGetRequest): Promise<runtime.ApiResponse<Invoice>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdGet.');
        }

        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.includeChildren !== undefined) {
            queryParameters['includeChildren'] = requestParameters.includeChildren;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/invoices/{invoiceId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"invoiceId"}}`, encodeURIComponent(String(requestParameters.invoiceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceFromJSON(jsonValue));
    }

    /**
     * Gets an invoice by session id and invoice id
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdGet(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdGetRequest): Promise<Invoice> {
        const response = await this.clientCacheSessionsSessionIdInvoicesInvoiceIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Inserts an invoice
     */
    async clientCacheSessionsSessionIdInvoicesPostRaw(requestParameters: ClientCacheSessionsSessionIdInvoicesPostRequest): Promise<runtime.ApiResponse<Invoice>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdInvoicesPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json; v=1.5';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/invoices`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InvoiceRequestToJSON(requestParameters.invoiceRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceFromJSON(jsonValue));
    }

    /**
     * Inserts an invoice
     */
    async clientCacheSessionsSessionIdInvoicesPost(requestParameters: ClientCacheSessionsSessionIdInvoicesPostRequest): Promise<Invoice> {
        const response = await this.clientCacheSessionsSessionIdInvoicesPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates an invoice
     */
    async clientCacheSessionsSessionIdInvoicesPutRaw(requestParameters: ClientCacheSessionsSessionIdInvoicesPutRequest): Promise<runtime.ApiResponse<Invoice>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdInvoicesPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json; v=1.5';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/invoices`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InvoiceRequestToJSON(requestParameters.invoiceRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceFromJSON(jsonValue));
    }

    /**
     * Updates an invoice
     */
    async clientCacheSessionsSessionIdInvoicesPut(requestParameters: ClientCacheSessionsSessionIdInvoicesPutRequest): Promise<Invoice> {
        const response = await this.clientCacheSessionsSessionIdInvoicesPutRaw(requestParameters);
        return await response.value();
    }

}

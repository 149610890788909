import {useEffect} from 'react';
import {useSyncSession} from '.';

export function SyncWarnings({children}: {children: JSX.Element}): JSX.Element {
  const {sessionStatus} = useSyncSession();

  if (window) {
    useEffect(() => {
      window.onbeforeunload = function (e) {
        e.preventDefault();
        return 'Alert'; // Browser sets message
      };
    }, [sessionStatus]);
  }
  return children;
}

import Dexie, {Table} from 'dexie';
import {InvoiceConsolidate, InvoicePaymentConsolidate} from '../api';

export class HiloDb extends Dexie {
  public InvoiceLocaldb!: Table<InvoiceConsolidate>;
  public InvoicePaymetLocaldb!: Table<InvoicePaymentConsolidate>;

  constructor(sessionId: string) {
    super('HiloDb');

    const view = new Uint8Array(32);

    view[0] = sessionId.toString().charCodeAt(0);

    this.version(1).stores({
      InvoiceLocaldb:
        'invoiceId, sessionId, recordStatus, status, serverTransactionID, isSynced, isDeleted',
      InvoicePaymetLocaldb:
        'invoicePaymentId,invoiceId,invoicePaymentStatus,recordStatus',
    });
  }
}

export const deleteDatabase = async (sessionId: string): Promise<void> => {
  const db = new HiloDb(sessionId);
  await db.delete();
};

export const getDatabase = async (sessionId: string): Promise<HiloDb> => {
  const db = new HiloDb(sessionId);
  await db.open();
  return db;
};

import {
  Customer,
  Customer as InvoiceCustomer,
  CustomerAccount as InvoiceCustomerAccount,
  Invoice,
  Phone,
} from '@emporos/api-enterprise';
import {CustomerProps} from '@emporos/components';
import assert from 'assert';
import moment from 'moment';
import {formatNamePart, formatPhoneNumber} from './';

export type NormalizedCustomer = {
  id: number;
  code?: string | null;
  mrn?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  dob?: Date | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
  email?: string | null;
  phones: Array<Phone>;
};

export const formatCustomerName = (customer: InvoiceCustomer): string =>
  formatNamePart(`${customer?.firstName || ''} ${customer?.lastName || ''}`);

export const formatCustomerAddress = ({
  address1,
  address2,
  city,
  state,
  zipCode,
}: NormalizedCustomer): string => {
  const formattedAddress2 = address2 ? ` ${formatNamePart(address2)}` : '';
  const formattedZipCode = zipCode ? ` ${zipCode}` : '';
  return [
    `${formatNamePart(address1 || '')}${formattedAddress2}`,
    formatNamePart(city || ''),
    `${state}${formattedZipCode}`,
  ].join(', ');
};

export const buildCustomerCardProps = (
  normalizedCustomer?: NormalizedCustomer,
): CustomerProps => {
  const props: CustomerProps = {
    firstName: '',
    lastName: '',
  };
  if (normalizedCustomer) {
    const {dob, firstName, lastName, mrn, phones} = normalizedCustomer;

    props.address = formatCustomerAddress(normalizedCustomer);
    props.firstName = formatNamePart(firstName || '');
    props.lastName = formatNamePart(lastName || '');

    // We conditionally add the following props so we don't add keys with
    // undefined values for commonly missing data.
    //   e.g. { dateOfBirth: undefined, mrn: undefined }.

    if (dob) {
      props.dateOfBirth = moment
        .utc(normalizedCustomer.dob)
        .format('MM/DD/YYYY');
    }

    if (phones.length && phones[0].number) {
      props.phone = formatPhoneNumber(phones[0].number);
    }

    if (mrn) {
      props.mrn = mrn;
    }
  }

  return props;
};

export const normalizeCustomer = (
  customer: InvoiceCustomer | Customer,
): NormalizedCustomer => {
  return customer;
};

export const activeInvoiceCustomersIds = (
  pendingInvoices: Array<Invoice>,
): Array<number> =>
  pendingInvoices
    .filter(invoice => invoice.customer)
    .map(({customer}) => {
      assert(customer, 'Missing customer');
      return customer.id;
    });

export const ars = (customer: InvoiceCustomer): Array<InvoiceCustomerAccount> =>
  customer.accounts.filter(a => !isPD(a));

export const pds = (customer: InvoiceCustomer): Array<InvoiceCustomerAccount> =>
  customer.accounts.filter(a => isPD(a));

export const isPD = ({accountType}: InvoiceCustomerAccount): boolean =>
  accountType === 'Payroll Deduction';

/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InvoiceCustomerAccountsRequest
 */
export interface InvoiceCustomerAccountsRequest {
    /**
     * 
     * @type {number}
     * @memberof InvoiceCustomerAccountsRequest
     */
    customerId: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceCustomerAccountsRequest
     */
    accountTypeId: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCustomerAccountsRequest
     */
    dataVersion?: string | null;
}

export function InvoiceCustomerAccountsRequestFromJSON(json: any): InvoiceCustomerAccountsRequest {
    return InvoiceCustomerAccountsRequestFromJSONTyped(json, false);
}

export function InvoiceCustomerAccountsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceCustomerAccountsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerId': json['customerId'],
        'accountTypeId': json['accountTypeId'],
        'dataVersion': !exists(json, 'dataVersion') ? undefined : json['dataVersion'],
    };
}

export function InvoiceCustomerAccountsRequestToJSON(value?: InvoiceCustomerAccountsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerId': value.customerId,
        'accountTypeId': value.accountTypeId,
        'dataVersion': value.dataVersion,
    };
}



import {
  BarcodeCollectionApiResponse,
  OtcItemListApiResponse,
  PaymentOptionResultListApiResponse,
  PaymentTypeListApiResponse,
  RelationshipListApiResponse,
  SettingListApiResponse,
  SignatureType,
  TaxGroupListApiResponse,
} from '@emporos/api-enterprise';
import {navigate} from '@reach/router';
import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {useAuthentication, useSession, useTransactionsState} from '../';
import {useApi} from './ApiProvider';

type PMS = 'mckesson' | 'epic';

interface GlobalDataProviderProps {
  loading: boolean;
  isError: boolean;
  barcodesResult: BarcodeCollectionApiResponse | void;
  otcItemsResult: OtcItemListApiResponse | void;
  taxGroupsResult: TaxGroupListApiResponse | void;
  settingsResult: SettingListApiResponse | void;
  relationshipsResult: RelationshipListApiResponse | void;
  paymentTendersResult: PaymentTypeListApiResponse | void;
  paymentOptionsResult: PaymentOptionResultListApiResponse | void;
  signatureTypesResult: SignatureType[] | void;
  pmsName: PMS;
  barcodeName: string;
  thirdPartySiteNumber: string;
  skipWillCall: boolean;
  setSkipWillCall: (skipWillCall: boolean) => void;
}

// TODO temporary hardcoded
const THIRD_PARTY_SITE_NUMBER = '104';
export const DEFAULT_PMS = 'mckesson';

const GlobalDataContext = createContext<GlobalDataProviderProps>({
  loading: false,
  isError: false,
  barcodesResult: undefined,
  otcItemsResult: undefined,
  taxGroupsResult: undefined,
  settingsResult: undefined,
  relationshipsResult: undefined,
  paymentTendersResult: undefined,
  paymentOptionsResult: undefined,
  signatureTypesResult: undefined,
  pmsName: DEFAULT_PMS,
  barcodeName: '',
  thirdPartySiteNumber: THIRD_PARTY_SITE_NUMBER,
  skipWillCall: false,
  setSkipWillCall: () => null,
});

function GlobalDataProvider({children}: {children: ReactNode}): ReactElement {
  const {
    session,
    setCurrentInvoiceId,
    setSelectedPayment,
  } = useTransactionsState();
  const api = useApi();
  const {user} = useAuthentication();
  const sessionRef = useRef<string | null>(null);
  const {pendingInvoices} = useSession();
  const [starting, setStarting] = useState(true);
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [pmsName, setPmsName] = useState<PMS>(DEFAULT_PMS);
  const [barcodeName, setBarcodeName] = useState('');
  const [skipWillCall, setSkipWillCall] = useState(false);
  const {siteId, stationId} = session;
  const {
    loading: loadingBarcodes,
    error: errorBarcodes,
    data: barcodesResult,
  } = api.GetBarcodes([{}]);
  const {
    loading: loadingOtcItems,
    error: errorOtcItems,
    data: otcItemsResult,
  } = api.GetUTCItems([{siteId}]);
  const {
    loading: loadingTaxGroups,
    error: errorTaxGroups,
    data: taxGroupsResult,
  } = api.GetTaxGroups([{}]);
  const {
    loading: loadingSettings,
    error: errorSettings,
    data: settingsResult,
  } = api.GetSettings([
    {
      siteId,
      stationId,
    },
  ]);
  const {
    loading: loadingRelationships,
    error: errorRelationships,
    data: relationshipsResult,
  } = api.GetRelationships([{}]);
  const {
    loading: loadingPaymentTenders,
    error: errorPaymentTenders,
    data: paymentTendersResult,
  } = api.GetPaymentTenders([{}]);
  const {
    loading: loadingPaymentOptions,
    error: errorPaymentOptions,
    data: paymentOptionsResult,
  } = api.GetPaymentOptions([{siteId}]);
  const {
    loading: loadingSignatureTypes,
    error: errorSignatureTypes,
    data: signatureTypesResult,
  } = api.GetSignatureTypes([]);

  useEffect(() => {
    setLoading(
      loadingBarcodes ||
        loadingOtcItems ||
        loadingTaxGroups ||
        loadingSettings ||
        loadingRelationships ||
        loadingPaymentTenders ||
        loadingSignatureTypes ||
        loadingPaymentOptions,
    );
  }, [
    loadingBarcodes,
    loadingOtcItems,
    loadingTaxGroups,
    loadingSettings,
    loadingRelationships,
    loadingPaymentTenders,
    loadingPaymentOptions,
    loadingSignatureTypes,
  ]);

  useEffect(() => {
    setIsError(
      !!errorBarcodes ||
        !!errorOtcItems ||
        !!errorTaxGroups ||
        !!errorSettings ||
        !!errorRelationships ||
        !!errorPaymentTenders ||
        !!errorPaymentOptions ||
        !!errorSignatureTypes,
    );
  }, [
    errorBarcodes,
    errorOtcItems,
    errorTaxGroups,
    errorSettings,
    errorRelationships,
    errorPaymentTenders,
    errorPaymentOptions,
    errorSignatureTypes,
  ]);

  useEffect(() => {
    (async () => {
      if (!user) {
        return;
      }

      if (
        session &&
        sessionRef.current !== session.sessionId &&
        !loading &&
        !isError &&
        barcodesResult
      ) {
        sessionRef.current = session.sessionId;
        const invoiceWithPayment = pendingInvoices.find(
          ({payments}) => payments.length,
        );
        if (invoiceWithPayment) {
          setCurrentInvoiceId(invoiceWithPayment.invoiceId);
          const pendingPayment = invoiceWithPayment.payments.find(
            ({recordStatus}) =>
              ['OverCharged', 'Pending'].includes(recordStatus),
          );
          if (pendingPayment) {
            setSelectedPayment(pendingPayment.invoicePaymentId);
            await navigate(
              '/sales/transactions/payments/customer-payment/payment-info',
              {
                replace: true,
              },
            );
          } else if (invoiceWithPayment.signatures.length > 0) {
            await navigate('/sales/transactions/payments/receipts', {
              replace: true,
            });
          } else {
            await navigate('/sales/transactions/payments/customer-payment', {
              replace: true,
            });
          }
        }
        setStarting(false);
      }
    })();
  }, [user, session, sessionRef, loading, isError]);

  useEffect(() => {
    if (
      settingsResult &&
      settingsResult.data &&
      barcodesResult &&
      barcodesResult.data
    ) {
      const pms = settingsResult.data.find(({name}) => name === 'PMS');
      if (!pms) throw Error('Missing PMS setting');
      setPmsName(pms.value.toLowerCase() as PMS);

      const _barcodeName = barcodesResult.data.pmsBarcodeMappings.find(
        m => m.pmsName?.toLowerCase() === pms.value.toLowerCase(),
      )?.barcodeName;
      if (!_barcodeName) throw Error('Missing barcode name mapping');
      setBarcodeName(_barcodeName);
    }
  }, [settingsResult, barcodesResult]);

  return (
    <GlobalDataContext.Provider
      value={{
        loading: loading || starting,
        isError,
        barcodesResult,
        otcItemsResult,
        taxGroupsResult,
        settingsResult,
        relationshipsResult,
        paymentTendersResult,
        signatureTypesResult,
        paymentOptionsResult,
        pmsName,
        barcodeName,
        thirdPartySiteNumber: THIRD_PARTY_SITE_NUMBER,
        skipWillCall,
        setSkipWillCall,
      }}
    >
      {children}
    </GlobalDataContext.Provider>
  );
}
const useGlobalData = (): GlobalDataProviderProps =>
  useContext(GlobalDataContext);
export {GlobalDataProvider, useGlobalData};

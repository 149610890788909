import React, {memo, useMemo} from 'react';
import {animated, useTransition} from 'react-spring';
import styled from 'styled-components';
import {AlertBanner, AlertType, Icons, Row} from './';

interface AlertBannerStackProps {
  alerts: Array<NotificationAlert>;
  onDismiss: (id: string) => void;
}

export interface NotificationAlert {
  id: string;
  title: string;
  description: string;
  icon: keyof typeof Icons;
  type?: AlertType;
}

const AlertBannerWrapper = styled.div<{isEmpty: boolean}>`
  position: absolute;
  top: 24px;
  padding: ${({isEmpty}) => !isEmpty && '16px'};
  right: 0;
  width: 465px;
  z-index: 999;
`;

export const AlertBannerStack = memo(
  ({
    alerts,
    onDismiss,
    ...props
  }: AlertBannerStackProps &
    React.PropsWithoutRef<JSX.IntrinsicElements['div']>) => {
    const refMap = useMemo(() => new WeakMap(), []);

    const transitions = useTransition(alerts, {
      initial: {transform: 'translate3d(0%, 0%,0)'},
      from: {height: 0, transform: 'translate3d(100%,0,0)', opacity: 1},
      enter: alert => async next =>
        next({
          height: refMap.get(alert).offsetHeight + 16, // 16px is for the bottom margin between alerts
          transform: 'translate3d(0%, 0%,0)',
          opacity: 1,
        }),
      leave: {height: 0, transform: 'translate3d(100%,0%,0)', opacity: 0},
    });

    return (
      <AlertBannerWrapper
        data-testid="alert-stack"
        isEmpty={!alerts.length}
        {...props}
      >
        {transitions((style, alert, _t) => (
          <animated.div key={alert.id} style={style}>
            <Row
              style={{marginBottom: '16px'}}
              justify="end"
              ref={(ref: HTMLDivElement) => ref && refMap.set(alert, ref)}
            >
              <AlertBanner
                onDismiss={() => onDismiss(alert.id)}
                type={alert.type}
                title={alert.title}
                description={alert.description}
                icon={alert.icon}
              />
            </Row>
          </animated.div>
        ))}
      </AlertBannerWrapper>
    );
  },
);

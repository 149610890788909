import _theme from './figma-theme.json';
export const theme = _theme;
export * from './helpers';
export * from './hooks';
// The following exports must come before
// others (dependencies will break tests)
export * as Icons from './Icons';
export * as PaymentIcons from './IconsPayment';
export * from './Layout';
export * from './Text';
///////////////////////////////////////////////
export * from './AlertBadge';
export * from './AlertBanner';
export * from './AlertBannerStack';
export * from './AlertMessage';
export * from './Button';
export * from './ButtonFloating';
export * from './ButtonShape';
export * from './ButtonSquareText';
export * from './Card';
export * from './CardOnFile';
export * from './Carousel';
export * from './Divider';
export * from './FooterGroup';
export * from './Header';
export * from './Icon';
export * as Illustrations from './Illustrations';
export * from './Illustration';
export * from './IndicatorBadge';
export * from './IndicatorCircle';
export * from './IndicatorQuantity';
export * from './Intermission';
export * from './LayoutUtilities';
export * from './LogoHiloAnimated';
export * as LogosEmporos from './LogosEmporos';
export * as LogosHilo from './LogosHilo';
export * from './Modal';
export * from './NumberInput';
export * from './Numberpad';
export * from './Page';
export * from './PaymentCard';
export * from './PendingChangesModal';
export * from './PriceFooter';
export * from './RadioButtons';
export * from './RowItem';
export * from './RowSidebar';
export * from './SegmentSlider';
export * from './Select';
export * from './SelectCustom';
// TODO: Add Signature component once it can be re-written
// export * from './Signature';
export * from './TabBar';
export * from './TestUtils';
export * from './TextInput';
export * from './TextInputWithPlaceholder';
export * from './Toggle';
export * from './VerificationModal';
export * from './VirtualizedList';
export * from './PortraitNotSupported';

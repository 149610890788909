/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum InvoiceStatusEnum {
    Active = 'Active',
    Processing = 'Processing',
    Complete = 'Complete',
    Accepted = 'Accepted',
    Error = 'Error',
    Deleted = 'Deleted'
}

export function InvoiceStatusEnumFromJSON(json: any): InvoiceStatusEnum {
    return InvoiceStatusEnumFromJSONTyped(json, false);
}

export function InvoiceStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceStatusEnum {
    return json as InvoiceStatusEnum;
}

export function InvoiceStatusEnumToJSON(value?: InvoiceStatusEnum | null): any {
    return value as any;
}


import {Customer, InvoicePayment, PaymentType} from '@emporos/api-enterprise';
import {
  AllIcons,
  Card,
  Gutter,
  PriceFooter,
  RowItem,
  ScrollContainer,
  Stack,
  Text,
  TextVariant as Variant,
} from '@emporos/components';
import {navigate, RouteComponentProps} from '@reach/router';
import assert from 'assert';
import moment from 'moment';
import {Dispatch, memo, SetStateAction} from 'react';
import {
  AcceptedPaymentTypes,
  buildCustomerCardProps,
  displayPaymentNumber,
  getPaymentIcon,
  getPaymentType,
  TotalsResult,
  useAlertState,
  useCreditCardProcessing,
  useGlobalData,
  useInvoice,
  useNetworkAvailable,
  useSettings,
  useTotals,
  useTransactionsState,
} from '../../../../';

interface Props {
  customer?: Customer;
  processedPayments: InvoicePayment[];
  pendingPayments: InvoicePayment[];
  voidedPayments: InvoicePayment[];
  totals: TotalsResult;
  paymentTenders: PaymentType[];
  selectedPayment: string;
  creditCardPendingPaymentExpirationMinutes: number;
  loadingPendingInvoicePaymentId?: string;
  online: boolean;
  setSelectedPayment: Dispatch<SetStateAction<string>>;
  showOfflineAlert: () => void;
}

const PaymentsSidebarIntegrationComponent = (
  _: RouteComponentProps,
): JSX.Element => {
  const {notification} = useAlertState();
  const {paymentTendersResult} = useGlobalData();
  const online = useNetworkAvailable();
  const {selectedPayment, setSelectedPayment} = useTransactionsState();
  const {creditCardPendingPaymentExpirationMinutes} = useSettings();
  const {loadingPendingInvoicePaymentId} = useCreditCardProcessing();

  const {
    invoice,
    processedPayments,
    pendingPayments,
    voidedPayments,
  } = useInvoice();
  const {totals} = useTotals();

  assert(invoice, 'Missing invoice');
  assert(
    paymentTendersResult && paymentTendersResult.data,
    'Missing paymentTendersResult',
  );
  const {customer} = invoice;
  const paymentTenders = paymentTendersResult.data as PaymentType[];

  const showOfflineAlert = () =>
    notification({
      title: 'Cannot Return Payment',
      description:
        'Credit card payments cannot be returned while offline. Please try again when connectivity is established.',
      type: 'warning',
      icon: 'CreditCard',
    });

  return (
    <PaymentSidebar
      customer={customer}
      processedPayments={processedPayments}
      pendingPayments={pendingPayments}
      voidedPayments={voidedPayments}
      totals={totals}
      paymentTenders={paymentTenders}
      selectedPayment={selectedPayment}
      creditCardPendingPaymentExpirationMinutes={
        creditCardPendingPaymentExpirationMinutes
      }
      loadingPendingInvoicePaymentId={loadingPendingInvoicePaymentId}
      online={online}
      setSelectedPayment={setSelectedPayment}
      showOfflineAlert={showOfflineAlert}
    />
  );
};

export const PaymentSidebar = ({
  customer,
  processedPayments,
  pendingPayments,
  voidedPayments,
  totals,
  paymentTenders,
  selectedPayment,
  creditCardPendingPaymentExpirationMinutes,
  loadingPendingInvoicePaymentId,
  online,
  setSelectedPayment,
  showOfflineAlert,
}: Props): JSX.Element => {
  const customCardsProps = customer ? buildCustomerCardProps(customer) : {};
  const isPendingPaymentSelected = pendingPayments.some(
    ({invoicePaymentId}) => invoicePaymentId === selectedPayment,
  );

  const leftIcon = (
    recordStatus: string,
    paymentTypeID: number,
  ): keyof typeof AllIcons => {
    if (recordStatus === 'OverCharged') {
      return 'Warning';
    }
    return getPaymentIcon(paymentTypeID || 0, paymentTenders);
  };

  const getPathname = () => {
    return window.location.pathname;
  };

  const navigateToPayment = () => {
    const pathname = getPathname();

    if (pathname.includes('customer-payment')) {
      return navigate(
        `/sales/transactions/payments/customer-payment/payment-info`,
      );
    } else if (pathname.includes('acknowledgements')) {
      return navigate(
        `/sales/transactions/payments/acknowledgements/payment-info`,
      );
    } else if (pathname.includes('complete')) {
      return navigate(`/sales/transactions/payments/complete/payment-info`);
    }
  };

  const arePaymentsInactive = () => {
    const pathname = getPathname();
    return (
      pathname.includes('receipts') || pathname.includes('acknowledgements')
    );
  };

  const onClickPayment = (
    invoicePaymentId: string,
    paymentType: AcceptedPaymentTypes,
  ) => {
    if (isPendingPaymentSelected) {
      return;
    }
    if (!online && paymentType === 'Credit Card') {
      showOfflineAlert();
      return;
    }
    if (selectedPayment === invoicePaymentId) {
      setSelectedPayment('');
      return;
    }
    setSelectedPayment(invoicePaymentId);
    return navigateToPayment();
  };

  return (
    <>
      <Card
        size="small"
        style={{marginBottom: 16}}
        customerInfo={customCardsProps}
      />

      <ScrollContainer style={{padding: '0 4px 4px', margin: '0 -4px'}}>
        <Stack gutter={Gutter.S}>
          <Text
            variant={Variant.Caption}
            style={{paddingLeft: 16, paddingBottom: 6, marginBottom: '-12px'}}
          >
            Processed Payments
          </Text>

          {!!processedPayments.length ? (
            processedPayments.map(p => {
              const {amount, invoicePaymentId, recordStatus, paymentTypeID} = p;
              const paymentType = getPaymentType(
                paymentTypeID || 0,
                paymentTenders,
              );
              if (!p.paymentTypeDesc) {
                p.paymentTypeDesc = paymentTenders?.find(
                  x => x.id == p.paymentTypeID,
                )?.description;
              }
              return (
                <RowItem
                  key={invoicePaymentId}
                  selected={selectedPayment === invoicePaymentId}
                  onClick={() => onClickPayment(invoicePaymentId, paymentType)}
                  title={
                    paymentType == 'UDP'
                      ? p.paymentTypeDesc
                        ? p.paymentTypeDesc
                        : 'UDP'
                      : paymentType
                  }
                  subtitle={displayPaymentNumber(p, paymentTenders)}
                  variant="payment"
                  leftIcon={leftIcon(recordStatus, paymentTypeID || 0)}
                  rightText={`$${amount.toFixed(2)}`}
                  inactive={arePaymentsInactive()}
                />
              );
            })
          ) : (
            <RowItem title="No Payments" variant="inverted" />
          )}

          {!!pendingPayments.length && (
            <>
              <Text
                variant={Variant.Caption}
                style={{
                  paddingLeft: 16,
                  paddingBottom: 6,
                  marginBottom: '-12px',
                  marginTop: 16,
                }}
              >
                Pending Payments
              </Text>
              {pendingPayments.map((p: InvoicePayment) => {
                const {amount, invoicePaymentId} = p;
                const paymentType = 'Credit Card';
                return (
                  <RowItem
                    key={invoicePaymentId}
                    selected={selectedPayment === invoicePaymentId}
                    onClick={() =>
                      onClickPayment(invoicePaymentId, paymentType)
                    }
                    title={paymentType}
                    subtitle={`Expires: ${moment(p.createdOn)
                      .add(creditCardPendingPaymentExpirationMinutes, 'minutes')
                      .format('hh:mmA')}`}
                    variant="payment"
                    leftIcon="Warning"
                    leftIconLoading={
                      invoicePaymentId === loadingPendingInvoicePaymentId
                    }
                    rightText={`$${amount.toFixed(2)}`}
                  />
                );
              })}
            </>
          )}

          {!!voidedPayments.length && (
            <>
              <Text
                variant={Variant.Caption}
                style={{
                  paddingLeft: 16,
                  paddingBottom: 6,
                  marginBottom: '-12px',
                  marginTop: 16,
                }}
              >
                Returned Payments
              </Text>

              {voidedPayments.map(p => {
                const {
                  amount,
                  invoicePaymentId,
                  recordStatus,
                  paymentTypeID,
                } = p;
                const paymentType = 'Credit Card';
                return (
                  <RowItem
                    key={invoicePaymentId}
                    selected={selectedPayment === invoicePaymentId}
                    onClick={() =>
                      onClickPayment(invoicePaymentId, paymentType)
                    }
                    title={paymentType}
                    subtitle={displayPaymentNumber(p, paymentTenders)}
                    variant="payment"
                    leftIcon={leftIcon(recordStatus, paymentTypeID || 0)}
                    rightText={`-$${amount.toFixed(2)}`}
                  />
                );
              })}
            </>
          )}
        </Stack>
      </ScrollContainer>
      <PriceFooter totals={totals} size="small" />
    </>
  );
};

export const PaymentsSidebarIntegration = memo(
  PaymentsSidebarIntegrationComponent,
);

import {
  InvoiceIdentification,
  InvoiceIdentificationRequest,
} from '@emporos/api-enterprise';

export function ConvertInvoiceIdentificationsToRequests(
  ids: InvoiceIdentification[] | undefined,
): InvoiceIdentificationRequest[] | undefined {
  if (ids === undefined) return undefined;

  const requests: InvoiceIdentificationRequest[] = [];
  ids.forEach(id => {
    if (id !== undefined)
      requests.push(
        ConvertInvoiceIdentificationToRequest(
          id,
        ) as InvoiceIdentificationRequest,
      );
  });
  return requests;
}

export function ConvertInvoiceIdentificationToRequest(
  id: InvoiceIdentification | undefined,
): InvoiceIdentificationRequest | undefined {
  if (id === undefined) return undefined;

  return {
    invoiceIdentificationId: id.invoiceIdentificationId,
    idNumber: id.idNumber,
    firstName: id.firstName,
    middleName: id.middleName,
    lastName: id.lastName,
    dob: id.dob,
    gender: id.gender,
    address1: id.address1,
    address2: id.address2,
    city: id.city,
    state: id.state,
    zipCode: id.zipCode,
    idExpirationDate: id.idExpirationDate,
    notes: id.notes,
    idTypeID: id.idTypeID,
    idTypeCode: id.idTypeCode,
    relationshipId: id.relationshipId,
    relationship: id.relationship,
  };
}

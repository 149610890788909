/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InvoiceIdentification
 */
export interface InvoiceIdentification {
    /**
     * 
     * @type {string}
     * @memberof InvoiceIdentification
     */
    invoiceIdentificationId: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceIdentification
     */
    invoiceId: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceIdentification
     */
    idNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceIdentification
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceIdentification
     */
    middleName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceIdentification
     */
    lastName?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceIdentification
     */
    dob?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceIdentification
     */
    gender?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceIdentification
     */
    address1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceIdentification
     */
    address2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceIdentification
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceIdentification
     */
    state?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceIdentification
     */
    zipCode?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceIdentification
     */
    idExpirationDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceIdentification
     */
    notes?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InvoiceIdentification
     */
    idTypeID: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceIdentification
     */
    idTypeCode?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InvoiceIdentification
     */
    relationshipId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceIdentification
     */
    relationship?: string | null;
}

export function InvoiceIdentificationFromJSON(json: any): InvoiceIdentification {
    return InvoiceIdentificationFromJSONTyped(json, false);
}

export function InvoiceIdentificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceIdentification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'invoiceIdentificationId': json['invoiceIdentificationId'],
        'invoiceId': json['invoiceId'],
        'idNumber': !exists(json, 'idNumber') ? undefined : json['idNumber'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'middleName': !exists(json, 'middleName') ? undefined : json['middleName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'dob': !exists(json, 'dob') ? undefined : (json['dob'] === null ? null : new Date(json['dob'])),
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'address1': !exists(json, 'address1') ? undefined : json['address1'],
        'address2': !exists(json, 'address2') ? undefined : json['address2'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'idExpirationDate': !exists(json, 'idExpirationDate') ? undefined : (json['idExpirationDate'] === null ? null : new Date(json['idExpirationDate'])),
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'idTypeID': json['idTypeID'],
        'idTypeCode': !exists(json, 'idTypeCode') ? undefined : json['idTypeCode'],
        'relationshipId': !exists(json, 'relationshipId') ? undefined : json['relationshipId'],
        'relationship': !exists(json, 'relationship') ? undefined : json['relationship'],
    };
}

export function InvoiceIdentificationToJSON(value?: InvoiceIdentification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'invoiceIdentificationId': value.invoiceIdentificationId,
        'invoiceId': value.invoiceId,
        'idNumber': value.idNumber,
        'firstName': value.firstName,
        'middleName': value.middleName,
        'lastName': value.lastName,
        'dob': value.dob === undefined ? undefined : (value.dob === null ? null : value.dob.toISOString()),
        'gender': value.gender,
        'address1': value.address1,
        'address2': value.address2,
        'city': value.city,
        'state': value.state,
        'zipCode': value.zipCode,
        'idExpirationDate': value.idExpirationDate === undefined ? undefined : (value.idExpirationDate === null ? null : value.idExpirationDate.toISOString()),
        'notes': value.notes,
        'idTypeID': value.idTypeID,
        'idTypeCode': value.idTypeCode,
        'relationshipId': value.relationshipId,
        'relationship': value.relationship,
    };
}



import {toPartialMatchRegexp} from '../utils/regexp';

export type StringMatcherPattern = {
  /**
   * A regular expression that defines a pattern of chararcters input by a
   * barcode scanner.
   */
  pattern: RegExp;

  /**
   * The length of the substring (integer).
   */
  length: number;
};

export type StringMatcherOptions<T extends StringMatcherPattern> = {
  patterns: T[];
};

export type StringMatch<
  T extends StringMatcherPattern = StringMatcherPattern
> = {pattern: T; match: string};

/**
 * An object that detects sequences of strings with input using regular
 * expressions.
 */
export class StringMatcher<
  T extends StringMatcherPattern = StringMatcherPattern
> implements Iterator<StringMatch | null, StringMatch | null, string> {
  private patterns: T[] = [];
  private matches: StringMatch<T>[] = [];

  constructor(options: StringMatcherOptions<T>) {
    this.patterns = options.patterns;
    this.reset();
  }

  next(key: string): IteratorResult<StringMatch | null, StringMatch | null> {
    let result: IteratorResult<StringMatch | null, StringMatch | null> = {
      done: false,
      value: null,
    };

    for (let i = this.matches.length - 1; i >= 0; i--) {
      const match = this.matches[i];
      const existingMatchWithKey = `${match.match}${key}`;

      // If the recorded sequence is still a potential match, record it.
      if (match.pattern.pattern.test(existingMatchWithKey)) {
        match.match = existingMatchWithKey;

        if (match.match.length === match.pattern.length) {
          result = {done: true, value: match};
          break;
        }
      } else {
        // Not a potential match? Throw the match out.
        this.matches.splice(i, 1);
      }
    }

    if (this.matches.length <= 0) {
      result = {done: true, value: null};
    }

    if (result.done) {
      this.reset();
    }

    return result;
  }

  reset(): void {
    this.matches = this.patterns.map(pattern => ({
      pattern: {
        ...pattern,
        // Convert normal regex to partial match patterns, which let us treat
        // matches for patterns as "innocent until proven guilty".
        pattern: toPartialMatchRegexp(pattern.pattern),
      },
      match: '',
    }));
  }
}

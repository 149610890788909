import {RouteComponentProps} from '@reach/router';
import assert from 'assert';
import {memo} from 'react';
import {
  AnalyticType,
  Invoice,
  OfflineCustomer,
  OfflineSynced,
  useAnalyticsProvider,
  useInvoice,
  withChildPage,
} from '../../../../../';
import {Receipts} from './';

export interface EmailForm {
  email: string;
}

function calculateFirstFillPercent(invoice: Invoice) {
  const {items, customer} = invoice;
  const totalFirstFillRx = (customer as OfflineCustomer)?.prescriptions?.filter(
    // Checking solely on fill will not work once we are fully integrated with Epic
    ({fill}) => fill === '00',
  );
  const firstFillPurchased = items.filter(({fill, rx}) => rx && fill === '00');
  if (totalFirstFillRx?.length) {
    return (firstFillPurchased.length / totalFirstFillRx.length) * 100;
  }
}

function ReceiptsIntegrationComponent({navigate}: RouteComponentProps) {
  const {track} = useAnalyticsProvider();
  const {invoice, updateInvoice} = useInvoice();
  const {NODE_ENV} = process.env;

  assert(
    navigate,
    '<ReceiptsIntegration /> must have a `navigate` prop.' +
      String(NODE_ENV) ===
      'production'
      ? ''
      : ' This likely means that you need to have it as a direct child of a <Router />',
  );
  assert(invoice, 'Missing invoice');

  const trackReceiptType = (receiptType: string) =>
    track(AnalyticType.ReceiptType, {receiptType});

  const onNoReceipt = () => {
    trackReceiptType('none');
    completeInvoice({
      isCompleted: true,
      isSynced: true,
    } as Partial<Invoice> & OfflineSynced);
  };

  const onEmailReceipt = (data: EmailForm) => {
    trackReceiptType('email');
    completeInvoice({
      emailReceipt: data.email,
      isCompleted: true,
      isSynced: true,
    } as Partial<Invoice> & OfflineSynced);
  };

  const completeInvoice = (
    invoiceUpdates: Partial<Invoice> & OfflineSynced,
  ) => {
    updateInvoice(invoiceUpdates, true);

    track(AnalyticType.OrderCompleted, {
      total: invoice.totalSale,
      firstFillPickupPercent: calculateFirstFillPercent(invoice),
      products: invoice.items
        .filter(({rx}) => !rx)
        .map(({description, itemNumber, price, quantity}) => {
          return {
            category: 'OTC',
            name: description || '',
            price,
            productId: itemNumber || '',
            quantity,
          };
        }),
      saleDuration: +new Date() - +invoice.saleDate,
    });

    setTimeout(() => navigate('/sales'), 400);
  };

  const onBack = () => {
    navigate('../customer-payment');
  };

  return (
    <Receipts
      invoice={invoice}
      onPrint={() => {
        trackReceiptType('printed');
        return navigate('/sales');
      }}
      onNoReceipt={onNoReceipt}
      onEmailReceipt={onEmailReceipt}
      onBack={onBack}
      navigate={navigate}
    />
  );
}

export const ReceiptsIntegration = memo(
  withChildPage(ReceiptsIntegrationComponent),
);

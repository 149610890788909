import {InvoiceTax, InvoiceTaxRequest} from '@emporos/api-enterprise';

export function ConvertInvoiceTaxesToRequests(
  taxes: InvoiceTax[],
): InvoiceTaxRequest[] {
  const requests: InvoiceTaxRequest[] = [];
  taxes.forEach(tax => {
    requests.push(ConvertInvoiceTaxToRequest(tax));
  });
  return requests;
}

export function ConvertInvoiceTaxToRequest(tax: InvoiceTax): InvoiceTaxRequest {
  return {
    invoiceTaxId: tax.invoiceTaxId,
    taxID: tax.taxID,
    transactionID: tax.transactionID ?? 0,
    taxAmount: tax.taxAmount ?? 0,
    taxRateID: tax.taxRateID,
    taxRate: tax.taxRate ?? 0,
    taxableSubTotal: tax.taxableSubTotal ?? 0,
    taxGroupID: tax.taxGroupID,
  };
}

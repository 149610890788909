import {
  Page,
  PageWrapper,
  PortraitNotSupported,
  SidebarWrapper,
} from '@emporos/components';
import {Navbar} from '@emporos/components-pos';
import {Redirect, RouteComponentProps, useLocation} from '@reach/router';
import React, {useEffect} from 'react';
import {animated, useSpring} from 'react-spring';
import styled from 'styled-components';

import {
  AuthClaim,
  NavigationLogTypes,
  Sidebar,
  useAlertState,
  useAuthentication,
  useLog,
  useSyncSession,
  useTransactionsState,
} from '../';

const MainWrapper = styled.div`
  display: flex;
  height: calc(100vh - 68px);
  height: calc(var(--vh, 1vh) * 100 - 68px);
`;

interface Props {
  children?: React.ReactElement | React.ReactElement[];
}

export function MainLayout({
  children,
}: RouteComponentProps & Props): JSX.Element {
  const {logout, user} = useAuthentication();
  const {logUserSelection} = useLog();
  const {reset} = useAlertState();
  const {syncSession, sessionStatus} = useSyncSession();
  const transition = useSpring({opacity: 1, from: {opacity: 0}});
  const {pathname} = useLocation();
  const {currentInvoiceId, savingSession} = useTransactionsState();

  useEffect(() => {
    reset();
  }, [location]);

  useEffect(() => {
    logUserSelection(NavigationLogTypes.UserNavigating, {
      url: location.pathname,
    });
  }, [location.pathname]);

  if (!user) {
    return <Redirect to="/login" noThrow />;
  }

  if (!currentInvoiceId && /payments/.test(pathname)) {
    return <Redirect to="/sales" noThrow />;
  }
  return (
    <>
      <div className="layout-landscape">
        <Navbar
          username={user?.profile[AuthClaim.Name] || ''}
          onSessionStatus={syncSession}
          sessionStatus={sessionStatus}
          onLogout={logout}
          isLoadingData={savingSession}
          hideSessionStatus={true}
        />
        <MainWrapper>
          <SidebarWrapper
            as={animated.div}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            style={transition as any}
          >
            <Sidebar />
          </SidebarWrapper>
          <PageWrapper style={{backgroundColor: 'transparent'}}>
            <Page open>{children}</Page>
          </PageWrapper>
        </MainWrapper>
      </div>
      <div className="layout-portrait">
        <PortraitNotSupported></PortraitNotSupported>
      </div>
    </>
  );
}

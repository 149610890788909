import {RouteComponentProps} from '@reach/router';
import assert from 'assert';
import {memo} from 'react';
import {
  mapAcknowledgement,
  useGlobalData,
  useInvoice,
  withChildPage,
} from '../../../../../../';
import {AcknowledgementsInfo} from './';

const AcknowledgementsInfoIntegrationComponent = ({
  navigate,
}: RouteComponentProps) => {
  const {settingsResult, paymentTendersResult} = useGlobalData();
  const {invoice} = useInvoice();

  assert(settingsResult && settingsResult.data, 'Missing settingsResult');
  assert(
    paymentTendersResult && paymentTendersResult.data,
    'Missing paymentTendersResult',
  );
  assert(navigate, 'Missing navigate');

  const {
    rxMessage,
    arMessage,
    pdMessage,
    ccMessage,
    sudafedMessage,
    noSafetyCapMessage,
  } = mapAcknowledgement(
    invoice,
    settingsResult.data,
    paymentTendersResult.data,
  );

  return (
    <AcknowledgementsInfo
      rxMessage={rxMessage}
      arMessage={arMessage}
      pdMessage={pdMessage}
      ccMessage={ccMessage}
      sudafedMessage={sudafedMessage}
      noSafetyCapMessage={noSafetyCapMessage}
      navigate={navigate}
    />
  );
};

export const AcknowledgementsInfoIntegration = memo(
  withChildPage(AcknowledgementsInfoIntegrationComponent),
);
